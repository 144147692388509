
<template>
      <v-app>
        <v-main>
<!--    <SideBar/>-->
    <div class="py-10">
        <div  v-if="showAlert" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-left">
            {{ message }}
        </div>
      <header>
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold leading-tight text-gray-900">Voucher</h1>
        </div>
      </header>
      <main>
        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <!-- Replace with your content -->
          <div class="px-4 py-8 sm:px-0">
<!--            <div class="border-4 border-dashed border-gray-200 rounded-lg h-96" />-->
                <!-- table start -->
                        <div class="px-4 sm:px-6 lg:px-8">
                            <div class="sm:flex sm:items-center">
                                <div class="mt-4 sm:mt-0 text-left">
                                    <button type="button"
                                            class="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                            @click="open_filter = true;"
                                    >
                                        Filter
                                    </button>
                                </div>
                                <div class="sm:flex-auto">
    <!--                                <h1 class="text-xl font-semibold text-gray-900">Promo</h1>-->
                                </div>

                                <label for="simple-search" class="sr-only">Search</label>
                                <div class="relative w-full" style="padding-left: 10px;">
                                    <input type="text" id="simple-search" v-model="search_query" @change="search" class="custom-search border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" required>
                                </div>

                                <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                    <button type="button"
                                            class="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                            @click="open = true; createVoucher = true; updateVoucher = false; clearAll()"
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>
                            <div class="mt-8 flex flex-col">
                                <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                        <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                            <table class="min-w-full divide-y divide-gray-300">
                                                <thead class="bg-gray-50">
                                                <tr>
                                                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                        <span class="sr-only">Edit</span>
                                                    </th>
                                                    <th scope="col"
                                                        @click="sortHeader('voucher_code')"
                                                        class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                        Voucher Code
                                                    </th>
                                                    <th scope="col"
                                                        @click="sortHeader('promo')"
                                                        class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                        Promo Code
                                                    </th>
                                                    <th scope="col"
                                                        @click="sortHeader('max_number_of_usage_customer')"
                                                        class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                                                        Max no. of Usage Customer
                                                    </th>
                                                    <th scope="col"
                                                        @click="sortHeader('max_number_of_usage')"
                                                        class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                                                        Max no. of Usage
                                                    </th>
                                                    <th scope="col"
                                                        class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                                                        Total no. Voucher Claim
                                                    </th>
                                                    <th scope="col"
                                                        class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                                                        Total no. Voucher Void
                                                    </th>
                                                    <th scope="col"
                                                        class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                                                        Total no. of Usage
                                                    </th>
                                                    <th scope="col"
                                                        class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                                                        Status
                                                    </th>

                                                    <th scope="col"
                                                        class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                                                        Prefix
                                                    </th>
<!--                                                    <th scope="col"-->
<!--                                                        class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">-->
<!--                                                        Sync-->
<!--                                                    </th>-->


                                                </tr>
                                                </thead>
                                                <tbody class="bg-white text-left">

                                                <tr v-for="(voucher, voucherIdx) in vouchers" :key="voucher.voucher_code"
                                                    :class="voucherIdx % 2 === 0 ? undefined : 'bg-gray-50'">
                                                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        <a href="#" class="text-indigo-600 hover:text-indigo-900"
                                                           @click="open = true; createVoucher = false; updateVoucher = true; selectedVoucher(voucher)"
                                                        >Edit<span class="sr-only">, {{ voucher.voucher_code }}</span></a
                                                        >
                                                    </td>
                                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                        {{ voucher.voucher_code }}
                                                    </td>
                                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                        {{ voucher.promo != null ? voucher.promo.promo_code : "" }}
                                                    </td>
                                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
                                                        {{ voucher.max_number_of_usage_customer }}
                                                    </td>
                                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
                                                        {{ voucher.max_number_of_usage }}
                                                    </td>
                                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
                                                        {{ voucher.total_voucher_claim }}
                                                    </td>
                                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
                                                        {{ voucher.total_voucher_void }}
                                                    </td>
                                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
                                                        {{ voucher.total_voucher_usage }}
                                                    </td>
                                                    <td v-if="voucher.is_active === true" class="text-we whitespace-nowrap px-3 py-4 text-sm text-gray-500 custom-align-center">
                                                        <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                          <path fill="#70bf2b" d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
                                                        </svg>
                                                    </td>

                                                    <td v-if="voucher.is_prefix === true" class="text-we whitespace-nowrap px-3 py-4 text-sm text-gray-500 custom-align-center">
                                                        <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                          <path fill="#70bf2b" d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
                                                        </svg>
                                                    </td>

                                                    <td v-else class="text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500 custom-align-center">
                                                        <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                          <path fill="#dd4646" d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
                                                        </svg>
                                                    </td>
<!--                                                    <td v-if="voucher.sync === true" class="text-we whitespace-nowrap px-3 py-4 text-sm text-gray-500 custom-align-center">-->
<!--                                                        <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">-->
<!--                                                          <path fill="#70bf2b" d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>-->
<!--                                                        </svg>-->
<!--                                                    </td>-->
<!--                                                    <td v-else class="text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500 custom-align-center">-->
<!--                                                        <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">-->
<!--                                                          <path fill="#dd4646" d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>-->
<!--                                                        </svg>-->
<!--                                                    </td>-->

                                                </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>

                                <div class="mt-10 pagination m-1 custom-center-div">
                                    <button @click="prevPage" v-if="showPreviousButton">❮</button>
                                    <button @click="nextPage" v-if="showNextButton">❯</button>
                                </div>

                            </div>
                        </div>
                        <!-- table end -->

                        <!-- start filter -->
                        <TransitionRoot as="template" :show="open_filter">
                            <Dialog as="div" class="relative z-10" @close="open_filter = false">
                                <div class="fixed inset-0"/>
                                <div class="fixed inset-0 overflow-hidden">
                                    <div class="absolute inset-0 overflow-hidden">
                                        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16 ">
                                            <TransitionChild as="template"
                                                             enter="transform transition ease-in-out duration-500 sm:duration-700"
                                                             enter-from="translate-x-full" enter-to="translate-x-0"
                                                             leave="transform transition ease-in-out duration-500 sm:duration-700"
                                                             leave-from="translate-x-0" leave-to="translate-x-full">
                                                <DialogPanel class="pointer-events-auto w-screen max-w-8xl">
                                                    <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                                                          @submit.prevent="submitApplyFilter"
                                                    >
                                                        <div class="h-0 flex-1 overflow-y-auto">
                                                            <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                                                                <div class="flex items-center justify-between">
                                                                    <DialogTitle class="text-lg font-medium text-white">
                                                                        Filter
                                                                    </DialogTitle>
                                                                    <div class="ml-3 flex h-7 items-center">
                                                                        <button type="button"
                                                                                class="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                                                @click="open_filter = false">
                                                                            <span class="sr-only">Close panel</span>
                                                                            <XIcon class="h-6 w-6" aria-hidden="true"/>
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div class="flex">
                                                                <div>
                                                                    <div class="space-y-4 pt-6 pb-5 ml-5">
                                                                        <label class="block text-lg font-weight-bold font-medium text-gray-900">
                                                                            Max no. of usage </label>
                                                                        <label class="block text-sm font-medium text-gray-900">
                                                                            Max no. of usage from </label>
                                                                        <div class="mt-1">
                                                                            <input v-model="filter_usage_from" type="number" name="filter_usage_from"
                                                                                   id="filter_usage_from"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                        </div>
                                                                    </div>

                                                                    <div class="space-y-4 pb-5 ml-5">
                                                                        <label class="block text-sm font-medium text-gray-900">
                                                                            Max no. of usage to </label>
                                                                        <div class="mt-1">
                                                                            <input v-model="filter_usage_to" type="number" name="filter_usage_to"
                                                                                   id="filter_max_usage_to"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <div class="space-y-4 pt-6 pb-5 ml-5">
                                                                        <label class="block text-lg font-weight-bold font-medium text-gray-900">
                                                                            Max no. of usage customer </label>
                                                                        <label class="block text-sm font-medium text-gray-900">
                                                                            Max no. of usage customer from </label>
                                                                        <div class="mt-1">
                                                                            <input v-model="filter_usage_customer_from" type="number" name="filter_usage_customer_from"
                                                                                   id="filter_usage_customer_from"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                        </div>
                                                                    </div>

                                                                    <div class="space-y-4 pb-5 ml-5">
                                                                        <label class="block text-sm font-medium text-gray-900">
                                                                            Max no. of usage customer to </label>
                                                                        <div class="mt-1">
                                                                            <input v-model="filter_usage_customer_to" type="number" name="filter_usage_customer_to"
                                                                                   id="filter_usage_customer_to"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="flex">
                                                                <div class="space-y-6 pt-6 pb-5 ml-5">
                                                                    <label class="block text-sm font-medium text-gray-900">
                                                                        Voucher Code </label>
                                                                    <div class="mt-1">
                                                                        <input v-model="filter_voucher_code" type="text" name="filter_voucher_code"
                                                                               id="filter_voucher_code"
                                                                               class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                    </div>
                                                                </div>

                                                                <div class="space-y-6 pt-6 pb-5 ml-5">
                                                                    <label class="block text-sm font-medium text-gray-900">
                                                                        Promo Code </label>
                                                                    <div class="mt-1">
                                                                        <input v-model="filter_promo_code" type="text" name="filter_promo_code"
                                                                               id="filter_promo_code"
                                                                               class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="flex">
                                                                <div class="space-y-6 pt-6 pb-5 ml-5">
                                                                    <label class="block text-sm font-medium text-gray-900">
                                                                        Status </label>
                                                                    <div class="mt-1">
                                                                        <SwitchGroup as="div" class="flex items-center">
                                                                                <Switch v-model="filter_is_active"
                                                                                    :class="[filter_is_active ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                                                                <span aria-hidden="true"
                                                                                      :class="[filter_is_active ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                                                            </Switch>
                                                                        </SwitchGroup>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="flex flex-shrink-0 px-4 py-4">
                                                                <button type="button"
                                                                        class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                        @click="open_filter = false; filter_status = 'False'; resetFilter()">Reset Filter
                                                                </button>
                                                                <button type="submit"
                                                                        class="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                        @click="open_filter = false; filter_status = 'True'"
                                                                >
                                                                    Apply Filter
                                                                </button>
                                                            </div>

                                                        </div>
                                                    </form>
                                                </DialogPanel>
                                            </TransitionChild>
                                        </div>
                                    </div>
                                </div>
                            </Dialog>
                        </TransitionRoot>

                        <!-- end filter -->


                        <!-- start dialog information -->
                        <TransitionRoot as="template" :show="open">
                            <Dialog as="div" class="relative z-10" @close="open = false">
                                <div class="fixed inset-0"/>

                                <div class="fixed inset-0 overflow-hidden">
                                    <div class="absolute inset-0 overflow-hidden">
                                        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16 ">
                                            <TransitionChild as="template"
                                                             enter="transform transition ease-in-out duration-500 sm:duration-700"
                                                             enter-from="translate-x-full" enter-to="translate-x-0"
                                                             leave="transform transition ease-in-out duration-500 sm:duration-700"
                                                             leave-from="translate-x-0" leave-to="translate-x-full">
                                                <DialogPanel class="pointer-events-auto w-screen max-w-md">
                                                    <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl" @submit.prevent="submitForm">
                                                        <div class="h-0 flex-1 overflow-y-auto">
                                                            <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                                                                <div class="flex items-center justify-between">
                                                                    <DialogTitle class="text-lg font-medium text-white"
                                                                                 v-if="this.createVoucher === true">
                                                                        Add Voucher
                                                                    </DialogTitle>
                                                                    <DialogTitle class="text-lg font-medium text-white"
                                                                                 v-if="this.updateVoucher === true">
                                                                        Edit Voucher
                                                                    </DialogTitle>
                                                                    <div class="ml-3 flex h-7 items-center">
                                                                        <button type="button"
                                                                                class="rounded-md bg-indigo-700 text-indigo-200 hover:text-white"
                                                                                @click="open = false">
                                                                            <span class="sr-only">Close panel</span>
                                                                            <XIcon class="h-6 w-6" aria-hidden="true"/>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="flex flex-1 flex-col justify-between">
                                                                <div class="divide-y divide-gray-200 px-4 sm:px-6">
                                                                    <div class="space-y-6 pt-6 pb-5">
                                                                        <div>
                                                                            <Combobox as="div" v-model="selectedPromo" >
                                                                                <ComboboxLabel class="block text-sm font-medium text-gray-700">Select Promo Code</ComboboxLabel>
                                                                                <div class="relative mt-1">
                                                                                    <ComboboxInput 
                                                                                        class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" 
                                                                                        @input="selectPromoHandleChange"
                                                                                        v-model="selectedPromo"
                                                                                        required
                                                                                    />
                                                                                    <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                                                                        <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                                    </ComboboxButton>

                                                                                    <ComboboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                                        <ComboboxOption 
                                                                                            v-for="promo in this.promos" 
                                                                                            :key="promo.promo_code" 
                                                                                            :value="promo.promo_code" 
                                                                                            as="template" 
                                                                                            v-slot="{ active, selected }"
                                                                                            
                                                                                        >
                                                                                            <li 
                                                                                                :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']" 
                                                                                                @mousedown="selectPromoOption(promo)"
                                                                                            >
                                                                                                <span :class="['block truncate', selected && 'font-semibold']">
                                                                                                    {{ promo.promo_code }}
                                                                                                </span>

                                                                                                <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                                                                                                    <CheckIcon class="h-5 w-5" aria-hidden="true" ></CheckIcon>
                                                                                                </span>
                                                                                            </li>
                                                                                        </ComboboxOption>
                                                                                    </ComboboxOptions>
                                                                                </div>
                                                                            </Combobox>
                                                                        </div>
                                                                        <div>
                                                                            <label for="voucher-code"
                                                                                   class="block text-sm font-medium text-gray-900">
                                                                                Voucher Code </label>
                                                                            <div class="mt-1">
                                                                                <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                                <input type="text" name="promo-name"
                                                                                       id="voucher-code"
                                                                                       v-model="voucherCode"
                                                                                       class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                                       required
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <label for="max-number-usage"
                                                                                   class="block text-sm font-medium text-gray-900">
                                                                                Max Number of Usage </label>
                                                                            <div class="mt-1">
                                                                                <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                                <input type="number" name="max-number-usage"
                                                                                       id="max-number-usage"
                                                                                       v-model="maxNumberOfUsage"
                                                                                       class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                            </div>
                                                                        </div>

<!--                                                                        <div>-->
<!--                                                                            <label for="number-usage"-->
<!--                                                                                   class="block text-sm font-medium text-gray-900">-->
<!--                                                                                Number of Usage </label>-->
<!--                                                                            <div class="mt-1">-->
<!--                                                                                &lt;!&ndash;                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />&ndash;&gt;-->
<!--                                                                                <input type="text" name="number-usage"-->
<!--                                                                                       id="number-usage"-->
<!--                                                                                       v-model="numberOfUsage"-->
<!--                                                                                       class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>-->
<!--                                                                            </div>-->
<!--                                                                        </div>-->

                                                                        <div>
                                                                            <label for="number-usage-customer"
                                                                                   class="block text-sm font-medium text-gray-900">
                                                                                Max Number of Usage Customer </label>
                                                                            <div class="mt-1">
                                                                                <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                                <input type="number" name="number-usage-customer"
                                                                                       id="number-usage-customer"
                                                                                       v-model="maxNumberOfUsageofCustomer"
                                                                                       class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                            </div>
                                                                        </div>

                                                                        <SwitchGroup as="div" class="flex items-center">
                                                                            <Switch v-model="is_active"
                                                                                    :class="[is_active ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                                                                <span aria-hidden="true"
                                                                                      :class="[is_active ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                                                            </Switch>
                                                                            <SwitchLabel as="span" class="ml-3">
                                                                                <span class="text-sm font-medium text-gray-900">Status </span>
                                                                            </SwitchLabel>
                                                                        </SwitchGroup>

                                                                        <SwitchGroup as="div" class="flex items-center">
                                                                            <Switch v-model="is_prefix"
                                                                                    :class="[is_prefix ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                                                                <span aria-hidden="true"
                                                                                      :class="[is_prefix ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                                                            </Switch>
                                                                            <SwitchLabel as="span" class="ml-3">
                                                                                <span class="text-sm font-medium text-gray-900">Prefix </span>
                                                                            </SwitchLabel>
                                                                        </SwitchGroup>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="flex flex-shrink-0 justify-end px-4 py-4">
                                                            <button type="button"
                                                                    class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                    @click="open = false">Cancel
                                                            </button>
                                                            <button type="submit"
                                                                    class="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                            >
                                                                Save
                                                            </button>
                                                        </div>
                                                    </form>
                                                </DialogPanel>
                                            </TransitionChild>
                                        </div>
                                    </div>
                                </div>
                            </Dialog>
                        </TransitionRoot>

<!--                                                      debug: filter={{filteredPromo}}-->
                        <!-- end dialof information -->
          </div>
          <!-- /End replace -->
        </div>
      </main>
    </div>
<!--    <router-view/>-->
            </v-main>
      </v-app>
</template>

<script>
     // import SideBar from "@/components/SideBar";
    import axios from "axios";
    import {ref} from "vue";
    import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/outline'
    import {
        Dialog,
        DialogPanel,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        Switch,
        SwitchGroup,
        SwitchLabel,
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue'
    import {XIcon} from '@heroicons/vue/outline'
    // const filteredPromo = computed(() =>
    //   this.query.value === ''
    //     ? this.promos
    //     : this.promos.filter((promo) => {
    //         return promo.promo_code.toLowerCase().includes(this.query.value.toLowerCase())
    //       })
    // )
    export default {
        name: "VoucherView",
        // components: {SideBar},
        components: {
            Dialog,
            DialogPanel,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            XIcon,
            Switch,
            SwitchGroup,
            SwitchLabel,
            Combobox,
            ComboboxButton,
            ComboboxInput,
            ComboboxLabel,
            ComboboxOption,
            ComboboxOptions,
            CheckIcon, ChevronDownIcon
        },
        data() {
            return {
                showAlert: false,
                message: "",
                createVoucher: false,
                updateVoucher: false,
                vouchers: [],
                voucherId: '',
                voucherCode: '',
                maxNumberOfUsage: 0,
                maxNumberOfUsageofCustomer: 0,
                // numberOfUsage: 0,
                numberUsageOfCustomer: 0,
                open: ref(false),
                is_active: ref(true),
                is_prefix: ref(false),
                promos: [],
                selectedPromo: ref(),
                currentSort:'voucher_code',
                currentSortDir:'asc',
                currentPage:1,
                query: ref(''),
                search_query: ref(''),
                showNextButton: false,
                showPreviousButton: false,
                order_name: 'voucher_code',
                open_filter: ref(false),
                filter_status: 'False',
                filter_is_active: ref(true),
                filter_usage_from: '',
                filter_usage_to: '',
                filter_usage_customer_from: '',
                filter_usage_customer_to: '',
                filter_voucher_code: '',
                filter_promo_code: '',
                timeoutId: null,
            }
        },
        mounted() {
            this.getVouchers()
            // this.getPromos()
        },
        /* eslint-disable */
        computed:{
            filteredPromo:function () {
             return this.query.value === ''
            ? this.promos
            : this.promos.filter(promo => {
                return promo.promo_code.toLowerCase().includes(this.query.toLowerCase())
              }).sort(res => {
                return -1;
              });
            },
        },
        methods: {
            displayAlert(message, duration) {
                this.message = message;
                this.showAlert = true;

                setTimeout(() => {
                    this.showAlert = false;
                    this.message = "";
                }, duration);
            },
            search() {
                this.filter_status = 'False'
                this.currentPage = 1
                this.showNextButton = false
                this.showPreviousButton = false
                this.getVouchers()
            },
            sortHeader:function(s) {
                //if s == current sort, reverse
                if(s === this.currentSort) {
                  this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
                } else {
                     this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
                }
                this.order_name = s

                if (this.filter_status === "True") {
                    this.applyFilter()
                } else {
                    this.getVouchers()
                }
            },
            nextPage:function() {
                this.currentPage += 1
                if (this.filter_status === "True") {
                    this.applyFilter()
                } else {
                    this.getVouchers()
                }
            },
            prevPage:function() {
                this.currentPage -= 1
                if (this.filter_status === "True") {
                    this.applyFilter()
                } else {
                    this.getVouchers()
                }
            },
            async getVouchers() {
                const token =  localStorage.getItem('token')
                axios.defaults.headers.common['Authorization'] = "Token " + token
                // console.log(this.currentPage)
                let data = {
                    "page": this.currentPage,
                    "search": this.search_query,
                    "filter_status": this.filter_status,
                    "orderby": this.order_name,
                    "sort": this.currentSortDir,
                }
                axios.get(`/api/v1/vouchers`, {params: data}).then(res => {
                    // console.log(res.data);
                    this.vouchers = res.data.results;

                    if (res.data.next) {
                        this.showNextButton = true
                    } else {
                        this.showNextButton = false
                    }
                    if (res.data.previous) {
                        this.showPreviousButton = true
                    } else {
                        this.showPreviousButton = false
                    }

                }).catch(error => {
                    console.log(error)
                })
            },
            clearAll() {
                console.log('add')
                this.voucherCode = ""
                this.selectedPromo = ""
                this.maxNumberOfUsage = 0
                // this.numberOfUsage = 0
                this.maxNumberOfUsageofCustomer = 0

                this.getPromos()
            },
            selectPromoOption(option) {
                console.log(option.promo_code)
                this.selectedPromo = option.promo_code
            },
            getPromoByPK(promo_code) {
                const token = localStorage.getItem('token')
                axios.defaults.headers.common['Authorization'] = "Token " + token
                axios.get(`/api/v1/promos/${promo_code}`).then(res => {
                    this.selectedPromo = res.data['promo_code']
                    
                }).catch(error => {
                    console.log(error)
                })
                
            },
            selectPromoHandleChange(event) {
                const search = event.target.value;
                 // Clear any existing timeout to ensure the action is executed only once
                if (this.timeoutId) {
                    clearTimeout(this.timeoutId);
                }

                 // Set a new timeout
                this.timeoutId = setTimeout(() => {
                    this.getPromos(search);
                }, 1000); // 1000 milliseconds = 1 second

            },
            getPromos(search) {
                console.log(search)
                const token = localStorage.getItem('token')
                axios.defaults.headers.common['Authorization'] = "Token " + token
                axios.get(`/api/v1/promos?page=1&search=${search}`).then(res => {
                    this.promos = res.data.results;
                }).catch(error => {
                    console.log(error)
                })
                
            },
            selectedVoucher(voucher) {
                console.log('update')
                
                this.voucherId = voucher['id']
                this.voucherCode = voucher['voucher_code']
                this.maxNumberOfUsage = voucher['max_number_of_usage']
                this.maxNumberOfUsageofCustomer = voucher['max_number_of_usage_customer']
                this.numberOfUsage = voucher['number_of_usage']
                this.numberUsageOfCustomer = voucher['number_of_usage_customer']
                this.is_active = voucher['is_active']
                this.is_prefix = voucher['is_prefix']
                this.getPromoByPK(voucher["promo"]["promo_code"])
                // this.selectedPromo = voucher["promo"]["promo_code"]
                
                console.log(this.selectedPromo)
                // console.log(voucher["promo"]["promo_code"])
                // if (voucher["promo"] !== null) {
                //     let filter_promo = this.promos.filter(promo =>{
                //         return promo.promo_code.toLowerCase().includes(voucher["promo"]["promo_code"].toLowerCase())
                //     }).sort(res => {
                //         return -1;
                //     });
                //     // console.log(filter_promo[0])
                //     this.selectedPromo = filter_promo[0]
                // }

                // console.log(this.selectedPromo)
            },
            submitForm: function() {
                let data = {
                    voucher_code: this.voucherCode,
                    max_number_of_usage: this.maxNumberOfUsage,
                    // number_of_usage: this.numberOfUsage,
                    max_number_of_usage_customer: this.maxNumberOfUsageofCustomer,
                    is_active: this.is_active,
                    is_prefix: this.is_prefix,
                    promo: this.selectedPromo,
                }

                // console.log(data)

                if (this.createVoucher === true) {
                    const token = localStorage.getItem('token')
                    axios.defaults.headers.common['Authorization'] = "Token " + token
                    axios.post("/api/v1/vouchers", data).then(res => {
                        console.log(res.data);
                        if (res.status === 201) {
                            location.reload();
                        } else {
                            let msg_error = error.response.data.detail || 'An error occurred';
                            this.displayAlert(msg_error, 5000)
                        }
                    }).catch(error => {
                        if (error.response) {
                            // console.log(error.response.data);
                            // console.log(error.response.data.detail);
                            // console.log(error.response.status);
                            // console.log(error.response.headers);
                            let msg_error = error.response.data.detail || 'An error occurred';
                            this.displayAlert(msg_error, 5000);
                        } else if (error.request) {
                            // The request was made but no response was received
                            console.log(error.request);
                            this.displayAlert('No response received from server', 5000);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                            this.displayAlert('Error: ' + error.message, 5000);
                        }
                        console.log(error.config);
                    })
                }

                if (this.updateVoucher === true) {
                    const token = localStorage.getItem('token')
                    axios.defaults.headers.common['Authorization'] = "Token " + token
                    axios.put(`/api/v1/vouchers/${this.voucherId}`, data).then(res => {
                        if (res.status === 200) {
                            location.reload();
                        } else {
                            let msg_error = res.data.message
                            this.displayAlert(msg_error, 5000)
                            
                        }
                    }).catch(error => {
                        if (error.response) {
                            // console.log(error.response.data);
                            // console.log(error.response.data.detail);
                            // console.log(error.response.status);
                            // console.log(error.response.headers);
                            let msg_error = error.response.data.detail || 'An error occurred';
                            this.displayAlert(msg_error, 5000);
                        } else if (error.request) {
                            // The request was made but no response was received
                            console.log(error.request);
                            this.displayAlert('No response received from server', 5000);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                            this.displayAlert('Error: ' + error.message, 5000);
                        }
                        console.log(error.config);
                    })
                }

            },
            submitApplyFilter: function () {
                console.log('Filter')
                this.currentPage = 1
                this.showNextButton = false
                this.showPreviousButton = false
                this.applyFilter()
            },
            applyFilter: function () {
                let filter_is_active = ''
                if (this.filter_is_active === true) {
                    filter_is_active = "True"
                } else {
                    filter_is_active = "False"
                }

                const token =  localStorage.getItem('token')
                axios.defaults.headers.common['Authorization'] = "Token " + token
                // console.log(this.currentPage)
                let data = {
                    "page": this.currentPage,
                    "no_usage_from": this.filter_usage_from,
                    "no_usage_to": this.filter_usage_to,
                    "no_usage_customer_from": this.filter_usage_customer_from,
                    "no_usage_customer_to": this.filter_usage_customer_to,
                    "voucher_code": this.filter_voucher_code,
                    "promo_code": this.filter_promo_code,
                    "is_active": filter_is_active,
                    "orderby": this.order_name,
                    "sort": this.currentSortDir,
                    "filter_status": this.filter_status,
                }
                axios.get(`/api/v1/vouchers` , {params: data}).then(res => {
                    // console.log(res.data);
                    this.vouchers = res.data.results;

                    if (res.data.next) {
                        this.showNextButton = true
                    } else {
                        this.showNextButton = false
                    }
                    if (res.data.previous) {
                        this.showPreviousButton = true
                    } else {
                        this.showPreviousButton = false
                    }

                }).catch(error => {
                    console.log(error)
                })
            },
            resetFilter: function () {
                this.filter_status = 'False'
                this.filter_is_active = ref(true)
                this.filter_usage_from = ''
                this.filter_usage_to = ''
                this.filter_usage_customer_from = ''
                this.filter_usage_customer_to = ''
                this.filter_voucher_code = ''
                this.filter_promo_code = ''

                this.currentPage = 1
                this.showNextButton = false
                this.showPreviousButton = false
                this.getVouchers()
            }
        }
    }
</script>

<style scoped>
    .pagination {
      display: inline-block;
    }

    .pagination button {
      color: black;
      float: left;
      padding: 8px 16px;
      text-decoration: none;
      transition: background-color .3s;
      border: 1px solid #ddd;
    }

    .pagination button.active {
      background-color: #4CAF50;
      color: white;
      border: 1px solid #4CAF50;
    }

    .custom-center-div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .pagination button:hover:not(.active) {background-color: #ddd;}

    .custom-search {
        border: 1px solid #555;
        display: block;
        padding: 9px 4px 9px 40px;
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
    }

    .custom-align-center{
        text-align: -webkit-center;
    }

    .alert {
      background-color: #f44336;
      color: white;
      padding: 16px;
      margin-bottom: 15px;
    }
</style>