<template>
    <div class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div class="max-w-md w-full space-y-8">
            <div>
                <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                     alt="Workflow"/>
                <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
            </div>
            <form class="mt-8 space-y-6" @submit.prevent="submitForm">
                <input type="hidden" name="remember" value="true"/>
                <div class="rounded-md shadow-sm -space-y-px">
                    <div>
                        <label for="username" class="sr-only">Username</label>
                        <input id="username" name="username" type="text" required=""
                               v-model="username"
                               class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                               placeholder="Username"/>
                    </div>
                    <div>
                        <label for="password" class="sr-only">Password</label>
                        <input id="password" name="password" type="password" autocomplete="current-password"
                               required=""
                               v-model="password"
                               class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                               placeholder="Password"/>
                    </div>
                </div>

                <div>
                    <button type="submit"
                            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true"/>
            </span>
                        Sign in
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import {LockClosedIcon} from '@heroicons/vue/solid'
    import axios from "axios";

    export default {
        name: "LoginView",
        components: {
            LockClosedIcon
        },
        data() {
            return {
                username: '',
                password: '',
            }
        },
        mounted() {
            this.auth()
        },
        methods: {
            login() {
                let data = {
                    username: this.username,
                    password: this.password,
                }

                console.log(data)
            },
            auth() {
                // console.log(this.$store.state.token)
                this.auth_token = this.$store.state.token
                if (this.auth_token) {
                    axios.defaults.headers.common['Authorization'] = "Token " + this.auth_token
                    axios.post("/api/v1/auth", '').then(res => {
                        // console.log(res)
                        this.token = true
                    }).catch(error => {
                        // console.log(error)
                        this.token = false
                        localStorage.removeItem('token')
                        location.reload();
                    })
                }

            },
            submitForm() {
                  console.log('login')
                  const data = {
                      password: this.password,
                      username: this.username,

                  }
                  localStorage.removeItem('token')
                  // console.log(data)
                  // const token = localStorage.getItem('token')
                  // const token = res.data.auth_token;
                  // axios.defaults.headers.common['Authorization'] = "Token " + 'c39b8820b38aca9bd0c7d9aeeb1fb6d85979ef54'
                  // axios.defaults.headers.common['X-CSRFTOKEN'] = '';
                  axios.post("/api/v1/token/login", data).then(res => {
                      // console.log(res.data.auth_token);
                      const token = res.data.auth_token;
                      localStorage.setItem('token', token);
                      this.token = true
                      location.reload();
                  }).catch(error => {
                      console.log(error)
                  })
              }
        }
    }
</script>

<style scoped>

</style>