<template>
    <!-- start dialog information -->
    <div  v-if="showAlert"
          class="bg-red-100
                 border
                 border-red-400
                 text-red-700
                 px-4
                 py-3
                 rounded
                 relative
                 text-left">
        {{ message }}
    </div>
    <form @submit.prevent="submitForm">
        <div class="flex flex-1 flex-col justify-between">
            <div class="divide-y divide-gray-200 px-4 sm:px-6">
                <div class="space-y-6 pt-6 pb-5">

                    <div>
                        <label for="store_id"
                               class="block text-sm font-medium text-gray-900">
                            Store ID </label>
                        <div class="mt-1">
                            <input type="text" name="store_id"
                                   id="store_id"
                                   v-model="formData.store_id"
                                   class="block
                                          w-75
                                          rounded-md
                                          border-gray-300
                                          shadow-sm
                                          focus:border-indigo-500
                                          focus:ring-indigo-500
                                          sm:text-sm"
                                   required
                            />
                        </div>
                    </div>

                    <div>
                        <label class="block text-sm font-medium text-gray-900">
                        Store Groups </label>
                        <div class="mt-1">
                            <select class="block
                                           w-50
                                           rounded-md
                                           border-gray-300
                                           shadow-sm
                                           focus:border-indigo-500
                                           focus:ring-indigo-500
                                           sm:text-sm
                                           resizable-select-stores"
                                    ref="resizableSelectStore"
                                    @mousedown="startResizeStore"
                                    v-model="formData.store_groups"
                                    multiple
                                    required
                            >
                                <!-- eslint-disable-next-line-->
                                <option v-for="data in store_groups"
                                        v-bind:value="data.store_group">
                                    {{ data.store_group }}
                                </option>
                            </select>

                        </div>
                    </div>

                    <div>
                        <label for="store"
                               class="block text-sm font-medium text-gray-900">
                            Store </label>
                        <div class="mt-1">
                            <input type="text" name="store"
                                   id="store"
                                   v-model="formData.store"
                                   class="block
                                          w-75
                                          rounded-md
                                          border-gray-300
                                          shadow-sm
                                          focus:border-indigo-500
                                          focus:ring-indigo-500
                                          sm:text-sm"
                                   required
                            />
                        </div>
                    </div>

                    <div>
                        <label for="master_ip_domain"
                               class="block text-sm font-medium text-gray-900">
                            Master IP Domain </label>
                        <div class="mt-1">
                            <input type="text" name="master_ip_domain"
                                   id="master_ip_domain"
                                   v-model="formData.master_ip_domain"
                                   class="block
                                          w-75
                                          rounded-md
                                          border-gray-300
                                          shadow-sm
                                          focus:border-indigo-500
                                          focus:ring-indigo-500
                                          sm:text-sm"/>
                        </div>
                    </div>

                    <div>
                        <label for="master_key"
                               class="block text-sm font-medium text-gray-900">
                            Master Key </label>
                        <div class="mt-1">
                            <input type="text" name="master_key"
                                   id="master_key"
                                   v-model="formData.master_key"
                                   class="block
                                          w-75
                                          rounded-md
                                          border-gray-300
                                          shadow-sm
                                          focus:border-indigo-500
                                          focus:ring-indigo-500
                                          sm:text-sm"/>
                        </div>
                    </div>

                    <div>
                        <label for="pos_key"
                               class="block text-sm font-medium text-gray-900">
                            POS Key </label>
                        <div class="mt-1">
                            <input type="text" name="pos_key"
                                   id="pos_key"
                                   v-model="formData.pos_key"
                                   class="block
                                          w-75
                                          rounded-md
                                          border-gray-300
                                          shadow-sm
                                          focus:border-indigo-500
                                          focus:ring-indigo-500
                                          sm:text-sm"/>
                        </div>
                    </div>

                    <div>
                        <label class="block text-sm font-medium text-gray-900">
                        Region </label>
                        <div class="mt-1">
                            <select class="block
                                           w-50
                                           rounded-md
                                           border-gray-300
                                           shadow-sm
                                           focus:border-indigo-500
                                           focus:ring-indigo-500
                                           sm:text-sm"
                                    v-model="formData.region"
                            >
                                <!-- eslint-disable-next-line-->
                                <option v-for="data in region_list"
                                        v-bind:value="data.value">
                                    {{ data.text }}
                                </option>
                            </select>
                        </div>
                    </div>

                </div>

                <div class="flex flex-shrink-0 justify-end px-4 py-4">
                    <button type="button"
                            class="rounded-md
                                   border
                                   border-gray-300
                                   bg-white
                                   py-2
                                   px-4
                                   text-sm
                                   font-medium
                                   text-gray-700
                                   shadow-sm
                                   hover:bg-gray-50
                                   focus:outline-none
                                   focus:ring-2
                                   focus:ring-indigo-500
                                   focus:ring-offset-2"
                            >Reset
                    </button>
                    <button type="submit"
                            class="ml-4
                                   inline-flex
                                   justify-center
                                   rounded-md
                                   border
                                   border-transparent
                                   bg-indigo-600
                                   py-2
                                   px-4
                                   text-sm
                                   font-medium
                                   text-white
                                   shadow-sm
                                   hover:bg-indigo-700
                                   focus:outline-none
                                   focus:ring-2
                                   focus:ring-indigo-500
                                   focus:ring-offset-2"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
    import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
    import {XIcon} from "@heroicons/vue/outline";
    import CreateStoreGroup from "@/views/StoreGroup/CreateStoreGroup";
    import axios from "axios";
    export default {
        name: "DataFields",
        components: {
            // Dialog,
            // DialogPanel,
            // DialogTitle,
            // TransitionChild,
            // TransitionRoot,
            // XIcon,
            // CreateStoreGroup
        },
        props: {
            action: Text,
            selectedData: Object
        },
        data() {
            return {
                formData: {
                    id: null,
                    store_id: '',
                    store_groups: [],
                    store: '',
                    master_ip_domain: '',
                    master_key: '',
                    pos_key: '',
                    region: ''
                },
                store_groups: [],
                region_list: [
                    {text: 'NCR', value: 'NCR'},
                    {text: 'North Luzon', value: 'North Luzon'},
                    {text: 'South Luzon', value: 'South Luzon'},
                    {text: 'Visayas', value: 'Visayas'},
                    {text: 'Mindanao', value: 'Mindanao'},
                ],
                showAlert: false,
                message: "",
                isResizingStore: false,
            }
        },
        mounted() {
            const token = localStorage.getItem('token')
            axios.defaults.headers.common['Authorization'] = "Token " + token
            this.getStoreGroup()
            console.log(this.action)
            if (this.action === "update") {
                this.formData.id = this.selectedData.id
                this.formData.store_id = this.selectedData.store_id
                this.formData.store_groups = this.selectedData.store_groups
                this.formData.store= this.selectedData.store
                this.formData.master_ip_domain = this.selectedData.master_ip_domain
                this.formData.master_key = this.selectedData.master_key
                this.formData.pos_key = this.selectedData.pos_key
                this.formData.region = this.selectedData.region
            }
        },
        methods: {
            getStoreGroup() {
                axios.get("/api/v1/store-group-list/").then(res => {
                    // console.log(res.data);
                    this.store_groups = res.data.map(v => ({...v, status: true}));
                }).catch(error => {
                    console.log(error)
                })
                console.log(this.store_groups)
            },
            close() {
            },
            submitForm: function() {
                console.log(this.formData)
                if (this.action === "create") {
                    console.log(this.action)
                    axios.post("/api/v1/store-add/", this.formData).then(res => {
                        // console.log(res.data.status);
                        if (res.data.status === 'error_special_character') {
                            let msg_error = res.data.message
                            this.displayAlert(msg_error, 5000)
                        } else {
                            location.reload();
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                } else if (this.action === "update") {
                    console.log(this.action)
                    axios.post("/api/v1/store-update/", this.formData).then(res => {
                        if (res.data.status === 'error') {
                            let msg_error = res.data.message
                            this.displayAlert(msg_error, 5000)
                        } else {
                            location.reload();
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                }
            },
            displayAlert(message, duration) {
                this.message = message;
                this.showAlert = true;

                setTimeout(() => {
                    this.showAlert = false;
                    this.message = "";
                }, duration);
            },
            startResizeStore(event) {
              this.isResizingStore = true;
              const initialWidth = this.$refs.resizableSelectStore.clientWidth;
              const initialHeight = this.$refs.resizableSelectStore.clientHeight;
              const initialX = event.clientX;
              const initialY = event.clientY;

              const onMouseMove = (event) => {
                if (this.isResizingStore) {
                  const deltaX = event.clientX - initialX;
                  const deltaY = event.clientY - initialY;
                  this.$refs.resizableSelectStore.style.width = `${initialWidth + deltaX}px`;
                  this.$refs.resizableSelectStore.style.height = `${initialHeight + deltaY}px`;
                }
              };

              const onMouseUp = () => {
                this.isResizingStore = false;
                window.removeEventListener('mousemove', onMouseMove);
                window.removeEventListener('mouseup', onMouseUp);
              };

              window.addEventListener('mousemove', onMouseMove);
              window.addEventListener('mouseup', onMouseUp);
            },
        }
    }
</script>

<style scoped>
    .resizable-select-stores {
      position: relative;
      display: inline-block;
      border: 1px solid #ccc;
      padding: 5px;
      cursor: col-resize;
      user-select: none;
    }
</style>