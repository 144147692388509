<template>
    <input
        type="file"
        ref="fileInput"
        style="display: none"
        @change="handleFileChange"
      />
    <div v-if="isVisible" class="context-menu" :style="{ top, left }">
        <ul>
          <li v-for="(item, index) in menuItems" :key="index" @click="handleItemClick(item)">
              {{ item.label }}
          </li>
        </ul>
    </div>

    <input type="text"
           style="height: 80%;"
           class="mb-3
                  block
                  w-50
                  rounded-md
                  border-gray-300
                  shadow-sm
                  sm:text-sm"
           v-model="inputValue"
           :placeholder="[valueOperator === '[]' ? 'From' : '...']"
           @contextmenu.prevent="showContextMenu($event)"
           @change="onChange"
           required
    />

</template>

<script>
    import Papa from 'papaparse';
    import readXlsxFile from "read-excel-file";

    export default {
        name: "ContextMenuInput",
        props: {
            value: String,
            valueOperator: String,
        },
        data() {
            return {
                isVisible: false,
                selectedFile: null,
                data: [],
                file: "",
                firstColumn: [],
                top: "",
                left: "",
                inputValue: this.value,
                menuItems: [
                    { label: 'Import', value: 'import' },
                    { label: 'Cancel', value: 'cancel' },
                ],
            }
        },
        methods: {
            handleItemClick(item) {
                this.$emit('itemSelected', item);
                this.isVisible = false;
                if (item.value === 'import') {
                    this.$refs.fileInput.click();
                }
            },
            uploadFile() {
                if (this.selectedFile) {
                    console.log('Selected file:', this.selectedFile);
                } else {
                    console.log('No file selected.');
                }
            },
            showContextMenu(event) {
              event.preventDefault();
              this.isVisible = true;
              this.top = `${event.clientY}px`;
              this.left = `${event.clientX}px`;
            },
            openFileDialog() {
              this.$refs.fileInput.click();

            },
            async handleFileChange(event) {
                this.isVisible = false;
                this.file = this.$refs.fileInput.files[0]
                console.log(this.file)

                if (this.file["type"] === 'text/csv') {
                    Papa.parse(this.file, {
                        complete:(result) => {
                            this.firstColumn = result.data.map(row => row[0]).filter(item => item.trim() !== "");
                            console.log(this.firstColumn)
                            this.inputValue  = this.firstColumn.join(",")
                            this.onChange()
                        }
                    })
                } else {
                    await this.readExcelFile(this.file);
                }

                this.clearFields()
            },
            async readExcelFile(file) {
                try {
                    const data = await readXlsxFile(file);
                    if (data.length > 0) {
                      this.firstColumn = data.map(row => row[0]);
                      this.inputValue = this.firstColumn.join(",")
                    }
                    this.onChange()
                } catch (error) {
                    console.error('Error reading Excel file:', error);
                }
            },
            clearFields() {
                 this.file = ''
                 this.data = ''
                 this.$refs.fileInput.value = null
            },
            onChange() {
                this.$emit('update:value', this.inputValue);
            }

        },
    }
</script>


<style scoped>
    .context-menu {
      position: absolute;
      background-color: #fff;
      border: 1px solid #ccc;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
      z-index: 1000;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    li {
      padding: 10px;
      cursor: pointer;
      border-bottom: 1px solid #ccc;
    }

    li:last-child {
      border-bottom: none;
    }

    .file-input-container {
      position: relative;
    }

    .file-input-button {
      padding: 10px;
      font-size: 16px;
      cursor: pointer;
    }

    .context-menu {
      position: absolute;
      background-color: #fff;
      border: 1px solid #ccc;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
      z-index: 1000;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    li {
      padding: 10px;
      cursor: pointer;
      border-bottom: 1px solid #ccc;
    }

    li:last-child {
      border-bottom: none;
    }
</style>