import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import router from "./router";
import store from './store'
import axios from 'axios';

axios.defaults.baseURL="https://api-evoucher.smmarkets.ph"

createApp(App).use(store).use(router, axios).mount('#app')
