<template>
    <div class="py-10">
        <header>
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 class="text-3xl font-bold leading-tight text-gray-900 text-left">Store Group</h1>
            </div>
        </header>

        <main>
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <create-store-group/> <!--create store group-->
                <filter-data/> <!--filter data-->
            </div>
        </main>

    </div>
</template>

<script>
    import CreateStoreGroup from "@/views/StoreGroup/CreateStoreGroup";
    import FilterData from "@/views/StoreGroup/FilterData";

    export default {
        name: "StoreGroup",
        components: {
            CreateStoreGroup,
            FilterData,
        },
        props: {
        },
        data() {
            return {
            }
        },
        mounted() {

        },
        methods: {
        }
    }
</script>

<style scoped>

</style>