<template>
    <div class="px-4 py-8 sm:px-0">
        <div class="px-4 sm:px-6 lg:px-8">
            <div class="sm:flex sm:items-center">
                <div class="mt-4 sm:mt-0 text-left">
                    <button type="button"
                            class="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                            @click="open_filter = true;"
                    >
                        Filter
                    </button>
                </div>
                <label for="simple-search" class="sr-only">Search</label>
                <div class="relative w-full" style="padding-left: 10px;">
                    <input type="text" id="simple-search" v-model="inputFilter.search_query" @change="search" class="custom-search border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" required>
                </div>
            </div>
        </div>

        <TransitionRoot as="template" :show="open_filter">
            <Dialog as="div" class="relative z-10" @close="open_filter = false">
                <div class="fixed inset-0"/>
                <div class="fixed inset-0 overflow-hidden">
                    <div class="absolute inset-0 overflow-hidden">
                        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16 ">
                            <TransitionChild as="template"
                                             enter="transform transition ease-in-out duration-500 sm:duration-700"
                                             enter-from="translate-x-full" enter-to="translate-x-0"
                                             leave="transform transition ease-in-out duration-500 sm:duration-700"
                                             leave-from="translate-x-0" leave-to="translate-x-full">
                                <DialogPanel class="pointer-events-auto w-screen max-w-8xl">
                                    <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                                          @submit.prevent="submitApplyFilter"
                                    >
                                        <div class="h-0 flex-1 overflow-y-auto">
                                            <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                                                <div class="flex items-center justify-between">
                                                    <DialogTitle class="text-lg font-medium text-white">
                                                        Filter
                                                    </DialogTitle>
                                                    <div class="ml-3 flex h-7 items-center">
                                                        <button type="button"
                                                                class="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                                @click="open_filter = false">
                                                            <span class="sr-only">Close panel</span>
                                                            <XIcon class="h-6 w-6" aria-hidden="true"/>
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="flex">
                                                <div>
                                                    <div class="space-y-4 pt-6 pb-5 ml-5">
                                                        <label class="block text-sm font-medium text-gray-900">
                                                            Store ID </label>
                                                        <div class="mt-1">
                                                            <input v-model="inputFilter.filter_store_id" type="text" name="filter_store_id"
                                                                   id="filter_store_id"
                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                        </div>
                                                    </div>

                                                    <div class="space-y-4 pt-6 pb-5 ml-5">
                                                        <label class="block text-sm font-medium text-gray-900">
                                                            Store </label>
                                                        <div class="mt-1">
                                                            <input v-model="inputFilter.filter_store" type="text" name="filter_store"
                                                                   id="filter_store"
                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                        </div>
                                                    </div>

                                                    <div class="space-y-4 pt-6 pb-5 ml-5">
                                                        <label class="block text-sm font-medium text-gray-900">
                                                            POS Key </label>
                                                        <div class="mt-1">
                                                            <input v-model="inputFilter.filter_pos_key" type="text" name="filter_pos_key"
                                                                   id="filter_pos_key"
                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                        </div>
                                                    </div>

<!--                                                    <div class="space-y-4 pb-5 ml-5">-->
<!--                                                        <label class="block text-sm font-medium text-gray-900">-->
<!--                                                            Company </label>-->
<!--                                                        <div class="mt-1">-->
<!--                                                            <select class="block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"-->
<!--                                                                    v-model="inputFilter.filter_company"-->
<!--                                                            >-->
<!--                                                                &lt;!&ndash; eslint-disable-next-line&ndash;&gt;-->
<!--                                                                <option v-for="data in company_list"-->
<!--                                                                        v-bind:value="data.value">-->
<!--                                                                    {{ data.text }}-->
<!--                                                                </option>-->
<!--                                                            </select>-->
<!--                                                        </div>-->
<!--                                                    </div>-->
                                                </div>

                                                <div>
                                                    <div class="space-y-4 pt-6 pb-5 ml-5">
                                                        <label class="block text-sm font-medium text-gray-900">
                                                            Master IP Domain </label>
                                                        <div class="mt-1">
                                                            <input v-model="inputFilter.filter_master_ip_domain" type="text" name="filter_master_ip_domain"
                                                                   id="filter_master_ip_domain"
                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                        </div>
                                                    </div>

                                                    <div class="space-y-4 pt-6 pb-5 ml-5">
                                                        <label class="block text-sm font-medium text-gray-900">
                                                            Master Key </label>
                                                        <div class="mt-1">
                                                            <input v-model="inputFilter.filter_master_key" type="text" name="filter_master_key"
                                                                   id="filter_master_key"
                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                        </div>
                                                    </div>


                                                    <div class="space-y-4 pt-6 pb-5 ml-5">
                                                        <label class="block text-sm font-medium text-gray-900">
                                                            Region </label>
                                                        <div class="mt-1">
                                                            <select class="block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                    v-model="inputFilter.filter_region"
                                                            >
                                                                <!-- eslint-disable-next-line-->
                                                                <option v-for="data in region_list"
                                                                        v-bind:value="data.value">
                                                                    {{ data.text }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="flex flex-shrink-0 px-4 py-4">
                                                <button type="button"
                                                        class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        @click="open_filter = false; resetFilter()">Reset Filter
                                                </button>
                                                <button type="submit"
                                                        class="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        @click="open_filter = false; inputFilter.filter_status = 'True'"
                                                >
                                                    Apply Filter
                                                </button>
                                            </div>

                                        </div>
                                    </form>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>

        <list-store :input-filter="inputFilter" :key="childKey" /> <!--display list store-->

        <div class="mt-10 pagination m-1 custom-center-div">
            <button @click="prevPage" v-if="showPreviousButton">❮</button>
            <button @click="nextPage" v-if="showNextButton">❯</button>
        </div>

    </div>
</template>

<script>
    import {ref} from "vue";
    import axios from "axios";
    import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/outline'
    import ListStore from "@/views/Store/ListStore";
    import {
        Dialog,
        DialogPanel,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
    } from '@headlessui/vue'
    import {XIcon} from '@heroicons/vue/outline'
    export default {
        name: "FilterData",
        components: {
            Dialog,
            DialogPanel,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            XIcon,
            ListStore
        },
        data() {
            return {
                open_filter: ref(false),
                createStoreGroup: false,
                showNextButton: false,
                showPreviousButton: false,
                currentPage:1,
                search_query: ref(''),
                data:[],
                company_list: [
                    {text: 'SVI', value: 'svi'},
                    {text: 'SSM', value: 'ssm'},
                    {text: 'SMCo', value: 'smco'},
                ],
                region_list: [
                    {text: 'NCR', value: 'NCR'},
                    {text: 'North Luzon', value: 'North Luzon'},
                    {text: 'South Luzon', value: 'South Luzon'},
                    {text: 'Visayas', value: 'Visayas'},
                    {text: 'Mindanao', value: 'Mindanao'},
                ],
                inputFilter: {
                    search_query: '',
                    filter_store_id: '',
                    filter_store: '',
                    filter_master_ip_domain: '',
                    filter_master_key: '',
                    filter_pos_key: '',
                    filter_region: '',
                    filter_status: 'False'
                },
                childKey: 0,
            }
        },
        mounted() {
            const token =  localStorage.getItem('token')
            axios.defaults.headers.common['Authorization'] = "Token " + token
        },
        methods: {
            search() {
                this.filter_status = 'False'
                this.currentPage = 1
                this.showNextButton = false
                this.showPreviousButton = false
                // this.inputFilter.search = this.search_query
                this.reloadChild()
            },
            submitApplyFilter: function () {
                console.log('Filter')
                this.currentPage = 1
                this.showNextButton = false
                this.showPreviousButton = false
                this.inputFilter.search_query = ""
                this.reloadChild()
            },
            resetFilter: function () {
                this.inputFilter.search_query = ""
                this.inputFilter.filter_store_id = ""
                this.inputFilter.filter_store = ""
                this.inputFilter.filter_master_ip_domain = ""
                this.inputFilter.filter_master_key = ""
                this.inputFilter.filter_pos_key = ""
                this.inputFilter.filter_region = ""
                this.inputFilter.filter_status = 'False'
                this.inputFilter.search = ""
                this.inputFilter.filter_store_id = ""

                this.currentPage = 1
                this.showNextButton = false
                this.showPreviousButton = false
                this.reloadChild()
            },
            reloadChild() {
              this.childKey++; // Change the key to "reload" the child component
            },
        }
    }
</script>

<style scoped>
    .pagination {
      display: inline-block;
    }

    .pagination button {
      color: black;
      float: left;
      padding: 8px 16px;
      text-decoration: none;
      transition: background-color .3s;
      border: 1px solid #ddd;
    }

    .pagination button.active {
      background-color: #4CAF50;
      color: white;
      border: 1px solid #4CAF50;
    }

    .custom-center-div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .pagination button:hover:not(.active) {background-color: #ddd;}
</style>