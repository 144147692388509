<template>
<!--    <p> {{ inputFilter }} </p>-->
    <div class="mt-8 flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead class="bg-gray-50">
                        <tr>
                            <th scope="col"
                                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Action
                            </th>
                            <th scope="col"
                                @click="sortHeader('store_group_name')"
                                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Store Group Name
                            </th>
                            <th scope="col"
                                @click="sortHeader('is_active')"
                                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Status
                            </th>
                            <th scope="col"
                                @click="sortHeader('is_active')"
                                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Sync
                            </th>
                        </tr>
                        </thead>
                        <tbody class="bg-white text-left">

                        <tr v-for="(strGrp, strGrpIdx) in data" :key="strGrp.store_group_name"
                            :class="strGrpIdx % 2 === 0 ? undefined : 'bg-gray-50'">
<!--                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">-->
<!--                                {{ strGrp.store.store_id }}-->
<!--                            </td>-->

                            <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6">
                                <a href="#" class="text-indigo-600 hover:text-indigo-900"
                                   @click="open = true; selectedItem(strGrp)"
                                >Edit<span class="sr-only">, {{ strGrp.store_group }}</span></a
                                >
                            </td>

                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {{ strGrp.store_group_name }}
                            </td>

                            <td v-if="strGrp.is_active === true" class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                  <path fill="#70bf2b" d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
                                </svg>
                            </td>
                            <td v-else class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                  <path fill="#dd4646" d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
                                </svg>
                            </td>
                            <td v-if="strGrp.sync === true" class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                  <path fill="#70bf2b" d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
                                </svg>
                            </td>
                            <td v-else class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                  <path fill="#dd4646" d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
                                </svg>
                            </td>


                        </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

        <div class="mt-10 pagination m-1 custom-center-div">
            <button @click="prevPage" v-if="showPreviousButton">❮</button>
            <button @click="nextPage" v-if="showNextButton">❯</button>
        </div>

        <div class="px-4 sm:px-6 lg:px-8">
        <!--edit store group-->
        <TransitionRoot as="template" :show="open">
            <Dialog as="div" class="relative z-10" @close="open = false">
                <div class="fixed inset-0"/>

                <div class="fixed inset-0 overflow-hidden">
                    <div class="absolute inset-0 overflow-hidden">
                        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16 ">
                            <TransitionChild as="template"
                                             enter="transform transition ease-in-out duration-500 sm:duration-700"
                                             enter-from="translate-x-full" enter-to="translate-x-0"
                                             leave="transform transition ease-in-out duration-500 sm:duration-700"
                                             leave-from="translate-x-0" leave-to="translate-x-full">
                                <DialogPanel class="pointer-events-auto w-screen max-w-md">
                                    <form class="flex
                                                 h-full
                                                 flex-col
                                                 divide-y
                                                 divide-gray-200
                                                 bg-white
                                                 shadow-xl"
                                    >
                                        <div class="h-0 flex-1 overflow-y-auto">
                                            <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                                                <div class="flex items-center justify-between">
                                                    <DialogTitle class="text-lg font-medium text-white">
                                                        Edit Store Group
                                                    </DialogTitle>
                                                    <div class="ml-3 flex h-7 items-center">
                                                        <button type="button"
                                                                class="rounded-md
                                                                       bg-indigo-700
                                                                       text-indigo-200
                                                                       hover:text-white"
                                                                @click="open = false">
                                                            <span class="sr-only">Close panel</span>
                                                            <XIcon class="h-6 w-6" aria-hidden="true"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <data-fields
                                                    :action="action"
                                                    :selectedData="selectedData"
                                            /> <!--display fields-->

                                        </div>
                                    </form>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>
    </div>

    </div>
</template>

<script>
    import axios from "axios";
    import {ref} from "vue";
    import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
    import {XIcon} from "@heroicons/vue/outline";
    import DataFields from "@/views/StoreGroup/DataFields";
    export default {
        name: "ListStoreGroup",
        components: {
            Dialog,
            DialogPanel,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            XIcon,
            DataFields
        },
        props: {
            inputFilter: Object,
        },
        data() {
            return {
                showNextButton: false,
                showPreviousButton: false,
                currentPage:1,
                search_query: ref(''),
                order_name: 'store_group_name',
                currentSortDir:'asc',
                data: [],
                selectedData: [],
                open: ref(false),
                action: "update",
                id: null,
                filter: this.inputFilter,
            }
        },
        mounted() {
            const token = localStorage.getItem('token')
            axios.defaults.headers.common['Authorization'] = "Token " + token
            this.getData()
        },
        methods: {
            nextPage:function() {
                this.currentPage += 1
                if (this.filter_status === "True") {
                    this.applyFilter()
                } else {
                    this.getData()
                }
            },
            prevPage:function() {
                this.currentPage -= 1
                if (this.filter_status === "True") {
                    this.applyFilter()
                } else {
                    this.getData()
                }
            },
            getData() {
                let filter = {
                    "page": this.currentPage,
                    "search": this.inputFilter.search_query,
                    "store_group_name": this.inputFilter.filter_store_group_name,
                    "orderby": this.order_name,
                    "sort": this.currentSortDir,
                    "filter_status": this.inputFilter.filter_status,
                }

                axios.get(`/api/v1/store-groups` , {params: filter}).then(res => {
                    // console.log(res.data);
                    this.data = res.data.results;

                    this.showNextButton = !!res.data.next;
                    this.showPreviousButton = !!res.data.previous;

                }).catch(error => {
                    console.log(error)
                })
            },
            submitApplyFilter: function () {
                console.log('Filter')
                this.currentPage = 1
                this.showNextButton = false
                this.showPreviousButton = false
            },
            sortHeader:function(s) {
                if(s === this.currentSort) {
                   this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
                } else {
                     this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
                }
                this.order_name = s

                if (this.filter_status === "True") {
                    this.applyFilter()
                } else {
                    this.getData()
                }
            },
            selectedItem(strGrp){
                this.selectedData =  strGrp
            },
        },
    }
</script>

<style scoped>
    .pagination {
      display: inline-block;
    }

    .pagination button {
      color: black;
      float: left;
      padding: 8px 16px;
      text-decoration: none;
      transition: background-color .3s;
      border: 1px solid #ddd;
    }

    .pagination button.active {
      background-color: #4CAF50;
      color: white;
      border: 1px solid #4CAF50;
    }

    .custom-center-div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .pagination button:hover:not(.active) {background-color: #ddd;}
</style>