<template>
    <div v-if="token === true">
        <SideBar/>
    </div>

    <div v-if="token === false">
        <LoginView/>
    </div>


</template>

<script>
    import SideBar from "@/components/SideBar";
    import LoginView from "@/views/LoginView";
    import axios from "axios";

    export default {
        name: 'App',
        components: {
            SideBar,
            LoginView
        },
        data() {
            return {
                username: '',
                password: '',
                token: '',
                auth_token: ''

            }
        },
        mounted() {
            this.auth()
        },
        beforeCreate() {
            this.$store.commit('initializeStore')

            const token = this.$store.state.token

            if (token) {
                axios.defaults.headers.common['Authorization'] = "Token" + token
            } else {
                axios.defaults.headers.common['Authorization'] = ''
            }
        },
        methods: {
            auth() {
                // console.log(this.$store.state.token)
                this.auth_token = this.$store.state.token
                if (this.auth_token) {
                    axios.defaults.headers.common['Authorization'] = "Token " + this.auth_token
                    axios.post("/api/v1/auth", '').then(res => {
                        // console.log(res)
                        this.token = true
                    }).catch(error => {
                        // console.log(error)
                        this.token = false
                        localStorage.removeItem('token')
                        location.reload();
                    })
                } else {
                     this.token = false
                    localStorage.removeItem('token')
                }

            },

        }
    }
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }
</style>
