<template>
    <div class="loading-modal" v-if="isLoading">
        <div class="loading-content">
            <div class="spinner" style="display: inline-block;"></div>
            <p>Loading...</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LoaderContainer",
        props: {
            isLoading: Boolean
        }
    }
</script>


<style scoped>
    .loading-modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }

    .loading-content {
      text-align: center;
      background-color: white;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    }

    .spinner {
      border: 4px solid rgba(255, 255, 255, 0.3);
      border-top: 4px solid #3498db;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
</style>