<template>
     <input
        type="file"
        ref="fileInput"
        style="display: none"
        accept=".csv,.xlsx"
        @change="handleFileChange"
      />

    <div v-if="isVisible" class="context-menu" :style="{ top, left }">
        <ul>
          <li v-for="(item, index) in menuItems" :key="index" @click="handleItemClick(item)">
              {{ item.label }}
          </li>
        </ul>
    </div>

    <input type="text"
           v-model="inputValue"
           class="w-25
                  focus:ring-indigo-500
                  sm:text-sm custom-input"
           style="border: 0"
           :placeholder="[valueOperator === '[]' ? 'From' : '...']"
           @contextmenu.prevent="showContextMenu($event)"
           @change="onChange"
           required/>

    <input type="text"
           v-model="inputValue2"
           v-if="valueOperator === '[]'"
           class="w-25 focus:ring-indigo-500 sm:text-sm custom-input ml-5"
           style="border: 0"
           placeholder="To"
           @change="onChange"
           required/>


</template>

<script>
    import Papa from 'papaparse';
    import readXlsxFile from 'read-excel-file';
    export default {
        name: "ContextMenu",
        props: {
            value: String,
            value2: String,
            valueOperator: String,
        },
        data() {
            return {
                isVisible: false,
                selectedFile: null,
                data: [],
                file: "",
                firstColumn: [],
                top: "",
                left: "",
                inputValue: this.value,
                inputValue2: this.value2,
                menuItems: [
                    { label: 'Import', value: 'import' },
                    { label: 'Cancel', value: 'cancel' },
                ],
            }
        },
        methods: {
            handleItemClick(item) {
                this.$emit('itemSelected', item);
                this.isVisible = false;
                if (item.value === 'import') {
                    this.$refs.fileInput.click();
                }
            },
            uploadFile() {
                if (this.selectedFile) {
                    console.log('Selected file:', this.selectedFile);
                } else {
                    console.log('No file selected.');
                }
            },
            showContextMenu(event) {
                event.preventDefault();
                this.isVisible = true;
                this.top = `${event.clientY}px`;
                this.left = `${event.clientX}px`;

            },
            openFileDialog() {
                this.$refs.fileInput.click();
            },
            async handleFileChange(event) {
                this.isVisible = false;
                this.file = this.$refs.fileInput.files[0]

                if (this.file["type"] === 'text/csv') {
                    Papa.parse(this.file, {
                        complete:(result) => {
                            this.firstColumn = result.data.map(row => row[0]).filter(item => item.trim() !== "");
                            console.log(this.firstColumn)
                            this.inputValue  = this.firstColumn.join(",")
                            this.onChange()
                        }
                    })
                } else {
                    await this.readExcelFile(this.file);
                }

                this.clearFields()
            },
            async readExcelFile(file) {
                try {
                    const data = await readXlsxFile(file);
                    if (data.length > 0) {
                      this.firstColumn = data.map(row => row[0]);
                      this.inputValue = this.firstColumn.join(",")
                    }
                    this.onChange()
                } catch (error) {
                    console.error('Error reading Excel file:', error);
                }
            },
            clearFields() {
                 this.file = ''
                 this.data = ''
                 this.$refs.fileInput.value = null
            },
            onChange() {
                this.$emit('update:value', this.inputValue);
                this.$emit('update:value2', this.inputValue2);
            }
        },
    }
</script>

<style scoped>
    .context-menu {
      position: absolute;
      background-color: #fff;
      border: 1px solid #ccc;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
      z-index: 1000;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    li {
      padding: 10px;
      cursor: pointer;
      border-bottom: 1px solid #ccc;
    }

    li:last-child {
      border-bottom: none;
    }

    .file-input-container {
  position: relative;
}

.file-input-button {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

.context-menu {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

li:last-child {
  border-bottom: none;
}
</style>