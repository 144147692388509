<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full">
    <body class="h-full">
    ```
  -->
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-40 md:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>

        <div class="fixed inset-0 z-40 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="relative max-w-xs w-full bg-white pt-5 pb-4 flex-1 flex flex-col">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute top-0 right-0 -mr-12 pt-2">
                  <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div class="flex-shrink-0 px-4 flex items-center">
<!--                <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg" alt="Workflow" />-->
                <b>SM PROMO ENGINE</b>
              </div>
              <div class="mt-5 flex-1 h-0 overflow-y-auto">
                <nav class="px-2 space-y-1">
                  <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group rounded-md py-2 px-2 flex items-center text-base font-medium']">
                    <component :is="item.icon" :class="[item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-4 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                    {{ item.name }}
                  </a>
                </nav>

                <nav class="flex-1 px-2 py-4 space-y-1">
                  <a v-on:click.prevent="logout()" v-for="item in settings" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
                    <component :is="item.icon" :class="[item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                    {{ item.name }}
                  </a>
                </nav>
              </div>

            </DialogPanel>
          </TransitionChild>
          <div class="flex-shrink-0 w-14">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="border-r border-gray-200 pt-5 flex flex-col flex-grow bg-white overflow-y-auto">
        <div class="flex-shrink-0 px-4 flex items-center">
<!--          <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg" alt="Workflow" />-->
          <b>SM PROMO ENGINE</b>
        </div>
        <div class="flex-grow mt-5 flex flex-col">
          <nav class="flex-1 px-2 space-y-1 bg-white" aria-label="Sidebar">
            <template v-for="item in navigation" :key="item.name">
              <div v-if="!item.children">
                <a href="#" :class="[item.current ? 'bg-gray-100 text-gray-900' : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md']">
                  <component :is="item.icon" :class="[item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                  {{ item.name }}
                </a>
              </div>
              <Disclosure as="div" v-else class="space-y-1" v-slot="{ open }">
                <DisclosureButton :class="[item.current ? 'bg-gray-100 text-gray-900' : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500']">
                  <component :is="item.icon" class="mr-3 flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  <span class="flex-1">
                    {{ item.name }}
                  </span>
                  <svg :class="[open ? 'text-gray-400 rotate-90' : 'text-gray-300', 'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150']" viewBox="0 0 20 20" aria-hidden="true">
                    <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                  </svg>
                </DisclosureButton>
                <DisclosurePanel class="space-y-1">
                  <DisclosureButton v-for="subItem in item.children" :key="subItem.name" as="a" :href="subItem.href" class="group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50">
                    {{ subItem.name }}
                  </DisclosureButton>
                </DisclosurePanel>
              </Disclosure>
            </template>

            <a v-on:click.prevent="logout()" v-for="item in settings" :key="item.name"  href="#" :class="[item.current ? 'bg-gray-100 text-gray-900' : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md']">
              <component :is="item.icon" :class="[item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
              {{ item.name }}
            </a>
          </nav>
        </div>
      </div>
    </div>

    <div class="md:pl-64">
      <div class="max-w-7xl mx-auto flex flex-col md:px-8 xl:px-0">
        <router-view/>
<!--        <main class="flex-1">-->
<!--          <div class="py-6">-->
<!--            <div class="px-4 sm:px-6 md:px-0">-->
<!--              <h1 class="text-2xl font-semibold text-gray-900">Dashboard</h1>-->
<!--            </div>-->
<!--            <div class="px-4 sm:px-6 md:px-0">-->
<!--              &lt;!&ndash; Replace with your content &ndash;&gt;-->
<!--              <div class="py-4">-->
<!--                <div class="h-96 border-4 border-dashed border-gray-200 rounded-lg" />-->
<!--              </div>-->
<!--              &lt;!&ndash; /End replace &ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->
<!--        </main>-->
      </div>
    </div>
  </div>
</template>

<script>
    import { ref } from 'vue'
    import { Dialog,
            DialogPanel,
            Menu,
            MenuButton,
            MenuItem,
            MenuItems,
            TransitionChild,
            TransitionRoot,
            Disclosure, DisclosureButton, DisclosurePanel
    } from '@headlessui/vue'
    import {
            BellIcon,
            ClockIcon,
            CogIcon,
            CreditCardIcon,
            DocumentReportIcon,
            HomeIcon,
            MenuAlt1Icon,
            QuestionMarkCircleIcon,
            ScaleIcon,
            ShieldCheckIcon,
            UserGroupIcon,
            XIcon,
            LinkIcon,
            SpeakerphoneIcon,
            LogoutIcon
          } from '@heroicons/vue/outline'
    import {
        CashIcon,
        CheckCircleIcon,
        ChevronDownIcon,
        ChevronRightIcon,
        OfficeBuildingIcon,
        SearchIcon,
      } from '@heroicons/vue/solid'
    import axios from "axios";
    export default {
        name: "SideBar",
        components: {
            Dialog,
            DialogPanel,
            Menu,
            MenuButton,
            MenuItem,
            MenuItems,
            TransitionChild,
            TransitionRoot,
            BellIcon,
            ClockIcon,
            CogIcon,
            CreditCardIcon,
            DocumentReportIcon,
            HomeIcon,
            MenuAlt1Icon,
            QuestionMarkCircleIcon,
            ScaleIcon,
            ShieldCheckIcon,
            UserGroupIcon,
            XIcon,
            CashIcon,
            SpeakerphoneIcon,
            LinkIcon,
            CheckCircleIcon,
            ChevronDownIcon,
            ChevronRightIcon,
            OfficeBuildingIcon,
            SearchIcon,
            Disclosure,
            DisclosureButton,
            DisclosurePanel,
        },
        data() {
            return {
                sidebarOpen: ref(false),
                navigation : [
                    { name: 'Marketing', icon: SpeakerphoneIcon, current: true,
                      children: [
                          { name: 'Promo Rule', href: '/promo_rule' },
                          { name: 'Voucher', href: '/voucher' },
                          // { name: 'Store', href: '/store_setup' },
                          { name: 'Store Group', href: '/store_group' },
                          { name: 'Import & Export', href: '/import_export' },
                      ] ,
                    },
                ],
                settings : [
                      { name: 'Logout', icon: LogoutIcon, current: true,
                      },
                  ],
                userNavigation: [
                    {name: 'Sign out', href: '/sample'},
                ],
                user: {
                  name: 'Tom Cook',
                  email: 'tom@example.com',
                  imageUrl:
                    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
                },

            }
        },
        methods: {
          logout() {
              console.log('logout')

              const token = localStorage.getItem('token')
              axios.defaults.headers.common['Authorization'] = "Token " + token
              axios.post("/api/v1/token/logout/").then(res => {
                    console.log(res.data);
                    this.token = false
                    localStorage.removeItem('token')
                    location.reload();

                    // this.stores = res.data;
              }).catch(error => {
                    console.log(error)
              })
          }
        }
    }
</script>

<style scoped>

</style>