<template>
<!--<SideBar/>-->
    <div class="py-10">
        <header>
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 class="text-3xl font-bold leading-tight text-gray-900">Catalog Price Rule</h1>
            </div>
        </header>
        <main>
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <!-- Replace with your content -->
                <div class="px-4 py-8 sm:px-0">
                    <!--            <div class="border-4 border-dashed border-gray-200 rounded-lg h-96" />-->
                    <!-- table start -->
                    <div class="px-4 sm:px-6 lg:px-8">
                        <div class="sm:flex sm:items-center">
                            <div class="sm:flex-auto">
                                <!--                                <h1 class="text-xl font-semibold text-gray-900">Promo</h1>-->
                            </div>
                            <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                <button type="button"
                                        class="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                        @click="open = true; createPromo = true; updatePromo=  false; clearAll()"
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                        <div class="mt-8 flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-300">
                                            <thead class="bg-gray-50">
                                            <tr>
                                                <th scope="col"
                                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Promo Name
                                                </th>
                                                <th scope="col"
                                                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Promo Code
                                                </th>
                                                <th scope="col"
                                                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Start Date
                                                </th>
                                                <th scope="col"
                                                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    End Date
                                                </th>
                                                <th scope="col"
                                                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Number of Usage
                                                </th>
                                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span class="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody class="bg-white">

                                            <tr v-for="(promo, promoIdx) in promos" :key="promo.promo_name"
                                                :class="promoIdx % 2 === 0 ? undefined : 'bg-gray-50'">
                                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    {{ promo.promo_name }}
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {{ promo.promo_code }}
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {{ promo.start_date }}
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {{ promo.end_date }}
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {{ promo.number_of_usage }}
                                                </td>
                                                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <a href="#" class="text-indigo-600 hover:text-indigo-900"
                                                       @click="open = true; createPromo = false; updatePromo = true; selectedPromo(promo)"
                                                    >Edit<span class="sr-only">, {{ promo.promo_code }}</span></a
                                                    >
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- table end -->

                    <!-- start dialog information -->
                    <TransitionRoot as="template" :show="open">
                        <Dialog as="div" class="relative z-10" @close="open = false">
                            <div class="fixed inset-0"/>

                            <div class="fixed inset-0 overflow-hidden">
                                <div class="absolute inset-0 overflow-hidden">
                                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16 ">
                                        <TransitionChild as="template"
                                                         enter="transform transition ease-in-out duration-500 sm:duration-700"
                                                         enter-from="translate-x-full" enter-to="translate-x-0"
                                                         leave="transform transition ease-in-out duration-500 sm:duration-700"
                                                         leave-from="translate-x-0" leave-to="translate-x-full">
                                            <DialogPanel class="pointer-events-auto w-screen max-w-5xl">
                                                <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                                                >
                                                    <div class="h-0 flex-1 overflow-y-auto">
                                                        <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                                                            <div class="flex items-center justify-between">
                                                                <DialogTitle class="text-lg font-medium text-white"
                                                                             v-if="this.createPromo === true">
                                                                    Add Catalog Price Rule
                                                                </DialogTitle>
                                                                <DialogTitle class="text-lg font-medium text-white"
                                                                             v-if="this.updatePromo === true">
                                                                    Edit Catalog Price Rule
                                                                </DialogTitle>
                                                                <div class="ml-3 flex h-7 items-center">
                                                                    <button type="button"
                                                                            class="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                                            @click="open = false">
                                                                        <span class="sr-only">Close panel</span>
                                                                        <XIcon class="h-6 w-6" aria-hidden="true"/>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <!--                                          <div class="mt-1">-->
                                                            <!--                                            <p class="text-sm text-indigo-300">Get started by filling in the information below to create your new project.</p>-->
                                                            <!--                                          </div>-->
                                                        </div>
                                                        <div class="flex flex-1 flex-col justify-between">
                                                            <div class="divide-y divide-gray-200 px-4 sm:px-6">
                                                                <div class="space-y-6 pt-6 pb-5">
                                                                    <div>
                                                                        <label for="promo-name"
                                                                               class="block text-sm font-medium text-gray-900">
                                                                            Promo Name </label>
                                                                        <div class="mt-1">
                                                                            <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <input type="text" name="promo-name"
                                                                                   id="promo-name"
                                                                                   v-model="promoName"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label for="promo-code"
                                                                               class="block text-sm font-medium text-gray-900">
                                                                            Promo Code </label>
                                                                        <div class="mt-1">
                                                                            <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <input type="text" name="promo-code"
                                                                                   id="promo-code"
                                                                                   v-model="promoCode"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label class="block text-sm font-medium text-gray-900">
                                                                            Stores </label>
                                                                        <div class="mt-1">
                                                                            <select v-model="selectedStores" multiple>
                                                                                <!-- eslint-disable-next-line-->
                                                                                <option v-for="store in stores"
                                                                                        v-bind:value="store.id">
                                                                                    {{ store.store }}
                                                                                </option>
                                                                            </select>
                                                                            <br>
<!--                                                                            <span>Selected: {{ selectedStores }}</span>-->
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label for="start-date"
                                                                               class="block text-sm font-medium text-gray-900">
                                                                            Start Date </label>
                                                                        <div class="mt-1">
                                                                            <input type="datetime-local" name="start-date"
                                                                                   id="start-date"
                                                                                   v-model="startDate"
                                                                                   format="MM/DD/YYYY hh:mm"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label for="end-date"
                                                                               class="block text-sm font-medium text-gray-900">
                                                                            End Date </label>
                                                                        <div class="mt-1">
                                                                            <input type="datetime-local" name="end-date"
                                                                                   id="end-date"
                                                                                   v-model="endDate"
                                                                                   format="MM/DD/YYYY hh:mm"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label for="number-usage"
                                                                               class="block text-sm font-medium text-gray-900">
                                                                            Number of Usage </label>
                                                                        <div class="mt-1">
                                                                            <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <input type="text" name="number-usage"
                                                                                   id="number-usage"
                                                                                   v-model="numberOfUsage"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label for="customer-number-usage"
                                                                               class="block text-sm font-medium text-gray-900">
                                                                            Number of Usage Customer </label>
                                                                        <div class="mt-1">
                                                                            <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <input type="text"
                                                                                   name="customer-number-usage"
                                                                                   id="customer-number-usage"
                                                                                   v-model="numberOfUsageCustomer"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label for="aggregator"
                                                                               class="block text-sm font-medium text-gray-900">
                                                                            Aggregator </label>
                                                                        <div class="mt-1">
                                                                            <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <!--                                                    <input type="text" name="aggregator" id="aggregator" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <select class="block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                                    v-model="aggregatorValue">
                                                                                <!-- eslint-disable-next-line-->
                                                                                <option v-for="aggregator in aggregators"
                                                                                        v-bind:value="aggregator.value">
                                                                                    {{ aggregator.text }}
                                                                                </option>
                                                                            </select>
                                                                            <br>
                                                                            <span>Selected: {{ aggregatorValue }}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label for="aggregator-value"
                                                                               class="block text-sm font-medium text-gray-900">
                                                                            Value Aggregator </label>
                                                                        <div class="mt-1">
                                                                            <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <!--                                                    <input type="text" name="aggregator-value" id="aggregator-value" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <div class="mt-1">
                                                                                <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                                <!--                                                    <input type="text" name="aggregator" id="aggregator" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                                <select class="block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                                        v-model="conditionValue">
                                                                                    <!-- eslint-disable-next-line-->
                                                                                    <option v-for="data in condition"
                                                                                            v-bind:value="data.value">
                                                                                        {{ data.text }}
                                                                                    </option>
                                                                                </select>
                                                                                <br>
                                                                                <span>Selected: {{ conditionValue }}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label class="block text-sm font-medium text-gray-900">
                                                                            Apply Discount </label>
                                                                        <div class="mt-1">
                                                                            <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <!--                                                    <input type="text" name="aggregator-value" id="aggregator-value" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <div class="mt-1">
                                                                                <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                                <!--                                                    <input type="text" name="aggregator" id="aggregator" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                                <select class="block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                                        v-model="applyDiscount">
                                                                                    <!-- eslint-disable-next-line-->
                                                                                    <option v-for="data in applyDiscountType"
                                                                                            v-bind:value="data.value">
                                                                                        {{ data.text }}
                                                                                    </option>
                                                                                </select>
                                                                                <br>
                                                                                <span>Selected: {{ applyDiscount }}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label for="trans-disc-sku-id"
                                                                               class="block text-sm font-medium text-gray-900">
                                                                            Transaction Discount SKU ID </label>
                                                                        <div class="mt-1">
                                                                            <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <input type="text"
                                                                                   name="trans-disc-sku-id"
                                                                                   id="trans-disc-sku-id"
                                                                                   v-model="transDiscSkuId"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label for="trans-disc-barcode"
                                                                               class="block text-sm font-medium text-gray-900">
                                                                            Transaction Discount Barcode </label>
                                                                        <div class="mt-1">
                                                                            <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <input type="text"
                                                                                   name="trans-disc-barcode"
                                                                                   id="trans-disc-barcode"
                                                                                   v-model="transDiscSkuBarcode"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                        </div>
                                                                    </div>
                                                                    <SwitchGroup as="div" class="flex items-center">
                                                                        <Switch v-model="enabled"
                                                                                :class="[enabled ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                                                            <span aria-hidden="true"
                                                                                  :class="[enabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                                                        </Switch>
                                                                        <SwitchLabel as="span" class="ml-3">
                                                                            <span class="text-sm font-medium text-gray-900">Status </span>
                                                                        </SwitchLabel>
                                                                    </SwitchGroup>


                                                                </div>
                                                                <!-- start promo conditions -->

                                                                <DialogTitle class="text-lg font-medium text-white"> Conditions </DialogTitle>
                                                                <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                                                                  <div class="flex items-center justify-between">
                                                                    <DialogTitle class="text-lg font-medium text-white"> Conditions </DialogTitle>
                                                                  </div>
                                                                </div>

                                                                <div id="app" style="margin-top: 0; !important;">

                                                                    <div class="row " style="display: flex!important;">
                                                                        <div class="mt-4 mb-4">
                                                                            <label for="promo-attribute"
                                                                                   class="block text-sm font-medium text-gray-900">
                                                                                Attribute </label>
                                                                            <div class="mt-1 mr-3">
                                                                                <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                                <select v-for="(input,k) in conditions" :key="k" style="height: 110%;"
                                                                                        class="mb-3 block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                                            v-model="input.attribute">
                                                                                        <!-- eslint-disable-next-line-->
                                                                                        <option v-for="attribute in attributes"
                                                                                                v-bind:value="attribute.value">
                                                                                            {{ attribute.text }}
                                                                                        </option>
                                                                                    </select>
                                                                            </div>
                                                                        </div>

                                                                        <div class="mt-4 mb-4">
                                                                            <label for="promo-name"
                                                                                   class="block text-sm font-medium text-gray-900">
                                                                                Operator </label>
                                                                            <div class="mt-1 mr-3">
                                                                                <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                                <select v-for="(input,k) in conditions" :key="k" style="height: 110%;"
                                                                                        class="mb-3 block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                                            v-model="input.operator">
                                                                                        <!-- eslint-disable-next-line-->
                                                                                        <option v-for="operator in operators"
                                                                                                v-bind:value="operator.value">
                                                                                            {{ operator.text }}
                                                                                        </option>
                                                                                    </select>
                                                                            </div>
                                                                        </div>

                                                                        <div class="mt-4 mb-4">
                                                                             <label class="block text-sm font-medium text-gray-900">
                                                                                    Value </label>
                                                                            <div style="display:flex; !important;" v-for="(input,k) in conditions" :key="k">

                                                                                <div class="mt-1 mr-3">
                                                                                    <input type="text"
                                                                                           v-model="input.value"
                                                                                           style="height: 80%;"
                                                                                           class="mb-3 block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                                </div>

                                                                                <span>
                                                                                    <i class="fas fa-minus-circle" @click="remove(k)" v-show="k || ( !k && conditions.length > 1)"></i>
                                                                                    <i class="fas fa-plus-circle" @click="add()" v-show="k === conditions.length-1"></i>
                                                                                </span>
                                                                            </div>

                                                                        </div>

                                                                     </div>
                                                                 </div>
                                                                <!-- end promo conditions -->

                                                                <!-- Start promo action -->
                                                                <DialogTitle class="text-lg font-medium text-white"> Action </DialogTitle>
                                                                <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                                                                  <div class="flex items-center justify-between">
                                                                    <DialogTitle class="text-lg font-medium text-white"> Action </DialogTitle>
                                                                  </div>
                                                                </div>

                                                                <div>
                                                                    <label class="block text-sm font-medium text-gray-900">
                                                                        Apply Action </label>
                                                                    <div class="mt-1"><div class="mt-1">
                                                                            <select class="block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                                    v-model="applyActionValue">
                                                                                <!-- eslint-disable-next-line-->
                                                                                <option v-for="data in applyAction"
                                                                                        v-bind:value="data.value">
                                                                                    {{ data.text }}
                                                                                </option>
                                                                            </select>
                                                                            <br>
<!--                                                                            <span>Selected: {{ applyActionValue }}</span>-->
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <label for="discount-amount"
                                                                           class="block text-sm font-medium text-gray-900">
                                                                        Discount Amount </label>
                                                                    <div class="mt-1">
                                                                        <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                        <input type="number" name="discount-amount"
                                                                               id="discount-amount"
                                                                               v-model="discountAmount"
                                                                               class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <label class="block text-sm font-medium text-gray-900">
                                                                        Promo Message </label>
                                                                    <div class="mt-1">
                                                                        <input type="text" name="promo-message"
                                                                               id="promo-message"
                                                                               v-model="promoMessage"
                                                                               class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                    </div>
                                                                </div>
                                                                <!-- End promo action -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="flex flex-shrink-0 justify-end px-4 py-4">
                                                        <button type="button"
                                                                class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                @click="open = false">Cancel
                                                        </button>
                                                        <button type="submit"
                                                                class="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                v-on:click.prevent="submitForm()"
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                </form>
                                            </DialogPanel>
                                        </TransitionChild>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </TransitionRoot>
                    <!-- end dialof information -->
                </div>
                <!-- /End replace -->
            </div>
        </main>
    </div>
    <router-view/>
</template>

<script>
    import {computed, ref} from 'vue'
    import {
        Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,

        Switch,
        SwitchGroup,
        SwitchLabel
        //   Combobox,
        // ComboboxButton,
        // ComboboxInput,
        // ComboboxLabel,
        // ComboboxOption,
        // ComboboxOptions,
    } from '@headlessui/vue'
    import {XIcon} from '@heroicons/vue/outline'
    import axios from "axios";
    import moment from 'moment'
    export default {
        el: "#app",
        name: "CatalogPriceRule",
        components: {
            Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, XIcon,
            Switch,
            SwitchGroup,
            SwitchLabel
            // Combobox,
            // ComboboxButton,
            // ComboboxInput,
            // ComboboxLabel,
            // ComboboxOption,
            // ComboboxOptions,
            // CheckIcon, SelectorIcon
        },
        data() {
            return {
                flag: '',
                rule: 'catalog_rule',
                createPromo: false,
                updatePromo: false,
                promoSelected: [],
                valid: false,
                promoId: '',
                promoName: '',
                promoCode: '',
                promos: [],
                open: ref(false),
                people: [
                    {id: 1, name: 'Leslie Alexander'},
                ],
                query: ref(''),
                selectedPerson: ref(),
                filteredPeople: computed(() =>
                    this.query.value === ''
                        ? this.people
                        : this.people.filter((person) => {
                            return person.name.toLowerCase().includes(this.query.value.toLowerCase())
                        })
                ),
                stores: [
                ],
                selectedStores: [],
                startDate: null,
                endDate: null,
                numberOfUsage: 0,
                numberOfUsageCustomer: 0,
                aggregators: [
                    {text: 'All', value: 'all'},
                    {text: 'Any', value: 'any'},
                ],
                aggregatorValue: 'all',
                condition: [
                    {text: 'True', value: 1},
                    {text: 'False', value: 0},
                ],
                conditions: [
                    {
                        attribute: '',
                        operator: '',
                        value: '',
                    }
                ],
                conditionValue: ref(1),
                attributes: [
                    {text: '', value: ''},
                    {text: 'Item ID', value: 'item_id'},
                    {text: 'Barcode', value: 'barcode'},
                    // {text: 'Mobile',  value: 'mobile'},
                    // {text: 'SMAC',    value: 'smac'},
                ],
                attributeValue: '',
                operators: [
                    {text: '', value: ''},
                    {text: 'contains', value: '{}'},
                    {text: 'does not contain', value: '!{}'},
                    {text: 'is', value: '=='},
                    {text: 'is one of', value: '()'},
                    {text: 'is not', value: '!='},
                    {text: 'is not one of', value: '!()'},
                ],
                operatorValue: '',
                applyAction: [
                    {text: '', value: ''},
                    {text: 'Apply as percentage of original' , value: 'catalog_percentage_original'},
                    {text: 'Apply as fixed amount' , value: 'catalog_fixed_amount'},
                    {text: 'Adjust final price to this percentage' , value: 'catalog_to_percent'},
                    {text: 'Adjust final price to discount value' , value: 'catalog_to_fixed'},
                ],
                applyActionValue: '',
                discountAmount: 0,
                enabled: ref(true),
                applyDiscountType: [
                    {text: 'Override Discount', value: 'override_discount'},
                    {text: 'Do not Apply Discount', value: 'do_not_apply_discount'},
                    {text: 'Combine Discount', value: 'combine_discount'},
                ],
                applyDiscount: ref("combine_discount"),
                transDiscSkuId: '',
                transDiscSkuBarcode: '',
                promoMessage: ''
            }
        },
        mounted() {
            this.getPromos()
            this.getStores()
        },
        methods: {
            getPromos() {
                const token = localStorage.getItem('token')
                axios.defaults.headers.common['Authorization'] = "Token " + token
                axios.get("/api/v1/catalog_rule/").then(res => {
                    // console.log(res.data);
                    this.promos = res.data;
                }).catch(error => {
                    console.log(error)
                })
            },
            getStores() {
                const token = localStorage.getItem('token')
                axios.defaults.headers.common['Authorization'] = "Token " + token
                axios.get("/api/v1/stores/").then(res => {
                    // console.log(res.data);
                    this.stores = res.data;
                }).catch(error => {
                    console.log(error)
                })
            },
            add() {
                this.conditions.push({ name: '' });
            },
            remove(index) {
                this.conditions.splice(index, 1);
            },
            clearAll(){
                console.log('add')
                this.promoId = ""
                this.promoName = ""
                this.promoCode = ""

                this.startDate = null
                this.endDate = null
                this.numberOfUsage = 0
                this.numberOfUsageCustomer = 0
                this.aggregatorValue = 'all'
                this.conditionValue = ref(1)
                this.applyActionValue = ''
                this.discountAmount = 0
                this.applyDiscount = ref("combine_discount")
                this.enabled = ref(true)
                this.conditions =  [
                    {
                        attribute: '',
                        operator: '',
                        value: '',
                    }
                    ]
                this.transDiscSkuId = ''
                this.transDiscSkuBarcode = ''
                this.promoMessage = ''
                this.getStores()
            },
            selectedPromo(promo) {
                console.log('update')
                console.log(promo)
                this.promoId = promo['id']
                this.promoName = promo['promo_name']
                this.promoCode = promo['promo_code']
                let stores = []
                Object.keys(promo['stores']).map((key) => {
                    stores.push(promo['stores'][key]['id'])
                })
                this.selectedStores = stores
                this.startDate = moment(String(promo['start_date'])).format('YYYY-MM-DDTkk:mm')
                this.endDate = moment(String(promo['end_date'])).format('YYYY-MM-DDTkk:mm')
                this.numberOfUsage = promo['number_of_usage']
                this.numberOfUsageCustomer = promo['number_of_usage_customer']
                this.aggregatorValue = promo['aggregator']
                this.conditionValue = promo['value_aggregator']
                this.applyActionValue = promo['apply_action']
                this.discountAmount = promo['discount_amount']
                this.applyDiscount = promo['apply_discount']
                this.enabled = promo['is_active']
                this.conditions = promo['conditions_serialized']
                this.transDiscSkuId = promo["transaction_disc_sku_id"]
                this.transDiscSkuBarcode = promo["transaction_disc_sku_barcode"]
                this.promoMessage = promo["promo_message"]
                // let conditions = []
                // Object.keys(promo['conditions']).map((key) => {
                //     conditions.push(promo['conditions'][key])
                // })
                // if (promo['conditions'].length > 0) {
                //      this.conditions = conditions
                // } else {
                //      this.conditions =  [
                //     {
                //         attribute: '',
                //         operator: '',
                //         value: '',
                //     }
                // ]
                // }
                // this.conditions = promo['conditions_serialized']

            },
            submitForm() {
                console.log('create')
                if (this.startDate === 'Invalid date' || this.startDate === '') {
                    this.startDate = null
                }

                if (this.endDate === 'Invalid date' || this.endDate === '') {
                    this.endDate = null
                }

                let data = {
                    rule: this.rule,
                    promo_id: this.promoId,
                    promo_name: this.promoName,
                    promo_code: this.promoCode,
                    stores: this.selectedStores,
                    start_date: this.startDate,
                    end_date: this.endDate,
                    number_of_usage: this.numberOfUsage,
                    number_of_usage_customer: this.numberOfUsageCustomer,
                    aggregator: this.aggregatorValue,
                    condition_value: this.conditionValue,
                    apply_discount: this.applyDiscount,
                    status: this.enabled,
                    promo_conditions: this.conditions,
                    apply_action: this.applyActionValue,
                    discount_amount: this.discountAmount,
                    transaction_disc_sku_id: this.transDiscSkuId,
                    transaction_disc_sku_barcode: this.transDiscSkuBarcode,
                    promo_message: this.promoMessage
                }

                console.log(data)

                console.log(this.createPromo)
                console.log(this.updatePromo)

                if (this.createPromo === true) {
                    const token = localStorage.getItem('token')
                    axios.defaults.headers.common['Authorization'] = "Token " + token
                    axios.post("/api/v1/add_catalog_rule/", data).then(res => {
                        console.log(res.data);
                        location.reload();
                    }).catch(error => {
                        console.log(error)
                    })
                }

                if (this.updatePromo === true) {
                    const token = localStorage.getItem('token')
                    axios.defaults.headers.common['Authorization'] = "Token " + token
                    axios.post("/api/v1/update_catalog_rule/", data).then(res => {
                        console.log(res.data);
                        location.reload();
                    }).catch(error => {
                        console.log(error)
                    })
                }

            }
        }
    }
</script>

<style scoped>

</style>