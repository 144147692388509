<template>
    <div id="store_group_max_usage"
         style="margin-top: 10px; !important;"
         v-for="(input,k) in store_group_max_usage" :key="k">
        <div class="row " style="display: flex!important;">
            <div class="mt-0 mb-0">
                <label v-show="k === 0"
                       class="block text-sm font-medium text-gray-900"
                >
                    Store Group </label>
                <div class="mt-1 mr-3">
                    <select style="height: 110%;"
                            class="mb-3
                                   block
                                   w-50
                                   rounded-md
                                   border-gray-300
                                   shadow-sm
                                   focus:border-indigo-500
                                   focus:ring-indigo-500
                                   sm:text-sm"
                            v-model="input.store_group"
                            :disabled="disable_select_store(input.store_group)"
                            @click="selectedStoreGroupMaxUsage(store_group_max_usage, input.store_group)"
                            @change="onChange"
                    >
                        <!-- eslint-disable-next-line-->
                        <option v-for="data in storeGroups"
                                v-bind:value="data.store_group_name" :disabled="data.status === false">
                            {{ data.store_group_name }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="mt-0 mb-0">
                 <label v-show="k === 0"
                        class="block text-sm font-medium text-gray-900"
                 >
                        Max Usage </label>

                <div style="display:flex; !important;">
                    <div class="mt-1 mr-3">
                        <input type="number"
                               v-model="input.max_usage"
                               style="height: 80%;"
                               onkeypress="return !(event.charCode == 46)"
                               @change="onChange"
                               class="mb-3
                                      block
                                      w-50
                                      rounded-md
                                      border-gray-300
                                      shadow-sm
                                      focus:border-indigo-500
                                      focus:ring-indigo-500
                                      sm:text-sm"
                        />
                    </div>


                </div>

            </div>

            <div class="mt-0 mb-0">
                <label v-show="k === 0"
                       class="block text-sm font-medium text-gray-900"
                >
                    Per Store / All </label>
                <div style="display:flex; !important;" class="mt-1 mr-3">
                    <select style="height: 110%;"
                            class="mb-3
                                   mr-3
                                   block
                                   w-50
                                   rounded-md
                                   border-gray-300
                                   shadow-sm
                                   focus:border-indigo-500
                                   focus:ring-indigo-500
                                   sm:text-sm"
                            v-model="input.option"
                            @change="onChange"
                    >
                        <!-- eslint-disable-next-line-->
                        <option v-for="data in storeGroupOption"
                                v-bind:value="data.value">
                            {{ data.text }}
                        </option>
                    </select>

                     <i class="fas fa-minus-circle"
                       @click="remove_store_group_max_usage(k, input.store_group)"
                       v-show="k || ( !k && store_group_max_usage.length > 1)">
                    </i>
                    <i class="fas fa-plus-circle"
                       @click="add_store_group_max_usage(input.store_group)"
                       v-show="k === store_group_max_usage.length-1">
                    </i>

                </div>
            </div>

         </div>

    </div>
</template>

<script>
    export default {
        name: "StoreGroup",
        props: {
            valueStoreGroupMaxUsage: Array,
            valueStoreGroups: Array
        },
        data() {
            return {
                store_group_max_usage: this.valueStoreGroupMaxUsage,
                storeGroups: this.valueStoreGroups,
                storeGroupOption: [
                    {text: 'Select', value: ''},
                    {text: 'Per Store', value: 'per_store'},
                    {text: 'All', value: 'all'},
                ]
            }
        },
        mounted() {
            console.log(this.store_group_max_usage)
        },
        methods: {
            disable_select_store(input_store){
                // console.log(input_store)
                // if (input_store) {
                //     return true
                // }
                // return false
                return !!input_store;

            },
            selectedStoreGroupMaxUsage(list_store_group_max_usage, input_store_group) {
                // console.log(list_store_max_usage.length)
                this.storeGroups = this.storeGroups.map(v => ({...v, status: true}));
                if (list_store_group_max_usage.length - 1 > 0) {
                    let i=0;
                    for (i; i < list_store_group_max_usage.length -1; i++) {
                        let filter_store = this.storeGroups.filter(s => {
                            return s.store_group_name.includes(list_store_group_max_usage[i]['store_group'])
                        })
                        filter_store[0].status = false
                        // console.log(filter_store)

                    }
                }

                if (input_store_group !== '') {
                     let filter_input_store = this.storeGroups.filter(s => {
                        return s.store_group_name.includes(input_store_group)
                     })
                     filter_input_store[0].status = false
                }
                this.onChange()
            },
            add_store_group_max_usage(input_store_id) {
                this.selectedStoreGroupMaxUsage(this.store_group_max_usage, input_store_id)
                this.store_group_max_usage.push({
                    store_group: '',
                    max_usage: 0,}
                    );
            },
            remove_store_group_max_usage(index, input_store_id) {
                this.selectedStoreGroupMaxUsage(this.store_group_max_usage, input_store_id)
                this.store_group_max_usage.splice(index, 1);
            },
            onChange() {
                this.$emit('update:valueStoreGroupMaxUsage', this.store_group_max_usage);
                this.$emit('update:valueStoreGroups', this.storeGroups);
            }
        }
    }
</script>

<style scoped>

</style>