<template>
    <div class="px-4 sm:px-6 lg:px-8">
        <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex" style="justify-content: flex-end;">
            <button type="button"
                class="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-lg font-bold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                @click="open = true;"
            >
                Add
            </button>
        </div>

        <TransitionRoot as="template" :show="open">
            <Dialog as="div" class="relative z-10" @close="open = false">
                <div class="fixed inset-0"/>

                <div class="fixed inset-0 overflow-hidden">
                    <div class="absolute inset-0 overflow-hidden">
                        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16 ">
                            <TransitionChild as="template"
                                             enter="transform transition ease-in-out duration-500 sm:duration-700"
                                             enter-from="translate-x-full" enter-to="translate-x-0"
                                             leave="transform transition ease-in-out duration-500 sm:duration-700"
                                             leave-from="translate-x-0" leave-to="translate-x-full">
                                <DialogPanel class="pointer-events-auto w-screen max-w-md">
                                    <div class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                        <div class="h-0 flex-1 overflow-y-auto">
                                            <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                                                <div class="flex items-center justify-between">
                                                    <DialogTitle class="text-lg font-medium text-white">
                                                        Add Store Group
                                                    </DialogTitle>
                                                    <div class="ml-3 flex h-7 items-center">
                                                        <button type="button"
                                                                class="rounded-md bg-indigo-700 text-indigo-200 hover:text-white"
                                                                @click="open = false">
                                                            <span class="sr-only">Close panel</span>
                                                            <XIcon class="h-6 w-6" aria-hidden="true"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <data-fields
                                                    :action="action"
                                                    :selectedData="selectedData"
                                            /> <!--display fields-->

                                        </div>

                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>
    </div>
</template>

<script>
    import {
        Dialog,
        DialogPanel,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
    } from '@headlessui/vue'
    import {XIcon} from '@heroicons/vue/outline'
    import DataFields from "@/views/StoreGroup/DataFields";
    import {ref} from "vue";
    export default {
        name: "CreateStoreGroup",
        components: {
            DataFields,
            Dialog,
            DialogPanel,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            XIcon,
        },
        data() {
            return {
                open: ref(false),
                action: "create",
                selectedData: [],
            }
        },
        mounted() {

        },
        methods: {
        },

    }
</script>

<style scoped>

</style>