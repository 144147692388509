<template>
    <loader-container :isLoading="loading_data"/>
    <div class="py-10">
        <header>
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 class="text-3xl font-bold leading-tight text-gray-900">Import & Export</h1>
            </div>
        </header>
        <div v-show="this.success_msg !== ''" class="flex p-4 mb-4 text-sm blue border-t border-b border-blue-500 bg-blue-100 rounded-lg dark:text-blue-400" role="alert">
            <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <div>
                <span class="font-medium bg-transparent">{{ success_msg }} </span>
                <span v-show="this.error_boolean" class="font-medium bg-transparent text-red-700"> -
                    <a href="#" @click="downloadErrorLog()" class="text-decoration-underline">
                        Click to download error log
                    </a>
                </span>
            </div>
        </div>
        <div v-show="this.error_msg !== ''" class="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:text-red-400" role="alert">
          <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
          <span class="sr-only">Info</span>
          <div>
             <span class="font-medium bg-transparent"> {{ error_msg }} </span>
              <span v-show="this.error_boolean" class="font-medium bg-transparent text-red-700"> -
                    <a href="#" @click="downloadErrorLog()" class="text-decoration-underline">
                        Click to download error log
                    </a>
                </span>
          </div>
        </div>
        <main class="py-10">
<!--            <div class="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">-->
                <div class="space-y-6 lg:col-span-2 lg:col-start-1 custom-width-import-export inline-grid">
                    <!-- Description list-->
                    <section aria-labelledby="applicant-information-title">
                        <div class="bg-white shadow sm:rounded-lg">
                            <div class="px-4 py-5 sm:px-6">
                                <h1 id="import-file" class="text-lg font-m leading-6 text-gray-900"><b>Import</b></h1>
<!--                                <p class="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>-->
                            </div>
                            <div class="border-none border-gray-200 px-4 py-5 sm:px-6">
                                <form method="post" enctype="multipart/form-data" v-on:submit.prevent="importCSV">
<!--                                    <div class="py-20 h-screen bg-gray-300 px-2">-->
                                    <div class="max-w-md mx-auto bg-white rounded-lg overflow-hidden md:max-w-lg">
                                        <div class="md:flex">
                                            <div class="w-full">
                                                <div class="p-3">
                                                    <div class="mb-2">
                                                        <div class="relative h-40 rounded-lg border-dashed border-2 border-gray-200 bg-white flex justify-center items-center hover:cursor-pointer">
                                                            <div class="w-full absolute">
                                                                <div class="flex flex-col items-center ">
                                                                    <i class="fa fa-cloud-upload fa-3x text-gray-200"></i>
                                                                    <span class="block text-gray-400 font-normal">Attach your file here</span>
                                                                    <span class="block text-gray-400 font-normal">or</span>
                                                                    <span class="block text-blue-400 font-normal">Browse file</span>
                                                                    <input class="w-full border-0 text-center text-sm" type="text" v-model="file_name" disabled>
                                                                </div>
                                                            </div>
                                                            <input type="file" accept=".csv," class="h-full w-full opacity-0" ref="csv_file" @change="onFileChange" required/>
                                                        </div>
                                                        <div class="flex justify-between items-center text-gray-400">
                                                            <span>Accepted file type:.csv only</span>
                                                            <span class="flex items-center "><i class="fa fa-lock mr-1"></i> secure</span>
                                                        </div>
                                                        <div class="flex items-center text-gray-400">
                                                            <span>Type: </span>
                                                            <select class="ml-2 block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                    v-model="import_value" required>
                                                                <!-- eslint-disable-next-line-->
                                                                <option v-for="data in import_type"
                                                                        v-bind:value="data.value">
                                                                    {{ data.type }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="flex justify-between items-center text-gray-400">
                                                            <span>Download a <a href="#" @click="downloadTemplate()"><b><u>sample CSV template</u></b></a></span>
                                                        </div>
<!--                                                        <div class="flex justify-between items-center text-gray-400">-->
<!--                                                            <span>Download a <a href="#" @click="downloadTemplateVoucehr()"><b><u>sample CSV Voucher template</u></b></a></span>-->
<!--                                                        </div>-->
                                                    </div>
                                                    <div class="mt-3 text-center pb-3 inline-block">
                                                        <input type="submit"
                                                               accept=".csv,"
                                                               value="Import File"
                                                               class="block rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                                        >
                                                    </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </section>

                    <section aria-labelledby="applicant-information-title">
                        <div class="bg-white shadow sm:rounded-lg">
                            <div class="px-4 py-5 sm:px-6">
                                <h1 id="export-file" class="text-lg font-m leading-6 text-gray-900"><b>Export</b></h1>
<!--                                <p class="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>-->
                            </div>
                            <div class="border-none border-gray-200 px-4 py-5 sm:px-6" style="display: inline-block;">
                                <form method="get" enctype="multipart/form-data" v-on:submit.prevent="exportCSV" >
<!--                                    <div class="py-20 h-screen bg-gray-300 px-2">-->
                                    <!--checkbox start-->
                                    <div class="flex items-center text-gray-400">
                                        <span>Select : </span>
                                        <select class="ml-2 block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                v-model="export_value" required>
                                            <!-- eslint-disable-next-line-->
                                            <option v-for="data in export_box"
                                                    v-bind:value="data.value">
                                                {{ data.type }}
                                            </option>
                                        </select>
<!--                                        <legend class="sr-only">Notifications</legend>-->
<!--                                        <div v-for="box in export_box" :key="box.type" class="relative flex items-start">-->
<!--                                            <div class="flex h-5 items-center">-->
<!--                                                <input v-model="box.value" :value="box.value" aria-describedby="comments-description" name="comments" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />-->
<!--                                            </div>-->
<!--                                            <div class="ml-3 text-sm">-->
<!--                                                <label class="font-medium text-gray-700" v-text="box.type"></label>-->
<!--                                            </div>-->
<!--                                        </div>-->
                                    </div>
                                    <!--checkbox end-->
                                    <div class="max-w-md mx-auto bg-white rounded-lg overflow-hidden md:max-w-lg">
                                        <div class="md:flex">
                                            <div class="w-full">
                                                <div class="p-3">
                                                    <div class="mt-3 text-center pb-3 inline-block">
                                                        <input download type="submit"
                                                               value="Export File"
                                                               class="block rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                                        >
                                                    </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <!-- start promo filter -->
                                <TransitionRoot as="template" :show="promo_filter">
                                    <Dialog as="div" class="relative z-10" @close="promo_filter = false">
                                        <div class="fixed inset-0"/>
                                        <div class="fixed inset-0 overflow-hidden">
                                            <div class="absolute inset-0 overflow-hidden">
                                                <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16 ">
                                                    <TransitionChild as="template"
                                                                     enter="transform transition ease-in-out duration-500 sm:duration-700"
                                                                     enter-from="translate-x-full" enter-to="translate-x-0"
                                                                     leave="transform transition ease-in-out duration-500 sm:duration-700"
                                                                     leave-from="translate-x-0" leave-to="translate-x-full">
                                                        <DialogPanel class="pointer-events-auto w-screen max-w-8xl">
                                                            <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                                                                  @submit.prevent="exportPromo"
                                                            >
                                                                <div class="h-0 flex-1 overflow-y-auto">
                                                                    <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                                                                        <div class="flex items-center justify-between">
                                                                            <DialogTitle class="text-lg font-medium text-white">
                                                                                Promo Filter
                                                                            </DialogTitle>
                                                                            <div class="ml-3 flex h-7 items-center">
                                                                                <button type="button"
                                                                                        class="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                                                        @click="promo_filter = false">
                                                                                    <span class="sr-only">Close panel</span>
                                                                                    <XIcon class="h-6 w-6" aria-hidden="true"/>
                                                                                </button>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <div class="flex">
                                                                        <div>
                                                                            <div class="space-y-4 pt-6 pb-5 ml-5">
                                                                                <label class="block text-lg font-weight-bold font-medium text-gray-900">
                                                                                    Start Date </label>
                                                                                <label class="block text-sm font-medium text-gray-900">
                                                                                    Start Date From </label>
                                                                                <div class="mt-1">
                                                                                    <input v-model="filter_promo_start_date_from" type="datetime-local" name="filter-start-date-from"
                                                                                           id="filter-start-date-from"
                                                                                           format="MM/DD/YYYY hh:mm"
                                                                                           class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"

                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div class="space-y-4 pb-5 ml-5">
                                                                                <label class="block text-sm font-medium text-gray-900">
                                                                                    Start Date To </label>
                                                                                <div class="mt-1">
                                                                                    <input v-model="filter_promo_start_date_to" type="datetime-local" name="filter-start-date-to"
                                                                                           id="filter-start-date-to"
                                                                                           format="MM/DD/YYYY hh:mm"
                                                                                           class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"

                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div>
                                                                            <div class="space-y-4 pt-6 pb-5 ml-5">
                                                                                <label class="block text-lg font-weight-bold font-medium text-gray-900">
                                                                                    End Date </label>
                                                                                <label class="block text-sm font-medium text-gray-900">
                                                                                    End Date From </label>
                                                                                <div class="mt-1">
                                                                                    <input v-model="filter_promo_end_date_from" type="datetime-local" name="filter-end-date-from"
                                                                                           id="filter-end-date-from"
                                                                                           format="MM/DD/YYYY hh:mm"
                                                                                           class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"

                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div class="space-y-4 pb-5 ml-5">
                                                                                <label class="block text-sm font-medium text-gray-900">
                                                                                    End Date To </label>
                                                                                <div class="mt-1">
                                                                                    <input v-model="filter_promo_end_date_to" type="datetime-local" name="filter-end-date-t0"
                                                                                           id="filter-end-date-t0"
                                                                                           format="MM/DD/YYYY hh:mm"
                                                                                           class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"

                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div>
                                                                            <div class="space-y-4 pt-6 pb-5 ml-5">
                                                                                <label class="block text-lg font-weight-bold font-medium text-gray-900">
                                                                                    Discount Amount </label>
                                                                                <label class="block text-sm font-medium text-gray-900">
                                                                                    Discount Amount From </label>
                                                                                <div class="mt-1">
                                                                                    <input v-model="filter_promo_discount_amount_from" type="number" name="filter_discount_amount_from"
                                                                                           id="filter_discount_amount_from"
                                                                                           class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                                </div>
                                                                            </div>

                                                                            <div class="space-y-4 pb-5 ml-5">
                                                                                <label class="block text-sm font-medium text-gray-900">
                                                                                    Discount Amount To </label>
                                                                                <div class="mt-1">
                                                                                    <input v-model="filter_promo_discount_amount_to" type="number" name="filter_discount_amount_to"
                                                                                           id="filter_discount_amount_to"
                                                                                           class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div>
                                                                            <div class="space-y-4 pt-6 pb-5 ml-5">
                                                                                <label class="block text-lg font-weight-bold font-medium text-gray-900">
                                                                                    Max no. of usage </label>
                                                                                <label class="block text-sm font-medium text-gray-900">
                                                                                    Max no. of usage from </label>
                                                                                <div class="mt-1">
                                                                                    <input v-model="filter_promo_usage_from" type="number" name="filter_max_usage_from"
                                                                                           id="filter_max_usage_from"
                                                                                           class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                                </div>
                                                                            </div>

                                                                            <div class="space-y-4 pb-5 ml-5">
                                                                                <label class="block text-sm font-medium text-gray-900">
                                                                                    Max no. of usage to </label>
                                                                                <div class="mt-1">
                                                                                    <input v-model="filter_promo_usage_to" type="number" name="filter_max_usage_to"
                                                                                           id="filter_max_usage_to"
                                                                                           class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div>
                                                                            <div class="space-y-4 pt-6 pb-5 ml-5">
                                                                                <label class="block text-lg font-weight-bold font-medium text-gray-900">
                                                                                    Max no. of usage customer </label>
                                                                                <label class="block text-sm font-medium text-gray-900">
                                                                                    Max no. of usage customer from </label>
                                                                                <div class="mt-1">
                                                                                    <input v-model="filter_promo_usage_customer_from" type="number" name="filter_max_usage_customer_from"
                                                                                           id="filter_max_usage_customer_from"
                                                                                           class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                                </div>
                                                                            </div>

                                                                            <div class="space-y-4 pb-5 ml-5">
                                                                                <label class="block text-sm font-medium text-gray-900">
                                                                                    Max no. of usage customer to </label>
                                                                                <div class="mt-1">
                                                                                    <input v-model="filter_promo_usage_customer_to" type="number" name="filter_max_usage_customer_to"
                                                                                           id="filter_max_usage_customer_to"
                                                                                           class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div>
                                                                            <div class="space-y-4 pt-6 pb-5 ml-5">
                                                                                <label class="block text-lg font-weight-bold font-medium text-gray-900">
                                                                                    Priority </label>
                                                                                <label class="block text-sm font-medium text-gray-900">
                                                                                    Priority from </label>
                                                                                <div class="mt-1">
                                                                                    <input v-model="filter_promo_priority_from" type="number" name="filter_priority_from"
                                                                                           id="filter_priority_from"
                                                                                           class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                                </div>
                                                                            </div>

                                                                            <div class="space-y-4 pb-5 ml-5">
                                                                                <label class="block text-sm font-medium text-gray-900">
                                                                                    Priority to </label>
                                                                                <div class="mt-1">
                                                                                    <input v-model="filter_promo_priority_to" type="number" name="filter_priority_to"
                                                                                           id="filter_priority_to"
                                                                                           class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="flex">
                                                                        <div class="space-y-6 pt-6 pb-5 ml-5">
                                                                            <label class="block text-sm font-medium text-gray-900">
                                                                                Promo Code </label>
                                                                            <div class="mt-1">
                                                                                <input v-model="filter_promo_code" type="text" name="filter_promo_code"
                                                                                       id="filter_promo_code"
                                                                                       class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                            </div>
                                                                        </div>

                                                                        <div class="space-y-6 pt-6 pb-5 ml-5">
                                                                            <label class="block text-sm font-medium text-gray-900">
                                                                                Promo Name </label>
                                                                            <div class="mt-1">
                                                                                <input v-model="filter_promo_name" type="text" name="filter_promo_name"
                                                                                       id="filter_promo_name"
                                                                                       class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="flex">
                                                                        <div class="space-y-6 pt-6 pb-5 ml-5">
                                                                            <label class="block text-sm font-medium text-gray-900">
                                                                                Apply Discount </label>
                                                                            <div class="mt-1">
                                                                                <select class="block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                                        v-model="filter_apply_discount"
                                                                                >
                                                                                    <!-- eslint-disable-next-line-->
                                                                                    <option v-for="data in applyDiscountType"
                                                                                            v-bind:value="data.value">
                                                                                        {{ data.text }}
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>

                                                                        <div class="space-y-6 pt-6 pb-5 ml-5">
                                                                            <label class="block text-sm font-medium text-gray-900">
                                                                                Apply Action </label>
                                                                            <div class="mt-1">
                                                                                <select class="block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                                        v-model="filter_apply_action"
                                                                                >
                                                                                    <!-- eslint-disable-next-line-->
                                                                                    <option v-for="data in applyAction"
                                                                                            v-bind:value="data.value">
                                                                                        {{ data.text }}
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="flex">
                                                                        <div class="space-y-6 pt-6 pb-5 ml-5">
                                                                            <label class="block text-sm font-medium text-gray-900">
                                                                                Stacking </label>
                                                                            <div class="mt-1">
                                                                                <SwitchGroup as="div" class="flex items-center">
                                                                                        <Switch v-model="filter_stacking"
                                                                                            :class="[filter_stacking ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                                                                        <span aria-hidden="true"
                                                                                              :class="[filter_stacking ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                                                                    </Switch>
                                                                                </SwitchGroup>
                                                                            </div>
                                                                        </div>

                                                                        <div class="space-y-6 pt-6 pb-5 ml-5">
                                                                            <label class="block text-sm font-medium text-gray-900">
                                                                                Status </label>
                                                                            <div class="mt-1">
                                                                                <SwitchGroup as="div" class="flex items-center">
                                                                                        <Switch v-model="filter_is_active"
                                                                                            :class="[filter_is_active ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                                                                        <span aria-hidden="true"
                                                                                              :class="[filter_is_active ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                                                                    </Switch>
                                                                                </SwitchGroup>
                                                                            </div>
                                                                        </div>

                                                                        <div class="space-y-6 pt-6 pb-5 ml-5">
                                                                            <label class="block text-sm font-medium text-gray-900">
                                                                                SMAC Required </label>
                                                                            <div class="mt-1">
                                                                                <SwitchGroup as="div" class="flex items-center">
                                                                                        <Switch v-model="filter_smac"
                                                                                            :class="[filter_smac ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                                                                        <span aria-hidden="true"
                                                                                              :class="[filter_smac ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                                                                    </Switch>
                                                                                </SwitchGroup>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="space-y-6 pt-6 pb-5 ml-5">
                                                                            <label class="block text-sm font-medium text-gray-900">
                                                                                Filter All </label>
                                                                            <div class="mt-1">
                                                                                <SwitchGroup as="div" class="flex items-center">
                                                                                        <Switch v-model="filter_promo_all"
                                                                                            :class="[filter_promo_all ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                                                                        <span aria-hidden="true"
                                                                                              :class="[filter_promo_all ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                                                                    </Switch>
                                                                                </SwitchGroup>
                                                                            </div>
                                                                        </div>

                                                                    <div class="flex flex-shrink-0 px-4 py-4">
                                                                        <button type="button"
                                                                                class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                                @click="open_filter = false; filter_status = 'False'; resetPromoFilter()">Reset Filter
                                                                        </button>

                                                                    </div>

                                                                    <div class="flex flex-shrink-0">
                                                                        <button type="submit"
                                                                                class="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                                @click="open_filter = false; filter_status = 'True'"
                                                                        >
                                                                            Export
                                                                        </button>
                                                                    </div>

                                                                </div>
                                                            </form>
                                                        </DialogPanel>
                                                    </TransitionChild>
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog>
                                </TransitionRoot>

                                <!-- end promo filter -->

                                <!-- start voucher filter -->
                                <TransitionRoot as="template" :show="voucher_filter">
                                    <Dialog as="div" class="relative z-10" @close="voucher_filter = false">
                                        <div class="fixed inset-0"/>
                                        <div class="fixed inset-0 overflow-hidden">
                                            <div class="absolute inset-0 overflow-hidden">
                                                <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16 ">
                                                    <TransitionChild as="template"
                                                                     enter="transform transition ease-in-out duration-500 sm:duration-700"
                                                                     enter-from="translate-x-full" enter-to="translate-x-0"
                                                                     leave="transform transition ease-in-out duration-500 sm:duration-700"
                                                                     leave-from="translate-x-0" leave-to="translate-x-full">
                                                        <DialogPanel class="pointer-events-auto w-screen max-w-8xl">
                                                            <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                                                                  @submit.prevent="exportVoucher"
                                                            >
                                                                <div class="h-0 flex-1 overflow-y-auto">
                                                                    <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                                                                        <div class="flex items-center justify-between">
                                                                            <DialogTitle class="text-lg font-medium text-white">
                                                                                Voucher Filter
                                                                            </DialogTitle>
                                                                            <div class="ml-3 flex h-7 items-center">
                                                                                <button type="button"
                                                                                        class="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                                                        @click="voucher_filter = false">
                                                                                    <span class="sr-only">Close panel</span>
                                                                                    <XIcon class="h-6 w-6" aria-hidden="true"/>
                                                                                </button>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <div class="flex">
                                                                        <div>
                                                                            <div class="space-y-4 pt-6 pb-5 ml-5">
                                                                                <label class="block text-lg font-weight-bold font-medium text-gray-900">
                                                                                    Max no. of usage </label>
                                                                                <label class="block text-sm font-medium text-gray-900">
                                                                                    Max no. of usage from </label>
                                                                                <div class="mt-1">
                                                                                    <input v-model="filter_voucher_usage_from" type="number" name="filter_voucher_usage_from"
                                                                                           id="filter_voucher_usage_from"
                                                                                           class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                                </div>
                                                                            </div>

                                                                            <div class="space-y-4 pb-5 ml-5">
                                                                                <label class="block text-sm font-medium text-gray-900">
                                                                                    Max no. of usage to </label>
                                                                                <div class="mt-1">
                                                                                    <input v-model="filter_voucher_usage_to" type="number" name="filter_voucher_usage_to"
                                                                                           id="filter_voucher_usage_to"
                                                                                           class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div>
                                                                            <div class="space-y-4 pt-6 pb-5 ml-5">
                                                                                <label class="block text-lg font-weight-bold font-medium text-gray-900">
                                                                                    Max no. of usage customer </label>
                                                                                <label class="block text-sm font-medium text-gray-900">
                                                                                    Max no. of usage customer from </label>
                                                                                <div class="mt-1">
                                                                                    <input v-model="filter_voucher_usage_customer_from" type="number" name="filter_usage_customer_from"
                                                                                           id="filter_usage_customer_from"
                                                                                           class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                                </div>
                                                                            </div>

                                                                            <div class="space-y-4 pb-5 ml-5">
                                                                                <label class="block text-sm font-medium text-gray-900">
                                                                                    Max no. of usage customer to </label>
                                                                                <div class="mt-1">
                                                                                    <input v-model="filter_voucher_usage_customer_to" type="number" name="filter_usage_customer_to"
                                                                                           id="filter_usage_customer_to"
                                                                                           class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="flex">
                                                                        <div class="space-y-6 pt-6 pb-5 ml-5">
                                                                            <label class="block text-sm font-medium text-gray-900">
                                                                                Voucher Code </label>
                                                                            <div class="mt-1">
                                                                                <input v-model="filter_voucher_code" type="text" name="filter_voucher_code"
                                                                                       id="filter_voucher_code"
                                                                                       class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                            </div>
                                                                        </div>

                                                                        <div class="space-y-6 pt-6 pb-5 ml-5">
                                                                            <label class="block text-sm font-medium text-gray-900">
                                                                                Promo Code </label>
                                                                            <div class="mt-1">
                                                                                <input v-model="filter_voucher_promo_code" type="text" name="filter_voucher_promo_code"
                                                                                       id="filter_voucher_promo_code"
                                                                                       class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="flex">
                                                                        <div class="space-y-6 pt-6 pb-5 ml-5">
                                                                            <label class="block text-sm font-medium text-gray-900">
                                                                                Status </label>
                                                                            <div class="mt-1">
                                                                                <SwitchGroup as="div" class="flex items-center">
                                                                                        <Switch v-model="filter_voucher_is_active"
                                                                                            :class="[filter_voucher_is_active ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                                                                        <span aria-hidden="true"
                                                                                              :class="[filter_voucher_is_active ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                                                                    </Switch>
                                                                                </SwitchGroup>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="flex">
                                                                        <div class="space-y-6 pt-6 pb-5 ml-5">
                                                                            <label class="block text-sm font-medium text-gray-900">
                                                                                Filter All </label>
                                                                            <div class="mt-1">
                                                                                <SwitchGroup as="div" class="flex items-center">
                                                                                        <Switch v-model="filter_voucher_all"
                                                                                            :class="[filter_voucher_all ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                                                                        <span aria-hidden="true"
                                                                                              :class="[filter_voucher_all ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                                                                    </Switch>
                                                                                </SwitchGroup>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="flex flex-shrink-0 px-4 py-4">
                                                                        <button type="button"
                                                                                class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                                @click="voucher_filter = false; resetVoucherFilter()">Reset Filter
                                                                        </button>
                                                                    </div>

                                                                    <div class="flex flex-shrink-0">
                                                                        <button type="submit"
                                                                                class="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                                @click="voucher_filter = false;"
                                                                        >
                                                                            Export
                                                                        </button>
                                                                    </div>

                                                                </div>
                                                            </form>
                                                        </DialogPanel>
                                                    </TransitionChild>
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog>
                                </TransitionRoot>


                                

                                <!-- end voucher filter -->

                                <!-- start log filter -->
                                <TransitionRoot as="template" :show="log_filter">
                                    <Dialog as="div" class="relative z-10" @close="log_filter = false">
                                        <div class="fixed inset-0"/>
                                        <div class="fixed inset-0 overflow-hidden">
                                            <div class="absolute inset-0 overflow-hidden">
                                                <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16 ">
                                                    <TransitionChild as="template"
                                                                     enter="transform transition ease-in-out duration-500 sm:duration-700"
                                                                     enter-from="translate-x-full" enter-to="translate-x-0"
                                                                     leave="transform transition ease-in-out duration-500 sm:duration-700"
                                                                     leave-from="translate-x-0" leave-to="translate-x-full">
                                                        <DialogPanel class="pointer-events-auto w-screen max-w-8xl">
                                                            <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                                                                  @submit.prevent="exportLogs"
                                                            >
                                                                <div class="h-0 flex-1 overflow-y-auto">
                                                                    <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                                                                        <div class="flex items-center justify-between">
                                                                            <DialogTitle class="text-lg font-medium text-white">
                                                                                Log Filter
                                                                            </DialogTitle>
                                                                            <div class="ml-3 flex h-7 items-center">
                                                                                <button type="button"
                                                                                        class="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                                                        @click="log_filter = false">
                                                                                    <span class="sr-only">Close panel</span>
                                                                                    <XIcon class="h-6 w-6" aria-hidden="true"/>
                                                                                </button>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <div class="flex">
                                                                        <div>
                                                                            <div class="space-y-4 pt-6 pb-5 ml-5">
                                                                                <label class="block text-lg font-weight-bold font-medium text-gray-900">
                                                                                    Start Date </label>
                                                                                <label class="block text-sm font-medium text-gray-900">
                                                                                    Start Date From </label>
                                                                                <div class="mt-1">
                                                                                    <input v-model="filter_log_date_from" type="datetime-local" name="filter_log_date_from"
                                                                                           id="filter_log_date_from"
                                                                                           format="MM/DD/YYYY hh:mm"
                                                                                           class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"

                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div class="space-y-4 pb-5 ml-5">
                                                                                <label class="block text-sm font-medium text-gray-900">
                                                                                    Start Date To </label>
                                                                                <div class="mt-1">
                                                                                    <input v-model="filter_log_date_to" type="datetime-local" name="filter_log_date_to"
                                                                                           id="filter_log_date_to"
                                                                                           format="MM/DD/YYYY hh:mm"
                                                                                           class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"

                                                                                    />
                                                                                </div>
                                                                            </div>


                                                                        </div>

                                                                    </div>

                                                                    <div class="flex">
                                                                        <div class="space-y-6 pt-6 pb-5 ml-5">
                                                                                <label class="block text-sm font-medium text-gray-900">
                                                                                    POS ID </label>
                                                                                <div class="mt-1">
                                                                                    <input v-model="filter_log_pos_id" type="text" name="filter_log_pos_id"
                                                                                           id="filter_log_pos_id"
                                                                                           class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                                </div>
                                                                            </div>
                                                                    </div>

                                                                    <div class="flex flex-shrink-0 px-4 py-4">
                                                                        <button type="button"
                                                                                class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                                @click="log_filter = false; resetLogFilter()">Reset Filter
                                                                        </button>
                                                                    </div>

                                                                    <div class="flex flex-shrink-0">
                                                                        <button type="submit"
                                                                                class="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                                @click="log_filter = false;"
                                                                        >
                                                                            Export
                                                                        </button>
                                                                    </div>

                                                                </div>
                                                            </form>
                                                        </DialogPanel>
                                                    </TransitionChild>
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog>
                                </TransitionRoot>

                                <!-- end log filter -->

                            </div>
                        </div>
                    </section>

                </div>
<!--            </div>-->

            <section>
                <div class="mt-8 flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-300">
                                            <thead class="bg-gray-50">
                                            <tr>
                                                <th scope="col"
                                                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Exported Files
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody class="bg-white text-left">

                                            <tr v-for="exported_file in exported_files" :key="exported_file.id" class="bg-gray-50">
                                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {{ exported_file.file_name }}
                                                </td>
                                                
                                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    <a href="#" @click="downloadLink(exported_file.file_name)">Download</a>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                            </div>
                            <div>
                                <div class="mt-10 pagination m-1">
                                    <button @click="prevPage" v-if="showPreviousButton">❮</button>
                                    <button @click="nextPage" v-if="showNextButton">❯</button>
                                </div>
                            </div>

                        </div>
            </section>
        </main>
    </div>
</template>

<script>
    import axios from "axios";
    import moment from "moment";
    import {ref} from "vue";
    import LoaderContainer from "@/components/LoaderContainer";

    import {
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogPanel,
        DialogTitle,
        Switch,
        SwitchGroup,
    } from '@headlessui/vue';
    import {XIcon} from "@heroicons/vue/outline";
    export default {
        name: "ImportExport",
        components: {
            TransitionRoot,
            TransitionChild,
            Dialog,
            DialogPanel,
            DialogTitle,
            XIcon,
            Switch,
            SwitchGroup,
            LoaderContainer,
        },
        data() {
             return {
                 text : 'export_promo',
                 data: [],
                 fileinput: '',
                 file: '',
                 file_name: '',
                 template: '',
                 export_box: [
                     {type: "Promos", value: "promo"},
                     {type: "Vouchers", value: "voucher"},
                     {type: "Logs", value: "log"},
                     {type: "Store Groups", value: "store_group"},
                 ],
                 import_type: [
                     {type: "Select", value: ""},
                     {type: "Promo", value: "promo"},
                     {type: "Voucher", value: "voucher"},
                     {type: "Store Group", value: "store_group"},
                 ],
                 import_value: '',
                 recent_import_value: '',
                 export_value: '',
                 success_msg: '',
                 error_msg: '',
                 promo_filter: false,
                 voucher_filter: false,
                 log_filter: false,
                 filter_promo_all: false,
                 filter_promo_start_date_from: '',
                 filter_promo_start_date_to: '',
                 filter_promo_end_date_from: '',
                 filter_promo_end_date_to: '',
                 filter_promo_discount_amount_from: '',
                 filter_promo_discount_amount_to: '',
                 filter_promo_priority_from: '',
                 filter_promo_priority_to: '',
                 filter_promo_usage_from: '',
                 filter_promo_usage_to: '',
                 filter_promo_usage_customer_from: '',
                 filter_promo_usage_customer_to: '',
                 filter_promo_code: '',
                 filter_promo_name: '',
                 filter_apply_discount: 'combine_discount',
                 filter_apply_action: '',
                 filter_stacking: false,
                 filter_is_active: ref(true),
                 filter_smac: ref(true),
                 filter_status: 'False',
                 applyDiscountType: [
                    {text: 'Override Discount', value: 'override_discount'},
                    {text: 'Do not Apply Discount', value: 'do_not_apply_discount'},
                    {text: 'Combine Discount', value: 'combine_discount'},
                    {text: 'Apply Higher', value: 'apply_higher'},
                 ],
                 applyAction: [
                    {text: '(Please select a value)', value: ''},
                    {text: 'Percent of product price discount' , value: 'cart_by_percent'},
                    {text: 'Fixed amount discount' , value: 'cart_by_fixed'},
                    {text: 'Fixed amount discount for whole cart' , value: 'cart_fixed'},
                    {text: 'Percent amount discount for whole cart' , value: 'cart_percent'},
                    {text: 'Buy X get Y free (discount amount is Y)' , value: 'cart_buy_x_get_y'},
                    {text: 'Bundle Promo' , value: 'bundle_promo'},
                    {text: 'Bundle Promo (Percentage)' , value: 'bundle_promo_percent'},
                    {text: 'Bundle Promo (Fixed Price)' , value: 'bundle_promo_fixed'},
                ],
                 filter_voucher_all: false,
                 filter_voucher_is_active: ref(true),
                 filter_voucher_usage_from: '',
                 filter_voucher_usage_to: '',
                 filter_voucher_usage_customer_from: '',
                 filter_voucher_usage_customer_to: '',
                 filter_voucher_code: '',
                 filter_voucher_promo_code: '',
                 filter_log_date_from: '',
                 filter_log_date_to: '',
                 filter_log_pos_id: '',
                 filter_log_store: '',
                 filter_log_promo_code: '',
                 filter_log_voucher_code: '',
                 filter_log_company_id: '',
                 filter_log_smac: '',
                 filter_log_mobile: '',
                 filter_log_type: '',
                 filter_log_store_id: '',
                 error_boolean: false,
                 loading_data: false,
                 exported_files: [],
                 currentPage:1,
                 showNextButton: false,
                showPreviousButton: false,
             }
         },
        mounted() {
            const token = localStorage.getItem('token')
            axios.defaults.headers.common['Authorization'] = "Token " + token

            this.getExportedFiles()
        },
        methods: {
            resetLogFilter: function () {
                 this.filter_log_date_from = ''
                 this.filter_log_date_to = ''
                 this.filter_log_pos_id = ''
                 this.filter_log_store = ''
                 this.filter_log_promo_code = ''
                 this.filter_log_voucher_code = ''
                 this.filter_log_company_id = ''
                 this.filter_log_smac = ''
                 this.filter_log_mobile = ''
                 this.filter_log_type = ''
            },
            resetVoucherFilter: function () {
                this.filter_voucher_is_active = ref(true)
                this.filter_voucher_usage_from = ''
                this.filter_voucher_usage_to = ''
                this.filter_voucher_usage_customer_from = ''
                this.filter_voucher_usage_customer_to = ''
                this.filter_voucher_code = ''
                this.filter_voucher_promo_code = ''
                this.filter_voucher_all = false
            },
            resetPromoFilter: function () {
                this.filter_status = 'False'
                this.filter_start_date_from = ''
                this.filter_start_date_to = ''
                this.filter_end_date_from = ''
                this.filter_end_date_to = ''
                this.filter_discount_amount_from = ''
                this.filter_discount_amount_to = ''
                this.filter_priority_from = ''
                this.filter_priority_to = ''
                this.filter_usage_from = ''
                this.filter_usage_to = ''
                this.filter_usage_customer_from = ''
                this.filter_usage_customer_to = ''
                this.filter_promo_code = ''
                this.filter_promo_name = ''
                this.filter_apply_discount = 'combine_discount'
                this.filter_apply_action = ''
                this.filter_stacking = false
                this.filter_is_active = ref(true)
                this.filter_smac = ref(true)
                this.filter_promo_all =  false
            },
            clearFields() {
                 this.file = ''
                 this.file_name = ''
                 this.import_value = ''
                 this.$refs.csv_file.value = null
            },
            onFileChange() {
                this.file = this.$refs.csv_file.files[0];
                const maxSizeInBytes = 5 * 1024 * 1024;
                if (this.file.size > maxSizeInBytes) {
                    this.error_msg = 'File size exceeds the limit (5MB).'
                } else {
                    this.file_name = this.file.name
                }

                // console.log(this.file)
            },
            importCSV() {
                // console.log(this.import_value)
                this.loading_data = true
                this.recent_import_value = this.import_value
                if (this.import_value === 'promo') {
                    this.importPromo()
                } else if (this.import_value === 'voucher') {
                    this.importVoucher()
                } else if (this.import_value === 'store_group') {
                    this.importStoreGroup()
                }
                // this.loading_data = false
            },
            async importStoreGroup() {
                console.log('Import Store Group')
                const formData = new FormData();
                formData.append('file', this.file)
                // console.log(formData)
                this.success_msg = ''
                this.error_msg = ''
                this.clearFields()
                try{
                   await axios.post("/api/v1/import-store-group", formData).then(res => {
                       let desc = ''
                       let err_desc = ''

                       if (res.data["count_success"] <= 1) {
                                desc = " success item"
                            } else {
                                desc = " success items"
                            }

                            if (res.data["count_error"] <= 1) {
                                err_desc = " failed item"
                            } else {
                                err_desc = " failed items"
                            }

                            this.error_boolean = res.data["count_error"] > 0;

                            // if (res.data["count_error"] > 0){
                            //     this.error_boolean = true
                            // } else {
                            //     this.error_boolean = false
                            // }
                        if (res.status === 200) {
                            this.success_msg = res.data["message"] +
                                ' - ' + res.data["count_success"] + desc
                                + " and " + res.data["count_error"] + err_desc
                        } else {
                            this.error_msg = res.data["message"] +
                                ' - ' + res.data["count_success"] + desc
                                + " and " + res.data["count_error"] + err_desc
                            console.log(res.data["message"])
                        }
                   })
                } catch (err) {
                    this.error_msg = err["response"]["statusText"]
                    console.log(err)
                }
                this.loading_data = false
            },
            async importStore() {
                console.log('Import Store')
                const formData = new FormData();
                formData.append('file', this.file);
                console.log(formData)
                this.success_msg = ''
                this.error_msg = ''
                this.clearFields()
                try{
                    await axios.post("/api/v1/import-store/", formData).then(res => {
                        let desc = ''

                        if (res.status === 200) {
                            if (res.data["count_success"] <= 1) {
                                desc = " item"
                            } else {
                                desc = " items"
                            }
                            this.success_msg = res.data["message"] + ' - ' + res.data["count_success"] + desc

                        } else {
                            console.log(res.data["message"]);
                        }
                        // location.reload();
                    })
                } catch (err) {
                    this.error_msg = err["response"]["statusText"]
                    console.log(err)
                }

            },
            async importPromo(){
                console.log('Import Promo')
                const formData = new FormData();
                formData.append('file', this.file);
                this.success_msg = ''
                this.error_msg = ''
                this.clearFields()
                try{
                    await axios.post("/api/v1/import-promo/", formData).then(res => {
                        let desc = ''

                        if (res.status === 200) {
                            if (res.data["count_success"] <= 1) {
                                desc = " item"
                            } else {
                                desc = " items"
                            }
                            this.success_msg = res.data["message"] + ' - ' + res.data["count_success"] + desc

                        } else {
                            console.log(res.data["message"]);
                        }
                        // location.reload();
                    })
                } catch (err) {
                    this.error_msg = err["response"]["statusText"]
                    console.log(err)
                }
                this.loading_data = false
            },
            async importVoucher(){
                console.log('Import Voucher')
                const formData = new FormData();
                formData.append('file', this.file);
                this.success_msg = ''
                this.error_msg = ''
                this.clearFields()
                try{
                    await axios.post("/api/v1/import-voucher/", formData).then(res => {
                        // console.log(res.data);
                        let desc = ''
                        let err_desc = ''
                        if (res.data["count_success"] <= 1) {
                                desc = " success item"
                            } else {
                                desc = " success items"
                            }

                            if (res.data["count_error"] <= 1) {
                                err_desc = " failed item"
                            } else {
                                err_desc = " failed items"
                            }

                            if (res.data["count_error"] > 0){
                                this.error_boolean = true
                            } else {
                                this.error_boolean = false
                            }
                        if (res.status === 200) {
                            this.success_msg = res.data["message"] +
                                ' - ' + res.data["count_success"] + desc
                                + " and " + res.data["count_error"] + err_desc
                        } else {
                            this.error_msg = res.data["message"] +
                                ' - ' + res.data["count_success"] + desc
                                + " and " + res.data["count_error"] + err_desc
                            console.log(res.data["message"])
                        }
                        // location.reload();
                    })
                } catch (err) {
                    this.error_msg = err["response"]["statusText"]
                    console.log(err)
                }
                this.loading_data = false
            },
            exportCSV() {
                if (this.export_value === "promo") {
                    this.promo_filter = true;
                    this.voucher_filter = false;
                    this.log_filter = false;
                    this.store_group_filter = false;
                } else if (this.export_value === "voucher") {
                    this.promo_filter = false;
                    this.voucher_filter = true;
                    this.log_filter = false;
                    this.store_group_filter = false;
                } else if (this.export_value === "log") {
                    this.promo_filter = false;
                    this.voucher_filter = false;
                    this.log_filter = true;
                    this.store_group_filter = false;
                } else if (this.export_value === "store_group") {
                    this.promo_filter = false;
                    this.voucher_filter = false;
                    this.log_filter = false;
                    this.exportStoreGroups()
                }
            },
            async exportPromo(){
                console.log('Promos')
                let filter_stacking = ''
                if (this.filter_stacking === true) {
                    filter_stacking = "True"
                } else {
                    filter_stacking = "False"
                }

                let filter_is_active = ''
                if (this.filter_is_active === true) {
                    filter_is_active = "True"
                } else {
                    filter_is_active = "False"
                }

                let filter_smac = ''
                if (this.filter_smac === true) {
                    filter_smac = "True"
                } else {
                    filter_smac = "False"
                }

                let filter_all = ''
                if (this.filter_promo_all === true) {
                    filter_all = "True"
                } else {
                    filter_all = "False"
                }

                let dateNow = moment(new Date()).format('YYYYMMDD')
                const token = localStorage.getItem('token')
                axios.defaults.headers.common['Authorization'] = "Token " + token

                let data = {
                    "start_date_from": this.filter_promo_start_date_from,
                    "start_date_to": this.filter_promo_start_date_to,
                    "end_date_from": this.filter_promo_end_date_from,
                    "end_date_to": this.filter_promo_end_date_to,
                    "disc_amount_from": this.filter_promo_discount_amount_from,
                    "disc_amount_to": this.filter_promo_discount_amount_to,
                    "no_usage_from": this.filter_promo_usage_from,
                    "no_usage_to": this.filter_promo_usage_to,
                    "no_usage_customer_from": this.filter_promo_usage_customer_from,
                    "no_usage_customer_to": this.filter_promo_usage_customer_to,
                    "priority_from": this.filter_promo_priority_from,
                    "priority_to": this.filter_promo_priority_to,
                    "promo_code": this.filter_promo_code,
                    "promo_name": this.filter_promo_name,
                    "apply_discount": this.filter_apply_discount,
                    "apply_action": this.filter_apply_action,
                    "stacking": filter_stacking,
                    "is_active": filter_is_active,
                    "smac": filter_smac,
                    "filter_all": filter_all,
                }

                await axios.get(`/api/v1/export-promos/`, {responseType: 'blob', params: data}).then(res => {
                    console.log('success')
                    // console.log(res.data);
                    // this.data = res.data;
                    // var fileURL = window.URL.createObjectURL(new Blob([res.data]));
                    // var fileLink = document.createElement('a');
                    // fileLink.href = fileURL;
                    // fileLink.setAttribute('download', 'export_promos_' + dateNow + '.xls');
                    // document.body.appendChild(fileLink);
                    // fileLink.click()
                }).catch(error => {
                    console.log(error)
                })
            },
            async exportVoucher() {
                console.log('Vouchers')
                let filter_is_active = ''
                if (this.filter_voucher_is_active === true) {
                    filter_is_active = "True"
                } else {
                    filter_is_active = "False"
                }

                let filter_all = ''
                if (this.filter_voucher_all === true) {
                    filter_all = "True"
                } else {
                    filter_all = "False"
                }


                let dateNow = moment(new Date()).format('YYYYMMDD')
                const token = localStorage.getItem('token')
                axios.defaults.headers.common['Authorization'] = "Token " + token

                let data = {
                    "no_usage_from": this.filter_voucher_usage_from,
                    "no_usage_to": this.filter_voucher_usage_to,
                    "no_usage_customer_from": this.filter_voucher_usage_customer_from,
                    "no_usage_customer_to": this.filter_voucher_usage_customer_to,
                    "voucher_code": this.filter_voucher_code,
                    "promo_code": this.filter_voucher_promo_code,
                    "is_active": filter_is_active,
                    "orderby": this.voucher_order_by,
                    "sort": this.voucher_currentSortDir,
                    "filter_all": filter_all,
                }

                await axios.get("/api/v1/export-vouchers/", {responseType: 'blob', params: data}).then(res => {
                    console.log('success')
                    // console.log(res.data);
                    // this.data = res.data;
                    // var fileURL = window.URL.createObjectURL(new Blob([res.data]));
                    // var fileLink = document.createElement('a');
                    // fileLink.href = fileURL;
                    // fileLink.setAttribute('download', 'export_vouchers_' + dateNow + '.xls');
                    // document.body.appendChild(fileLink);
                    // fileLink.click()
                }).catch(error => {
                    console.log(error)
                })
            },
            async exportLogs(){
                console.log('Logs')
                let dateNow = moment(new Date()).format('YYYYMMDD')
                const token = localStorage.getItem('token')
                axios.defaults.headers.common['Authorization'] = "Token " + token
                let date_from = moment(this.filter_log_date_from).format( 'YYYY-MM-DD HH:mm')
                let date_to = moment(this.filter_log_date_to).format( 'YYYY-MM-DD HH:mm')
                let data = {
                    "date_from": date_from,
                    "date_to": date_to,
                    "pos_id": this.filter_log_pos_id,
                    // "promo_code": this.filter_log_promo_code,
                    // "voucher_code": this.filter_log_voucher_code,
                }

                await axios.get("/api/v1/export-logs/", {responseType: 'blob', params: data}).then(res => {
                    console.log('success')
                    // console.log(res.data);
                    // this.data = res.data;
                    // var fileURL = window.URL.createObjectURL(new Blob([res.data]));
                    // var fileLink = document.createElement('a');
                    // fileLink.href = fileURL;
                    // fileLink.setAttribute('download', 'export_logs_' + dateNow + '.xls');
                    // document.body.appendChild(fileLink);
                    // fileLink.click()
                }).catch(error => {
                    console.log(error)
                })
            },
            async exportStoreGroups(){
                console.log('Store Groups')
                // let dateNow = moment(new Date()).format('YYYYMMDD')

                await axios.get("/api/v1/export-store-groups/", {responseType: 'blob'}).then(res => {
                    console.log('success')
                    // console.log(res.data);
                    // this.data = res.data;
                    // var fileURL = window.URL.createObjectURL(new Blob([res.data]));
                    // var fileLink = document.createElement('a');
                    // fileLink.href = fileURL;
                    // fileLink.setAttribute('download', 'export_store_groups_' + dateNow + '.xls');
                    // document.body.appendChild(fileLink);
                    // fileLink.click()
                }).catch(error => {
                    console.log(error)
                })
            },
            downloadTemplate(){
                let url = ''
                let filename = ''
                if (this.import_value === 'promo') {
                    url = "/api/v1/download-template-promo/"
                    filename = "sample_template_promo.csv"
                    // this.downloadTemplatePromo()
                } else if (this.import_value === 'voucher') {
                    url = "/api/v1/download-template-voucher/"
                    filename = "sample_template_voucher.csv"
                    // this.downloadTemplateVoucher()
                } else if (this.import_value === 'store_group') {
                    url = "/api/v1/download-template-store-group/"
                    filename = "sample_template_store_group.csv"
                    // this.downloadTemplateStore()
                }
                this.downloadFile(url, filename)
            },
            downloadTemplatePromo() {
                const token = localStorage.getItem('token')
                axios.defaults.headers.common['Authorization'] = "Token " + token
                axios.get("/api/v1/download-template-promo/", {responseType: 'blob'}).then(res => {
                    // console.log(res.data);
                    // this.template = res.data;
                    var fileURL = window.URL.createObjectURL(new Blob([res.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'sample_template_promo.csv');
                    document.body.appendChild(fileLink);
                    fileLink.click()
                }).catch(error => {
                    console.log(error)
                })
            },
            downloadErrorLog() {
                console.log(this.recent_import_value)
                let url = ''
                let filename = ''
                if (this.recent_import_value === "promo") {
                    url = "/api/v1/download-voucher-log/"
                    filename = "import_promo_log.txt"
                } else if (this.recent_import_value === "voucher") {
                    url = "/api/v1/download-voucher-log/"
                    filename = "import_voucher_log.txt"
                } else if (this.recent_import_value === "store_group") {
                    url = "/api/v1/download-store-group-log/"
                    filename = "import_store-group_log.txt"
                }
                this.downloadFile(url, filename)
            },
            downloadFile(url,filename) {
              axios.get(url, {responseType: 'blob'}).then(res => {
                    // console.log(res.data);
                    // this.template = res.data;
                    var fileURL = window.URL.createObjectURL(new Blob([res.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', filename);
                    document.body.appendChild(fileLink);
                    fileLink.click()
                }).catch(error => {
                    console.log(error)
                })
            },
            downloadTemplateStore() {
                const token = localStorage.getItem('token')
                axios.defaults.headers.common['Authorization'] = "Token " + token
                axios.get("/api/v1/download-template-store/", {responseType: 'blob'}).then(res => {
                    // console.log(res.data);
                    // this.template = res.data;
                    var fileURL = window.URL.createObjectURL(new Blob([res.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'sample_template_store.csv');
                    document.body.appendChild(fileLink);
                    fileLink.click()
                }).catch(error => {
                    console.log(error)
                })
            },
            getExportedFiles :function() {
                const token = localStorage.getItem('token')
                axios.defaults.headers.common['Authorization'] = "Token " + token
                axios.get(`/api/v1/exported-files/?page=${this.currentPage}`).then(res => {
                    // console.log(res.data.results)
                    this.exported_files = res.data.results;

                    if (res.data.next) {
                        this.showNextButton = true
                    } else {
                        this.showNextButton = false
                    }
                    if (res.data.previous) {
                        this.showPreviousButton = true
                    } else {
                        this.showPreviousButton = false
                    }

                }).catch(error => {
                    console.log(error)
                })
            },
            downloadLink(file_name) {
                let url = `/api/v1/download-excel/${file_name}/`
                this.downloadFile(url, file_name)
            },
            nextPage:function() {
                this.currentPage += 1
                this.getExportedFiles()
            },
            prevPage:function() {
                this.currentPage -= 1
                this.getExportedFiles()
            },
        }
    }
</script>

<style scoped>
    .custom-width-import-export {
        width: 70%
    }

    .pagination {
      display: inline-block;
    }

    .pagination button {
      color: black;
      float: left;
      padding: 8px 16px;
      text-decoration: none;
      transition: background-color .3s;
      border: 1px solid #ddd;
    }

    .pagination button.active {
      background-color: #4CAF50;
      color: white;
      border: 1px solid #4CAF50;
    }

    .pagination button:hover:not(.active) {background-color: #ddd;}
</style>