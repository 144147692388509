<template>
<!--<SideBar/>-->
    <div class="py-10">
        <header>
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 class="text-3xl font-bold leading-tight text-gray-900">Promo Rule</h1>
            </div>
        </header>
        <main>
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <!-- Replace with your content -->
                <div class="px-4 py-8 sm:px-0">
                    <!--            <div class="border-4 border-dashed border-gray-200 rounded-lg h-96" />-->
                    <!-- table start -->

                    <div class="px-4 sm:px-6 lg:px-8">

                        <div class="sm:flex sm:items-center">
                            <div class="mt-4 sm:mt-0 text-left">
                                <button type="button"
                                        class="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                        @click="open_filter = true;"
                                >
                                    Filter
                                </button>
                            </div>
                            <div class="sm:flex-auto">

                                <!--                                <h1 class="text-xl font-semibold text-gray-900">Promo</h1>-->
                            </div>

                            <label for="simple-search" class="sr-only">Search</label>
                            <div class="relative w-full" style="padding-left: 10px;">
                                <input type="text" id="simple-search" v-model="search_query" @change="search" class="custom-search border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" required>
                            </div>

                            <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                <button type="button"
                                        class="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                        @click="open = true; createPromo = true; updatePromo=  false; clearAll(); defaultCondition()"
                                >
                                    Add
                                </button>
                            </div>
                        </div>

                        <div class="mt-8 flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-300">
                                            <thead class="bg-gray-50">
                                            <tr>
                                                <th scope="col"
                                                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Action
                                                </th>
                                                <th scope="col"
                                                    @click="sortHeader('priority')"
                                                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Priority
                                                </th>
                                                <th scope="col"
                                                    @click="sortHeader('promo_code')"
                                                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Promo Code
                                                </th>
                                                <th scope="col"
                                                    @click="sortHeader('promo_name')"
                                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Promo Name
                                                </th>
                                                <th scope="col"
                                                    @click="sortHeader('prefix')"
                                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Prefix
                                                </th>
                                                <th scope="col"
                                                    @click="sortHeader('start_date')"
                                                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Start Date
                                                </th>
                                                <th scope="col"
                                                    @click="sortHeader('end_date')"
                                                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    End Date
                                                </th>
                                                <th scope="col"
                                                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Apply Discount
                                                </th>
                                                <th scope="col"
                                                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Apply Action
                                                </th>
                                                <th scope="col"
                                                    @click="sortHeader('discount_amount')"
                                                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Discount Amount
                                                </th>
                                                <th scope="col"
                                                    @click="sortHeader('dmall')"
                                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Dmall
                                                </th>
                                                <th scope="col"
                                                    @click="sortHeader('stacking')"
                                                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Stacking
                                                </th>
                                                <th scope="col"
                                                    @click="sortHeader('smac_required')"
                                                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    SMAC Required
                                                </th>
                                                <th scope="col"
                                                    @click="sortHeader('enable_multiple_voucher')"
                                                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Multiple Promo
                                                </th>
                                                <th scope="col"
                                                    @click="sortHeader('number_of_usage_customer')"
                                                    class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                                                    Max no. of Usage Customer
                                                </th>
                                                <th scope="col"
                                                    @click="sortHeader('number_of_usage')"
                                                    class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                                                    Max no. of Usage
                                                </th>
                                                <th scope="col"
                                                    class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                                                    Total no. Promo Claim
                                                </th>
                                                <th scope="col"
                                                    class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                                                    Total no. Promo Void
                                                </th>
                                                <th scope="col"
                                                    class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                                                    Total no. of Usage
                                                </th>
                                                <th scope="col"
                                                    @click="sortHeader('is_active')"
                                                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Status
                                                </th>
<!--                                                <th scope="col"-->
<!--                                                    @click="sortHeader('sync')"-->
<!--                                                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">-->
<!--                                                    Sync-->
<!--                                                </th>-->

                                            </tr>
                                            </thead>
                                            <tbody class="bg-white text-left">

                                            <tr v-for="(promo, promoIdx) in promos" :key="promo.promo_code"
                                                :class="promoIdx % 2 === 0 ? undefined : 'bg-gray-50'">
                                                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <a href="#" class="text-indigo-600 hover:text-indigo-900"
                                                       @click="open = true; createPromo = false; updatePromo = true; clearAll(); selectedPromo(promo)"
                                                    >Edit<span class="sr-only">{{ promo.promo_code }}</span></a
                                                    >
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {{ promo.priority }}
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {{ promo.promo_code }}
                                                </td>
                                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    {{ promo.promo_name }}
                                                </td>
                                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    {{ promo.prefix }}
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {{ promo.start_date }}
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {{ promo.end_date }}
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {{ promo.get_apply_discount_display }}
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {{ promo.get_apply_action_display }}
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
                                                    {{ promo.discount_amount }}
                                                </td>
                                                <td v-if="promo.dmall === true" class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 custom-align-center">
                                                    <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                      <path fill="#70bf2b" d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
                                                    </svg>
                                                </td>
                                                <td v-else class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 custom-align-center">
                                                    <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                      <path fill="#dd4646" d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
                                                    </svg>
                                                </td>
                                                <td v-if="promo.stacking === true" class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 custom-align-center">
                                                    <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                      <path fill="#70bf2b" d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
                                                    </svg>
                                                </td>
                                                <td v-else class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 custom-align-center">
                                                    <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                      <path fill="#dd4646" d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
                                                    </svg>
                                                </td>
                                                <td v-if="promo.smac_required === true" class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 custom-align-center">
                                                    <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                      <path fill="#70bf2b" d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
                                                    </svg>
                                                </td>
                                                <td v-else class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 custom-align-center">
                                                    <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                      <path fill="#dd4646" d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
                                                    </svg>
                                                </td>
                                                <td v-if="promo.enable_multiple_voucher === true" class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 custom-align-center">
                                                    <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                      <path fill="#70bf2b" d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
                                                    </svg>
                                                </td>
                                                <td v-else class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 custom-align-center">
                                                    <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                      <path fill="#dd4646" d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
                                                    </svg>
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
                                                    {{ promo.number_of_usage_customer }}
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
                                                    {{ promo.number_of_usage }}
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
                                                    {{ promo.total_promo_claim }}
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
                                                    {{ promo.total_promo_void }}
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
                                                    {{ promo.total_promo_usage }}
                                                </td>
                                                <td v-if="promo.is_active === true" class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 custom-align-center">
                                                    <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                      <path fill="#70bf2b" d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
                                                    </svg>
                                                </td>
                                                <td v-else class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 custom-align-center">
                                                    <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                      <path fill="#dd4646" d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
                                                    </svg>
                                                </td>
<!--                                                <td v-if="promo.sync === true" class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 custom-align-center">-->
<!--                                                    <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">-->
<!--                                                      <path fill="#70bf2b" d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>-->
<!--                                                    </svg>-->
<!--                                                </td>-->
<!--                                                <td v-else class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 custom-align-center">-->
<!--                                                    <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">-->
<!--                                                      <path fill="#dd4646" d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>-->
<!--                                                    </svg>-->
<!--                                                </td>-->
                                            </tr>
                                            </tbody>
                                        </table>
<!--                                        debug: sort={{currentSort}}, dir={{currentSortDir}}, page={{currentPage}}-->
                                    </div>

                                </div>

                            </div>
                            <div>
                                <div class="mt-10 pagination m-1">
                                    <button @click="prevPage" v-if="showPreviousButton">❮</button>
                                    <button @click="nextPage" v-if="showNextButton">❯</button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <!-- table end -->
                    <!-- start filter -->
                    <TransitionRoot as="template" :show="open_filter">
                        <Dialog as="div" class="relative z-10" @close="open_filter = false">
                            <div class="fixed inset-0"/>
                            <div class="fixed inset-0 overflow-hidden">
                                <div class="absolute inset-0 overflow-hidden">
                                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16 ">
                                        <TransitionChild as="template"
                                                         enter="transform transition ease-in-out duration-500 sm:duration-700"
                                                         enter-from="translate-x-full" enter-to="translate-x-0"
                                                         leave="transform transition ease-in-out duration-500 sm:duration-700"
                                                         leave-from="translate-x-0" leave-to="translate-x-full">
                                            <DialogPanel class="pointer-events-auto w-screen max-w-8xl">
                                                <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                                                      @submit.prevent="submitApplyFilter"

                                                >
                                                    <div class="h-0 flex-1 overflow-y-auto">
                                                        <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                                                            <div class="flex items-center justify-between">
                                                                <DialogTitle class="text-lg font-medium text-white">
                                                                    Filter
                                                                </DialogTitle>
                                                                <div class="ml-3 flex h-7 items-center">
                                                                    <button type="button"
                                                                            class="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                                            @click="open_filter = false">
                                                                        <span class="sr-only">Close panel</span>
                                                                        <XIcon class="h-6 w-6" aria-hidden="true"/>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div class="flex">
                                                            <div>
                                                                <div class="space-y-4 pt-6 pb-5 ml-5">
                                                                    <label class="block text-lg font-weight-bold font-medium text-gray-900">
                                                                        Start Date </label>
                                                                    <label class="block text-sm font-medium text-gray-900">
                                                                        Start Date From </label>
                                                                    <div class="mt-1">
                                                                        <input v-model="filter_start_date_from" type="datetime-local" name="filter-start-date-from"
                                                                               id="filter-start-date-from"
                                                                               format="MM/DD/YYYY hh:mm"
                                                                               class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"

                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div class="space-y-4 pb-5 ml-5">
                                                                    <label class="block text-sm font-medium text-gray-900">
                                                                        Start Date To </label>
                                                                    <div class="mt-1">
                                                                        <input v-model="filter_start_date_to" type="datetime-local" name="filter-start-date-to"
                                                                               id="filter-start-date-to"
                                                                               format="MM/DD/YYYY hh:mm"
                                                                               class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"

                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <div class="space-y-4 pt-6 pb-5 ml-5">
                                                                    <label class="block text-lg font-weight-bold font-medium text-gray-900">
                                                                        End Date </label>
                                                                    <label class="block text-sm font-medium text-gray-900">
                                                                        End Date From </label>
                                                                    <div class="mt-1">
                                                                        <input v-model="filter_end_date_from" type="datetime-local" name="filter-end-date-from"
                                                                               id="filter-end-date-from"
                                                                               format="MM/DD/YYYY hh:mm"
                                                                               class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"

                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div class="space-y-4 pb-5 ml-5">
                                                                    <label class="block text-sm font-medium text-gray-900">
                                                                        End Date To </label>
                                                                    <div class="mt-1">
                                                                        <input v-model="filter_end_date_to" type="datetime-local" name="filter-end-date-t0"
                                                                               id="filter-end-date-t0"
                                                                               format="MM/DD/YYYY hh:mm"
                                                                               class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"

                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <div class="space-y-4 pt-6 pb-5 ml-5">
                                                                    <label class="block text-lg font-weight-bold font-medium text-gray-900">
                                                                        Discount Amount </label>
                                                                    <label class="block text-sm font-medium text-gray-900">
                                                                        Discount Amount From </label>
                                                                    <div class="mt-1">
                                                                        <input v-model="filter_discount_amount_from" type="number" name="filter_discount_amount_from"
                                                                               id="filter_discount_amount_from"
                                                                               class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                    </div>
                                                                </div>

                                                                <div class="space-y-4 pb-5 ml-5">
                                                                    <label class="block text-sm font-medium text-gray-900">
                                                                        Discount Amount To </label>
                                                                    <div class="mt-1">
                                                                        <input v-model="filter_discount_amount_to" type="number" name="filter_discount_amount_to"
                                                                               id="filter_discount_amount_to"
                                                                               class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <div class="space-y-4 pt-6 pb-5 ml-5">
                                                                    <label class="block text-lg font-weight-bold font-medium text-gray-900">
                                                                        Max no. of usage </label>
                                                                    <label class="block text-sm font-medium text-gray-900">
                                                                        Max no. of usage from </label>
                                                                    <div class="mt-1">
                                                                        <input v-model="filter_usage_from" type="number" name="filter_max_usage_from"
                                                                               id="filter_max_usage_from"
                                                                               class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                    </div>
                                                                </div>

                                                                <div class="space-y-4 pb-5 ml-5">
                                                                    <label class="block text-sm font-medium text-gray-900">
                                                                        Max no. of usage to </label>
                                                                    <div class="mt-1">
                                                                        <input v-model="filter_usage_to" type="number" name="filter_max_usage_to"
                                                                               id="filter_max_usage_to"
                                                                               class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <div class="space-y-4 pt-6 pb-5 ml-5">
                                                                    <label class="block text-lg font-weight-bold font-medium text-gray-900">
                                                                        Max no. of usage customer </label>
                                                                    <label class="block text-sm font-medium text-gray-900">
                                                                        Max no. of usage customer from </label>
                                                                    <div class="mt-1">
                                                                        <input v-model="filter_usage_customer_from" type="number" name="filter_max_usage_customer_from"
                                                                               id="filter_max_usage_customer_from"
                                                                               class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                    </div>
                                                                </div>

                                                                <div class="space-y-4 pb-5 ml-5">
                                                                    <label class="block text-sm font-medium text-gray-900">
                                                                        Max no. of usage customer to </label>
                                                                    <div class="mt-1">
                                                                        <input v-model="filter_usage_customer_to" type="number" name="filter_max_usage_customer_to"
                                                                               id="filter_max_usage_customer_to"
                                                                               class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <div class="space-y-4 pt-6 pb-5 ml-5">
                                                                    <label class="block text-lg font-weight-bold font-medium text-gray-900">
                                                                        Priority </label>
                                                                    <label class="block text-sm font-medium text-gray-900">
                                                                        Priority from </label>
                                                                    <div class="mt-1">
                                                                        <input v-model="filter_priority_from" type="number" name="filter_priority_from"
                                                                               id="filter_priority_from"
                                                                               class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                    </div>
                                                                </div>

                                                                <div class="space-y-4 pb-5 ml-5">
                                                                    <label class="block text-sm font-medium text-gray-900">
                                                                        Priority to </label>
                                                                    <div class="mt-1">
                                                                        <input v-model="filter_priority_to" type="number" name="filter_priority_to"
                                                                               id="filter_priority_to"
                                                                               class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="flex">
                                                            <div class="space-y-6 pt-6 pb-5 ml-5">
                                                                <label class="block text-sm font-medium text-gray-900">
                                                                    Promo Code </label>
                                                                <div class="mt-1">
                                                                    <input v-model="filter_promo_code" type="text" name="filter_promo_code"
                                                                           id="filter_promo_code"
                                                                           class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                </div>
                                                            </div>

                                                            <div class="space-y-6 pt-6 pb-5 ml-5">
                                                                <label class="block text-sm font-medium text-gray-900">
                                                                    Promo Name </label>
                                                                <div class="mt-1">
                                                                    <input v-model="filter_promo_name"
                                                                           type="text" name="filter_promo_name"
                                                                           id="filter_promo_name"
                                                                           class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="flex">
                                                            <div class="space-y-6 pt-6 pb-5 ml-5">
                                                                <label class="block text-sm font-medium text-gray-900">
                                                                    Apply Discount </label>
                                                                <div class="mt-1">
                                                                    <select class="block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                            v-model="filter_apply_discount"
                                                                    >
                                                                        <!-- eslint-disable-next-line-->
                                                                        <option v-for="data in applyDiscountType"
                                                                                v-bind:value="data.value">
                                                                            {{ data.text }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div class="space-y-6 pt-6 pb-5 ml-5">
                                                                <label class="block text-sm font-medium text-gray-900">
                                                                    Apply Action </label>
                                                                <div class="mt-1">
                                                                    <select class="block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                            v-model="filter_apply_action"
                                                                    >
                                                                        <!-- eslint-disable-next-line-->
                                                                        <option v-for="data in applyAction"
                                                                                v-bind:value="data.value">
                                                                            {{ data.text }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="flex">
                                                            <div class="space-y-6 pt-6 pb-5 ml-5">
                                                                <label class="block text-sm font-medium text-gray-900">
                                                                    Stacking </label>
                                                                <div class="mt-1">
                                                                    <SwitchGroup as="div" class="flex items-center">
                                                                            <Switch v-model="filter_stacking"
                                                                                :class="[filter_stacking ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                                                            <span aria-hidden="true"
                                                                                  :class="[filter_stacking ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                                                        </Switch>
                                                                    </SwitchGroup>
                                                                </div>
                                                            </div>

                                                            <div class="space-y-6 pt-6 pb-5 ml-5">
                                                                <label class="block text-sm font-medium text-gray-900">
                                                                    Status </label>
                                                                <div class="mt-1">
                                                                    <SwitchGroup as="div" class="flex items-center">
                                                                            <Switch v-model="filter_is_active"
                                                                                :class="[filter_is_active ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                                                            <span aria-hidden="true"
                                                                                  :class="[filter_is_active ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                                                        </Switch>
                                                                    </SwitchGroup>
                                                                </div>
                                                            </div>

                                                            <div class="space-y-6 pt-6 pb-5 ml-5">
                                                                <label class="block text-sm font-medium text-gray-900">
                                                                    SMAC Required </label>
                                                                <div class="mt-1">
                                                                    <SwitchGroup as="div" class="flex items-center">
                                                                            <Switch v-model="filter_smac"
                                                                                :class="[filter_smac ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                                                            <span aria-hidden="true"
                                                                                  :class="[filter_smac ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                                                        </Switch>
                                                                    </SwitchGroup>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="flex flex-shrink-0 px-4 py-4">
                                                            <button type="button"
                                                                    class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                    @click="open_filter = false; filter_status = 'False'; resetFilter()">Reset Filter
                                                            </button>
                                                            <button type="submit"
                                                                    class="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                    @click="open_filter = false; filter_status = 'True'"
                                                            >
                                                                Apply Filter
                                                            </button>
                                                        </div>

                                                    </div>
                                                </form>
                                            </DialogPanel>
                                        </TransitionChild>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </TransitionRoot>

                    <!-- end filter -->
                    <!-- start dialog information -->
                    <TransitionRoot as="template" :show="open">
                        <Dialog as="div" class="relative z-10" @close="open = false">
                            <div class="fixed inset-0"/>

                            <div class="fixed inset-0 overflow-hidden">
                                <div class="absolute inset-0 overflow-hidden">
                                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16 ">
                                        <TransitionChild as="template"
                                                         enter="transform transition ease-in-out duration-500 sm:duration-700"
                                                         enter-from="translate-x-full" enter-to="translate-x-0"
                                                         leave="transform transition ease-in-out duration-500 sm:duration-700"
                                                         leave-from="translate-x-0" leave-to="translate-x-full">
                                            <DialogPanel class="pointer-events-auto w-screen max-w-8xl">
                                                <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                                                      @submit.prevent="submitForm"
                                                >
                                                    <div class="h-0 flex-1 overflow-y-auto">
                                                        <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                                                            <div class="flex items-center justify-between">
                                                                <DialogTitle class="text-lg font-medium text-white"
                                                                             v-if="this.createPromo === true">
                                                                    Add Cart Price Rule
                                                                </DialogTitle>
                                                                <DialogTitle class="text-lg font-medium text-white"
                                                                             v-if="this.updatePromo === true">
                                                                    Edit Cart Price Rule
                                                                </DialogTitle>
                                                                <div class="ml-3 flex h-7 items-center">
                                                                    <button type="button"
                                                                            class="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                                            @click="open = false">
                                                                        <span class="sr-only">Close panel</span>
                                                                        <XIcon class="h-6 w-6" aria-hidden="true"/>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <!--                                          <div class="mt-1">-->
                                                            <!--                                            <p class="text-sm text-indigo-300">Get started by filling in the information below to create your new project.</p>-->
                                                            <!--                                          </div>-->
                                                        </div>
                                                        <div class="flex flex-1 flex-col justify-between">
                                                            <div class="divide-y divide-gray-200 px-4 sm:px-6">

                                                                <div v-if="errorMessage" class="error-message mt-4" :style="{ backgroundColor: '#ffcccc', padding: '10px', border: '1px solid #ff6666' }">
                                                                    {{ errorMessage }}
                                                                </div>

                                                                <div class="space-y-6 pt-6 pb-5">

                                                                    <div  v-if="showAlert" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-left">
                                                                        {{ message }}
                                                                    </div>

                                                                    <div>
                                                                        <label class="block text-sm font-medium text-gray-900">
                                                                            Priority </label>
                                                                        <div class="mt-1">
                                                                            <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <input type="number" name="priority"
                                                                                   id="priority"
                                                                                   v-model="priority"
                                                                                   onkeypress="return !(event.charCode == 46)"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label for="promo-name"
                                                                               class="block text-sm font-medium text-gray-900">
                                                                            Promo Name </label>
                                                                        <div class="mt-1">
                                                                            <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <input type="text" name="promo-name"
                                                                                   id="promo-name"
                                                                                   v-model="promoName"
                                                                                   @input="limitCharacters"
                                                                                   class="custom-width-30 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                                   required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label for="promo-code"
                                                                               class="block text-sm font-medium text-gray-900">
                                                                            Promo Code </label>
                                                                        <div class="mt-1">
                                                                            <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <input type="text" name="promo-code"
                                                                                   id="promo-code"
                                                                                   v-model="promoCode"
                                                                                   @input="limitCharacters"
                                                                                   class="custom-width-30 block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                                   required
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <label for="prefix"
                                                                               class="block text-sm font-medium text-gray-900">
                                                                            Prefix </label>
                                                                        <div class="mt-1">
                                                                            <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <input type="text" name="prefix"
                                                                                   id="prefix"
                                                                                   v-model="prefix"
                                                                                   class="width-30 block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <label class="block
                                                                                      text-sm
                                                                                      font-medium
                                                                                      text-gray-900"
                                                                        >
                                                                            Stores </label>
                                                                        <div class="mt-1">
                                                                            <select class="resizable-div"
                                                                                    ref="resizableSelectStore"
                                                                                    @mousedown="startResizeStore"
                                                                                    v-model="selectedStores"
                                                                                    multiple

                                                                            >
                                                                                <!-- eslint-disable-next-line-->
                                                                                <option v-for="store in stores"
                                                                                        v-bind:value="store.store_id">
                                                                                    {{ store.store_id }}
                                                                                </option>
                                                                            </select>
<!--                                                                            <br>-->
<!--                                                                            <span>Selected: {{ selectedStores }}</span>-->
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <label class="block
                                                                                      text-sm
                                                                                      font-medium
                                                                                      text-gray-900"
                                                                        >
                                                                            Store Groups
                                                                        </label>
                                                                        <div class="mt-1">
                                                                            <select class="resizable-div"
                                                                                    ref="resizableSelect"
                                                                                    @mousedown="startResize"
                                                                                    v-model="selectedStoreGroups"
                                                                                    multiple
                                                                            >
                                                                                <!-- eslint-disable-next-line-->
                                                                                <option v-for="storeGroup in storeGroups"
                                                                                        v-bind:value="storeGroup.store_group_name">
                                                                                    {{ storeGroup.store_group_name }}
                                                                                </option>
                                                                            </select>
<!--                                                                            <br>-->
<!--                                                                            <span>Selected: {{ selectedStores }}</span>-->
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <label for="start-date"
                                                                               class="block text-sm font-medium text-gray-900">
                                                                            Start Date </label>
                                                                        <div class="mt-1">
                                                                            <input type="datetime-local" name="start-date"
                                                                                   id="start-date"
                                                                                   v-model="startDate"
                                                                                   format="MM/DD/YYYY HH:mm"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                                   required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label for="end-date"
                                                                               class="block text-sm font-medium text-gray-900">
                                                                            End Date </label>
                                                                        <div class="mt-1">
                                                                            <input type="datetime-local" name="end-date"
                                                                                   id="end-date"
                                                                                   v-model="endDate"
                                                                                   format="MM/DD/YYYY hh:mm"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label for="number-usage"
                                                                               class="block text-sm font-medium text-gray-900">
                                                                            Number of Usage </label>
                                                                        <div class="mt-1">
                                                                            <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <input type="number" name="number-usage"
                                                                                   id="number-usage"
                                                                                   v-model="numberOfUsage"
                                                                                   onkeypress="return !(event.charCode == 46)"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label for="customer-number-usage"
                                                                               class="block text-sm font-medium text-gray-900">
                                                                            Number of Usage Customer </label>
                                                                        <div class="mt-1">
                                                                            <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <input type="number"
                                                                                   name="customer-number-usage"
                                                                                   id="customer-number-usage"
                                                                                   v-model="numberOfUsageCustomer"
                                                                                   onkeypress="return !(event.charCode == 46)"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                        </div>
                                                                    </div>

                                                                    <!--start store max usage-->

                                                                    <div id="shop_max_usage" style="margin-top: 10px; !important;" v-for="(input,k) in store_max_usage" :key="k">

                                                                        <div class="row " style="display: flex!important;">
                                                                            <div class="mt-0 mb-0">
                                                                                <label v-show="k === 0" class="block text-sm font-medium text-gray-900">
                                                                                    Store </label>
                                                                                <div class="mt-1 mr-3">
                                                                                    <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                                    <select style="height: 110%;"
                                                                                            class="mb-3 block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                                            v-model="input.store_id" :disabled="disable_select_store(input.store_id)" @click="selectedStoreMaxUsage(store_max_usage, input.store_id)"
                                                                                    >
                                                                                        <!-- eslint-disable-next-line-->
                                                                                        <option v-for="data in stores"
                                                                                                v-bind:value="data.store_id" :disabled="data.status === false">
                                                                                            {{ data.store }}
                                                                                        </option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>

                                                                            <div class="mt-0 mb-0">
                                                                                 <label v-show="k === 0" class="block text-sm font-medium text-gray-900">
                                                                                        Max Usage </label>

                                                                                <div style="display:flex; !important;">

                                                                                    <div class="mt-1 mr-3">
                                                                                        <input type="number"
                                                                                               v-model="input.max_usage"
                                                                                               style="height: 80%;"
                                                                                               onkeypress="return !(event.charCode == 46)"
                                                                                               class="mb-3 block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                                    </div>

                                                                                    <i class="fas fa-minus-circle" @click="remove_store_max_usage(k, input.store_id)" v-show="k || ( !k && store_max_usage.length > 1)"></i>
                                                                                    <i class="fas fa-plus-circle" @click="add_store_max_usage(input.store_id)" v-show="k === store_max_usage.length-1"></i>

                                                                                </div>

                                                                            </div>

                                                                         </div>

                                                                    </div>
                                                                    <!--end store max usage-->

                                                                    <!--start store group max usage-->
                                                                    <store-group-max-usage
                                                                            v-model:valueStoreGroupMaxUsage="store_group_max_usage"
                                                                            v-model:valueStoreGroups="storeGroups"
                                                                    />
                                                                    <!--end store max usage-->

                                                                    <div>
                                                                        <label class="block text-sm font-medium text-gray-900">
                                                                            Apply Discount </label>
                                                                        <div class="mt-1">
                                                                            <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <!--                                                    <input type="text" name="aggregator-value" id="aggregator-value" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <div class="mt-1">
                                                                                <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                                <!--                                                    <input type="text" name="aggregator" id="aggregator" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                                <select class="block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" required
                                                                                        v-model="applyDiscount">
                                                                                    <!-- eslint-disable-next-line-->
                                                                                    <option v-for="data in applyDiscountType"
                                                                                            v-bind:value="data.value">
                                                                                        {{ data.text }}
                                                                                    </option>
                                                                                </select>
<!--                                                                                <br>-->
<!--                                                                                <span>Selected: {{ applyDiscount }}</span>-->
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label for="trans-disc-sku-id"
                                                                               class="block text-sm font-medium text-gray-900">
                                                                            Transaction Discount SKU ID </label>
                                                                        <div class="mt-1">
                                                                            <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <input type="text"
                                                                                   name="trans-disc-sku-id"
                                                                                   id="trans-disc-sku-id"
                                                                                   v-model="transDiscSkuId"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label for="trans-disc-barcode"
                                                                               class="block text-sm font-medium text-gray-900">
                                                                            Transaction Discount Barcode </label>
                                                                        <div class="mt-1">
                                                                            <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <input type="text"
                                                                                   name="trans-disc-barcode"
                                                                                   id="trans-disc-barcode"
                                                                                   v-model="transDiscSkuBarcode"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label class="block text-sm font-medium text-gray-900">
                                                                            Promo Message </label>
                                                                        <div class="mt-1">
                                                                            <textarea
                                                                                    v-model="promoMessage"
                                                                                    rows="20"
                                                                                    v-on:keyup="limitTextPerRow()"
                                                                                    v-on:keydown="limitTextPerRow()"
                                                                                    class="block w-100 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" style="width: 600px !important; height: 100px;">
                                                                            </textarea>
                                                                        </div>
                                                                    </div>
                                                                    <SwitchGroup as="div" class="flex items-center">
                                                                        <Switch v-model="is_active"
                                                                                :class="[is_active ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                                                            <span aria-hidden="true"
                                                                                  :class="[is_active ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                                                        </Switch>
                                                                        <SwitchLabel as="span" class="ml-3">
                                                                            <span class="text-sm font-medium text-gray-900">Status </span>
                                                                        </SwitchLabel>
                                                                    </SwitchGroup>

                                                                    <SwitchGroup as="div" class="flex items-center">
                                                                        <Switch v-model="smac_required"
                                                                                :class="[smac_required ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                                                            <span aria-hidden="true"
                                                                                  :class="[smac_required ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                                                        </Switch>
                                                                        <SwitchLabel as="span" class="ml-3">
                                                                            <span class="text-sm font-medium text-gray-900">Required SMAC </span>
                                                                        </SwitchLabel>
                                                                    </SwitchGroup>

                                                                    <SwitchGroup as="div" class="flex items-center">
                                                                        <Switch v-model="multiple_voucher"
                                                                                :class="[multiple_voucher ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                                                            <span aria-hidden="true"
                                                                                  :class="[multiple_voucher ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                                                        </Switch>
                                                                        <SwitchLabel as="span" class="ml-3">
                                                                            <span class="text-sm font-medium text-gray-900">Enable Multiple Promo </span>
                                                                        </SwitchLabel>
                                                                    </SwitchGroup>

                                                                    <SwitchGroup as="div" class="flex items-center">
                                                                        <Switch v-model="dmall"
                                                                                :class="[dmall ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                                                            <span aria-hidden="true"
                                                                                  :class="[dmall ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                                                        </Switch>
                                                                        <SwitchLabel as="span" class="ml-3">
                                                                            <span class="text-sm font-medium text-gray-900">Dmall </span>
                                                                        </SwitchLabel>
                                                                    </SwitchGroup>


                                                                </div>
                                                                <!-- start promo conditions -->
                                                                <DialogTitle class="text-lg font-medium text-white"> Conditions </DialogTitle>
                                                                <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                                                                  <div class="flex items-center justify-between">
                                                                    <DialogTitle class="text-lg font-medium text-white"> Conditions </DialogTitle>
                                                                  </div>
                                                                </div>
                                                                <br>


                                                                <div class="row" style="border: none;">
                                                                    <div style="display:inline; !important;" v-for="(input,k) in ruleConditionCombine" :key="k">

                                                                        <span style="border: none;">
                                                                            If
                                                                            <select v-show="false" class="w-50 border-0 focus:ring-indigo-500 sm:text-sm"
                                                                                    v-model="input.type">
                                                                                <!-- eslint-disable-next-line-->
                                                                                <option v-for="type in typeConditionCombine"
                                                                                        v-bind:value="type.value">
                                                                                    {{ type.text }}
                                                                                </option>
                                                                            </select>

                                                                            <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                    v-model="input.aggregator" required>
                                                                                <!-- eslint-disable-next-line-->
                                                                                <option v-for="aggregator in aggregators"
                                                                                        v-bind:value="aggregator.value">
                                                                                    {{ aggregator.text }}
                                                                                </option>
                                                                            </select>
                                                                            of these conditions are
                                                                            <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                    v-model="input.value" required>
                                                                                <!-- eslint-disable-next-line-->
                                                                                <option v-for="value in condition"
                                                                                        v-bind:value="value.value">
                                                                                    {{ value.text }}

                                                                                </option>
                                                                            </select>:
                                                                        </span>

                                                                        <br>

                                                                    </div>

                                                                     <div style="!important; margin-left: 2%" v-for="(input,k) in ruleCondition" :key="k">
                                                                         <select  v-show="input.type === ''" class="mb-3 block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                            v-model="input.type">
                                                                                <!-- eslint-disable-next-line-->
                                                                                <option v-for="type in cartConditions"
                                                                                        v-bind:value="type.value" :disabled="type.value === 'cart_attribute'"
                                                                                        >
                                                                                    {{ type.text }}
                                                                                </option>

                                                                        </select>

                                                                        <span v-if="input.type === 'products_attribute_combination'">
                                                                            If an item is
                                                                            <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                    v-model="input.value" required>
                                                                                <!-- eslint-disable-next-line-->
                                                                                <option  v-for="value in rule_param_1"
                                                                                        v-bind:value="value.value" >
                                                                                    {{ value.text }}
                                                                                </option>
                                                                            </select>
                                                                            in the cart with
                                                                            <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                    v-model="input.aggregator" required>
                                                                                <!-- eslint-disable-next-line-->
                                                                                <option v-for="aggregator in rule_param_2"
                                                                                        v-bind:value="aggregator.value">
                                                                                    {{ aggregator.text }}
                                                                                </option>
                                                                            </select>
                                                                            of these conditions true:

                                                                            <span><i class="fas fa-minus-circle" @click="remove(k, ruleCondition.length)"
                                                                            ></i></span>

                                                                            <div class="col-2 row" style="display: grid;margin-left: 2%;">
                                                                                <div v-for="(data,i) in this.ruleCondition[k].conditions" :key="i">
                                                                                    <select v-show="input.conditions[i].attribute === ''" style="display: block" class="w-50 border-0 focus:ring-indigo-500 sm:text-sm" :class="input.value ? '' : 'custom-please-select'"
                                                                                        v-model="input.conditions[i].attribute" required>
                                                                                        <!-- eslint-disable-next-line-->
                                                                                        <option v-for="attribute in cart_item_attribute"
                                                                                                v-bind:value="attribute.attribute" >
                                                                                            {{ attribute.text }}
                                                                                        </option>
                                                                                    </select>

                                                                                    <span v-if="input.conditions[i].attribute === 'quote_item_price'">
                                                                                        Price in cart
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                            <!-- eslint-disable-next-line-->
                                                                                            <option v-for="operator in priceCartOperator"
                                                                                                    v-bind:value="operator.value">
                                                                                                {{ operator.text }}
                                                                                            </option>
                                                                                        </select>
                                                                                        <input type="number" v-model="input.conditions[i].value"
                                                                                               step=".01"
                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>
                                                                                    </span>

                                                                                    <span v-if="input.conditions[i].attribute === 'quote_item_qty'">
                                                                                        Quantity in cart
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                            <!-- eslint-disable-next-line-->
                                                                                            <option v-for="operator in itemQtyCartOperator"
                                                                                                    v-bind:value="operator.value">
                                                                                                {{ operator.text }}
                                                                                            </option>
                                                                                        </select>
                                                                                        <input type="number" v-model="input.conditions[i].value"
                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>
                                                                                    </span>

                                                                                    <span v-if="input.conditions[i].attribute === 'quote_item_row'">
                                                                                        Row total in cart
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                            <!-- eslint-disable-next-line-->
                                                                                            <option v-for="operator in rowTotalCartOperator"
                                                                                                    v-bind:value="operator.value">
                                                                                                {{ operator.text }}
                                                                                            </option>
                                                                                        </select>
                                                                                        <input type="number" v-model="input.conditions[i].value"
                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>
                                                                                    </span>

                                                                                    <span v-if="input.conditions[i].attribute === 'quote_item_smac'">
                                                                                        SMAC
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                            <!-- eslint-disable-next-line-->
                                                                                            <option v-for="operator in smacOperator"
                                                                                                    v-bind:value="operator.value">
                                                                                                {{ operator.text }}
                                                                                            </option>
                                                                                        </select>

                                                                                        <context-menu
                                                                                             v-model:value="input.conditions[i].value"
                                                                                             v-model:value2="input.conditions[i].value2"
                                                                                             :value-operator="input.conditions[i].operator"
                                                                                             @itemSelected="handleMenuItemSelected"
                                                                                         />
<!--                                                                                        <input type="text" v-model="input.conditions[i].value"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>-->

<!--                                                                                        <input type="text" v-model="input.conditions[i].value"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" :placeholder="[input.conditions[i].operator === '[]' ? 'From' : '...']" required/>-->

<!--                                                                                        <input type="text" v-model="input.conditions[i].value2" v-if="input.conditions[i].operator === '[]'"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input ml-5" style="border: 0" placeholder="To" required/>-->

                                                                                    </span>

                                                                                    <span v-if="input.conditions[i].attribute === 'quote_item_bin_number'">
                                                                                        Bin Number (First 6 digits)
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                            <!-- eslint-disable-next-line-->
                                                                                            <option v-for="operator in operator_1"
                                                                                                    v-bind:value="operator.value">
                                                                                                {{ operator.text }}
                                                                                            </option>
                                                                                        </select>
<!--                                                                                        <input type="text" v-model="input.conditions[i].value"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>-->

                                                                                        <context-menu
                                                                                             v-model:value="input.conditions[i].value"
                                                                                             v-model:value2="input.conditions[i].value2"
                                                                                             :value-operator="input.conditions[i].operator"
                                                                                             @itemSelected="handleMenuItemSelected"
                                                                                         />

<!--                                                                                        <input type="text"-->
<!--                                                                                               v-model="input.conditions[i].value"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input"-->
<!--                                                                                               style="border: 0"-->
<!--                                                                                               :placeholder="[input.conditions[i].operator === '[]' ? 'From' : '...']"-->
<!--                                                                                               required/>-->

<!--                                                                                        <input type="text"-->
<!--                                                                                               v-if="input.conditions[i].operator === '[]'"-->
<!--                                                                                               v-model="input.conditions[i].value2"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input ml-5"-->
<!--                                                                                               style="border: 0"-->
<!--                                                                                               placeholder="To"-->
<!--                                                                                               required/>-->

                                                                                    </span>

                                                                                    <span v-if="input.conditions[i].attribute === 'quote_item_barcode'">
                                                                                        Barcode
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                            <!-- eslint-disable-next-line-->
                                                                                            <option v-for="operator in operator_1"
                                                                                                    v-bind:value="operator.value">
                                                                                                {{ operator.text }}
                                                                                            </option>
                                                                                        </select>

                                                                                        <context-menu
                                                                                             v-model:value="input.conditions[i].value"
                                                                                             v-model:value2="input.conditions[i].value2"
                                                                                             :value-operator="input.conditions[i].operator"
                                                                                             @itemSelected="handleMenuItemSelected"
                                                                                         />

<!--                                                                                        <input type="text" v-model="input.conditions[i].value"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" :placeholder="[input.conditions[i].operator === '[]' ? 'From' : '...']" required/>-->

<!--                                                                                        <input type="text" v-model="input.conditions[i].value2" v-if="input.conditions[i].operator === '[]'"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input ml-5" style="border: 0" placeholder="To" required/>-->
                                                                                    </span>

                                                                                    <span v-if="input.conditions[i].attribute === 'quote_item_id'">
                                                                                        Item ID
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                            <!-- eslint-disable-next-line-->
                                                                                            <option v-for="operator in operator_1"
                                                                                                    v-bind:value="operator.value">
                                                                                                {{ operator.text }}
                                                                                            </option>
                                                                                        </select>

                                                                                        <context-menu
                                                                                             v-model:value="input.conditions[i].value"
                                                                                             v-model:value2="input.conditions[i].value2"
                                                                                             :value-operator="input.conditions[i].operator"
                                                                                             @itemSelected="handleMenuItemSelected"
                                                                                         />

<!--                                                                                        <input type="text" v-model="input.conditions[i].value"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" :placeholder="[input.conditions[i].operator === '[]' ? 'From' : '...']" required/>-->

<!--                                                                                        <input type="text" v-model="input.conditions[i].value2" v-if="input.conditions[i].operator === '[]'"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input ml-5" style="border: 0" placeholder="To" required/>-->
                                                                                    </span>

                                                                                    <span v-if="input.conditions[i].attribute === 'quote_item_mobile'">
                                                                                        Mobile Number
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                            <!-- eslint-disable-next-line-->
                                                                                            <option v-for="operator in operator_1"
                                                                                                    v-bind:value="operator.value">
                                                                                                {{ operator.text }}
                                                                                            </option>
                                                                                        </select>

                                                                                        <context-menu
                                                                                             v-model:value="input.conditions[i].value"
                                                                                             v-model:value2="input.conditions[i].value2"
                                                                                             :value-operator="input.conditions[i].operator"
                                                                                             @itemSelected="handleMenuItemSelected"
                                                                                         />

<!--                                                                                        <input type="text" v-model="input.conditions[i].value"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" :placeholder="[input.conditions[i].operator === '[]' ? 'From' : '...']" required/>-->

<!--                                                                                        <input type="text" v-model="input.conditions[i].value2" v-if="input.conditions[i].operator === '[]'"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input ml-5" style="border: 0" placeholder="To" required/>-->
                                                                                    </span>

                                                                                    <span v-if="input.conditions[i].attribute === 'base_subtotal_excl_tax'">
                                                                                        Subtotal (Excl. Tax)
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                            <!-- eslint-disable-next-line-->
                                                                                            <option v-for="operator in exclSubtotalOperator"
                                                                                                    v-bind:value="operator.value">
                                                                                                {{ operator.text }}
                                                                                            </option>
                                                                                        </select>
                                                                                        <input type="number" v-model="input.conditions[i].value"
                                                                                               step=".01"
                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>
                                                                                    </span>

                                                                                    <span v-if="input.conditions[i].attribute === 'base_subtotal'">
                                                                                        Subtotal
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                            <!-- eslint-disable-next-line-->
                                                                                            <option v-for="operator in baseSubtotalOperator"
                                                                                                    v-bind:value="operator.value">
                                                                                                {{ operator.text }}
                                                                                            </option>
                                                                                        </select>
                                                                                        <input type="number" v-model="input.conditions[i].value"
                                                                                               step=".01"
                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>
                                                                                    </span>

                                                                                    <span><i class="fas fa-minus-circle" @click="remove_cart_serialized(input,data)"></i></span>
                                                                                </div>
                                                                                <span><i class="fas fa-plus-circle" @click="add_cart_serialized(k)"></i></span>
                                                                            </div>
                                                                        </span>

                                                                        <span v-if="input.type === 'products_subselection'">
                                                                            If
                                                                            <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                    v-model="input.attribute" required>
                                                                                <!-- eslint-disable-next-line-->
                                                                                <option v-for="attribute in rule_param_3"
                                                                                        v-bind:value="attribute.value">
                                                                                    {{ attribute.text }}
                                                                                </option>
                                                                            </select>
                                                                            <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                    v-model="input.operator" required>
                                                                                <!-- eslint-disable-next-line-->
                                                                                <option v-for="operator in operator_2"
                                                                                        v-bind:value="operator.value">
                                                                                    {{ operator.text }}
                                                                                </option>
                                                                            </select>
                                                                             <input type="number" v-model="input.value"
                                                                                    step=".01"
                                                                                    class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>
                                                                            for a subselection of items in cart matching
                                                                            <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                    v-model="input.aggregator" required>
                                                                                <!-- eslint-disable-next-line-->
                                                                                <option v-for="aggregator in rule_param_2"
                                                                                        v-bind:value="aggregator.value">
                                                                                    {{ aggregator.text }}
                                                                                </option>
                                                                            </select>
                                                                            of these conditions:

                                                                            <span><i class="fas fa-minus-circle" @click="remove(k, ruleCondition.length)"></i></span>

                                                                            <div class="col-2 row"  style="display:grid; !important; margin-left: 2%">
                                                                                <div v-for="(data,i) in this.ruleCondition[k].conditions" :key="i">
                                                                                    <select v-show="input.conditions[i].attribute === ''" style="display: block" class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                        v-model="input.conditions[i].attribute" required>
                                                                                        <!-- eslint-disable-next-line-->
                                                                                        <option v-for="attribute in cart_item_attribute"
                                                                                                v-bind:value="attribute.attribute">
                                                                                            {{ attribute.text }}
                                                                                        </option>
                                                                                    </select>

                                                                                    <span v-if="input.conditions[i].attribute === 'quote_item_price'">
                                                                                        Price in cart
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                            <!-- eslint-disable-next-line-->
                                                                                            <option v-for="operator in priceCartOperator"
                                                                                                    v-bind:value="operator.value">
                                                                                                {{ operator.text }}
                                                                                            </option>
                                                                                        </select>
                                                                                        <input type="number" v-model="input.conditions[i].value"
                                                                                               step=".01"
                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>
                                                                                    </span>

                                                                                    <span v-if="input.conditions[i].attribute === 'quote_item_qty'">
                                                                                        Quantity in cart
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                            <!-- eslint-disable-next-line-->
                                                                                            <option v-for="operator in itemQtyCartOperator"
                                                                                                    v-bind:value="operator.value">
                                                                                                {{ operator.text }}
                                                                                            </option>
                                                                                        </select>
                                                                                        <input type="number" v-model="input.conditions[i].value"
                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>
                                                                                    </span>

                                                                                    <span v-if="input.conditions[i].attribute === 'quote_item_row'">
                                                                                        Row total in cart
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                            <!-- eslint-disable-next-line-->
                                                                                            <option v-for="operator in rowTotalCartOperator"
                                                                                                    v-bind:value="operator.value">
                                                                                                {{ operator.text }}
                                                                                            </option>
                                                                                        </select>
                                                                                        <input type="number" v-model="input.conditions[i].value"
                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>
                                                                                    </span>

                                                                                    <span v-if="input.conditions[i].attribute === 'quote_item_smac'">
                                                                                        SMAC
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                            <!-- eslint-disable-next-line-->
                                                                                            <option v-for="operator in smacOperator"
                                                                                                    v-bind:value="operator.value">
                                                                                                {{ operator.text }}
                                                                                            </option>
                                                                                        </select>

                                                                                        <context-menu
                                                                                             v-model:value="input.conditions[i].value"
                                                                                             v-model:value2="input.conditions[i].value2"
                                                                                             :value-operator="input.conditions[i].operator"
                                                                                             @itemSelected="handleMenuItemSelected"
                                                                                         />

<!--                                                                                        <input type="text" v-model="input.conditions[i].value"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>-->

<!--                                                                                        <input type="text" v-model="input.conditions[i].value"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" :placeholder="[input.conditions[i].operator === '[]' ? 'From' : '...']" required/>-->

<!--                                                                                        <input type="text" v-model="input.conditions[i].value2" v-if="input.conditions[i].operator === '[]'"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input ml-5" style="border: 0" placeholder="To" required/>-->

                                                                                    </span>

                                                                                    <span v-if="input.conditions[i].attribute === 'quote_item_bin_number'">
                                                                                        Bin Number (First 6 digits)
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                            <!-- eslint-disable-next-line-->
                                                                                            <option v-for="operator in binNumberOperator"
                                                                                                    v-bind:value="operator.value">
                                                                                                {{ operator.text }}
                                                                                            </option>
                                                                                        </select>

                                                                                        <context-menu
                                                                                             v-model:value="input.conditions[i].value"
                                                                                             v-model:value2="input.conditions[i].value2"
                                                                                             :value-operator="input.conditions[i].operator"
                                                                                             @itemSelected="handleMenuItemSelected"
                                                                                         />
<!--                                                                                        <input type="text" v-model="input.conditions[i].value"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>-->

<!--                                                                                        <input type="text"-->
<!--                                                                                               v-model="input.conditions[i].value"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input"-->
<!--                                                                                               style="border: 0"-->
<!--                                                                                               :placeholder="[input.conditions[i].operator === '[]' ? 'From' : '...']"-->
<!--                                                                                               required/>-->

<!--                                                                                        <input type="text"-->
<!--                                                                                               v-if="input.conditions[i].operator === '[]'"-->
<!--                                                                                               v-model="input.conditions[i].value2"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input ml-5"-->
<!--                                                                                               style="border: 0"-->
<!--                                                                                               placeholder="To"-->
<!--                                                                                               required/>-->
                                                                                    </span>

                                                                                    <span v-if="input.conditions[i].attribute === 'base_subtotal_excl_tax'">
                                                                                        Subtotal (Excl. Tax)
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                            <!-- eslint-disable-next-line-->
                                                                                            <option v-for="operator in exclSubtotalOperator"
                                                                                                    v-bind:value="operator.value">
                                                                                                {{ operator.text }}
                                                                                            </option>
                                                                                        </select>
                                                                                        <input type="number" v-model="input.conditions[i].value"
                                                                                               step=".01"
                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>
                                                                                    </span>

                                                                                    <span v-if="input.conditions[i].attribute === 'base_subtotal'">
                                                                                        Subtotal
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                            <!-- eslint-disable-next-line-->
                                                                                            <option v-for="operator in baseSubtotalOperator"
                                                                                                    v-bind:value="operator.value">
                                                                                                {{ operator.text }}
                                                                                            </option>
                                                                                        </select>
                                                                                        <input type="number" v-model="input.conditions[i].value"
                                                                                               step=".01"
                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>
                                                                                    </span>

                                                                                    <span v-if="input.conditions[i].attribute === 'quote_item_barcode'">
                                                                                        Barcode
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                            <!-- eslint-disable-next-line-->
                                                                                            <option v-for="operator in operator_1"
                                                                                                    v-bind:value="operator.value">
                                                                                                {{ operator.text }}
                                                                                            </option>
                                                                                        </select>

                                                                                        <context-menu
                                                                                             v-model:value="input.conditions[i].value"
                                                                                             v-model:value2="input.conditions[i].value2"
                                                                                             :value-operator="input.conditions[i].operator"
                                                                                             @itemSelected="handleMenuItemSelected"
                                                                                         />

<!--                                                                                        <input type="text" v-model="input.conditions[i].value"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" :placeholder="[input.conditions[i].operator === '[]' ? 'From' : '...']" required/>-->

<!--                                                                                        <input type="text" v-model="input.conditions[i].value2" v-if="input.conditions[i].operator === '[]'"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input ml-5" style="border: 0" placeholder="To" required/>-->
                                                                                    </span>

                                                                                    <span v-if="input.conditions[i].attribute === 'quote_item_id'">
                                                                                        Item ID
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                            <!-- eslint-disable-next-line-->
                                                                                            <option v-for="operator in operator_1"
                                                                                                    v-bind:value="operator.value">
                                                                                                {{ operator.text }}
                                                                                            </option>
                                                                                        </select>

                                                                                        <context-menu
                                                                                             v-model:value="input.conditions[i].value"
                                                                                             v-model:value2="input.conditions[i].value2"
                                                                                             :value-operator="input.conditions[i].operator"
                                                                                             @itemSelected="handleMenuItemSelected"
                                                                                         />

<!--                                                                                        <input type="text" v-model="input.conditions[i].value"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" :placeholder="[input.conditions[i].operator === '[]' ? 'From' : '...']" required/>-->

<!--                                                                                        <input type="text" v-model="input.conditions[i].value2" v-if="input.conditions[i].operator === '[]'"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input ml-5" style="border: 0" placeholder="To" required/>-->
                                                                                    </span>

                                                                                    <span v-if="input.conditions[i].attribute === 'quote_item_mobile'">
                                                                                        Mobile Number
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                                <!-- eslint-disable-next-line-->
                                                                                                <option v-for="operator in operator_1"
                                                                                                        v-bind:value="operator.value">
                                                                                                    {{ operator.text }}
                                                                                                </option>
                                                                                        </select>

                                                                                        <context-menu
                                                                                             v-model:value="input.conditions[i].value"
                                                                                             v-model:value2="input.conditions[i].value2"
                                                                                             :value-operator="input.conditions[i].operator"
                                                                                             @itemSelected="handleMenuItemSelected"
                                                                                         />

<!--                                                                                        <input type="text" v-model="input.conditions[i].value"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" :placeholder="[input.conditions[i].operator === '[]' ? 'From' : '...']" required/>-->

<!--                                                                                        <input type="text" v-model="input.conditions[i].value2" v-if="input.conditions[i].operator === '[]'"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input ml-5" style="border: 0" placeholder="To" required/>-->
                                                                                    </span>

                                                                                    <span><i class="fas fa-minus-circle" @click="remove_cart_serialized(input,data)"></i></span>
                                                                                </div>
                                                                                <span><i class="fas fa-plus-circle" @click="add_cart_serialized(k)"></i></span>
                                                                            </div>
                                                                        </span>

                                                                        <span v-if="input.type === 'condition_combination'">
                                                                            If
                                                                            <select v-show="false" class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                    v-model="input.type">
                                                                                    <!-- eslint-disable-next-line-->
                                                                                    <option v-for="type in typeConditionCombine"
                                                                                            v-bind:value="type.value">
                                                                                        {{ type.text }}
                                                                                    </option>
                                                                            </select>

                                                                            <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                    v-model="input.aggregator" required>
                                                                                    <!-- eslint-disable-next-line-->
                                                                                    <option v-for="aggregator in rule_param_2"
                                                                                            v-bind:value="aggregator.value">
                                                                                        {{ aggregator.text }}
                                                                                    </option>
                                                                            </select>
                                                                            of these conditions are
                                                                            <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                    v-model="input.value" required>
                                                                                    <!-- eslint-disable-next-line-->
                                                                                    <option v-for="value in condition"
                                                                                            v-bind:value="value.value">
                                                                                        {{ value.text }}
                                                                                    </option>
                                                                            </select>:

                                                                            <span><i class="fas fa-minus-circle" @click="remove(k, ruleCondition.length)"></i></span>

                                                                            <div class="col-2 row" style="display:grid; !important; margin-left: 2%">
                                                                                <div v-for="(data,i) in this.ruleCondition[k].conditions" :key="i">
                                                                                    <select v-show="input.conditions[i].attribute === ''" style="display: block" class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                            v-model="input.conditions[i].attribute" required>
                                                                                            <!-- eslint-disable-next-line-->
                                                                                            <option v-for="attribute in conditionTypeCombination"
                                                                                                    v-bind:value="attribute.attribute" >
                                                                                                {{ attribute.text }}
                                                                                            </option>
                                                                                    </select>

                                                                                    <span v-if="input.conditions[i].attribute === 'quote_item_price'">
                                                                                        Price in cart
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                                <!-- eslint-disable-next-line-->
                                                                                                <option v-for="operator in priceCartOperator"
                                                                                                        v-bind:value="operator.value">
                                                                                                    {{ operator.text }}
                                                                                                </option>
                                                                                        </select>
                                                                                        <input type="number" v-model="input.conditions[i].value"
                                                                                               step=".01"
                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>
                                                                                    </span>

                                                                                    <span v-if="input.conditions[i].attribute === 'quote_item_qty'">
                                                                                        Quantity in cart
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                                <!-- eslint-disable-next-line-->
                                                                                                <option v-for="operator in itemQtyCartOperator"
                                                                                                        v-bind:value="operator.value">
                                                                                                    {{ operator.text }}
                                                                                                </option>
                                                                                        </select>
                                                                                        <input type="number" v-model="input.conditions[i].value"
                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>
                                                                                    </span>

                                                                                    <span v-if="input.conditions[i].attribute === 'quote_item_row'">
                                                                                        Row total in cart
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                                <!-- eslint-disable-next-line-->
                                                                                                <option v-for="operator in rowTotalCartOperator"
                                                                                                        v-bind:value="operator.value">
                                                                                                    {{ operator.text }}
                                                                                                </option>
                                                                                        </select>
                                                                                        <input type="number" v-model="input.conditions[i].value"
                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>
                                                                                    </span>

                                                                                    <span v-if="input.conditions[i].attribute === 'quote_item_smac'">
                                                                                        SMAC
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                                <!-- eslint-disable-next-line-->
                                                                                                <option v-for="operator in smacOperator"
                                                                                                        v-bind:value="operator.value">
                                                                                                    {{ operator.text }}
                                                                                                </option>
                                                                                        </select>

                                                                                        <context-menu
                                                                                             v-model:value="input.conditions[i].value"
                                                                                             v-model:value2="input.conditions[i].value2"
                                                                                             :value-operator="input.conditions[i].operator"
                                                                                             @itemSelected="handleMenuItemSelected"
                                                                                         />

<!--                                                                                        <input type="text" v-model="input.conditions[i].value"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" :placeholder="[input.conditions[i].operator === '[]' ? 'From' : '...']" required/>-->

<!--                                                                                        <input type="text" v-model="input.conditions[i].value2" v-if="input.conditions[i].operator === '[]'"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input ml-5" style="border: 0" placeholder="To" required/>-->

                                                                                    </span>

                                                                                    <span v-if="input.conditions[i].attribute === 'quote_item_bin_number'">
                                                                                        Bin Number (First 6 digits)
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                                <!-- eslint-disable-next-line-->
                                                                                                <option v-for="operator in cart_operators"
                                                                                                        v-bind:value="operator.value">
                                                                                                    {{ operator.text }}
                                                                                                </option>
                                                                                        </select>

                                                                                        <context-menu
                                                                                             v-model:value="input.conditions[i].value"
                                                                                             v-model:value2="input.conditions[i].value2"
                                                                                             :value-operator="input.conditions[i].operator"
                                                                                             @itemSelected="handleMenuItemSelected"
                                                                                         />

<!--                                                                                        <input type="text" v-model="input.conditions[i].value"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>-->

<!--                                                                                        <input type="text"-->
<!--                                                                                               v-model="input.conditions[i].value"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input"-->
<!--                                                                                               style="border: 0"-->
<!--                                                                                               :placeholder="[input.conditions[i].operator === '[]' ? 'From' : '...']"-->
<!--                                                                                               required/>-->

<!--                                                                                        <input type="text"-->
<!--                                                                                               v-if="input.conditions[i].operator === '[]'"-->
<!--                                                                                               v-model="input.conditions[i].value2"-->
<!--                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input ml-5"-->
<!--                                                                                               style="border: 0"-->
<!--                                                                                               placeholder="To"-->
<!--                                                                                               required/>-->
                                                                                    </span>

                                                                                    <span  v-if="input.conditions[i].attribute === 'base_subtotal_excl_tax'">
                                                                                        Subtotal (Excl. Tax)
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                            v-model="input.conditions[i].operator" required>
                                                                                            <!-- eslint-disable-next-line-->
                                                                                            <option v-for="operator in exclSubtotalOperator"
                                                                                                    v-bind:value="operator.value">
                                                                                                {{ operator.text }}
                                                                                            </option>
                                                                                        </select>
                                                                                         <input type="number" :v-model="input.value !== '' ? '' : ''"
                                                                                                step=".01"
                                                                                                class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>

<!--                                                                                         <span><i class="fas fa-minus-circle" @click="remove(k, ruleCondition.length)"></i></span>-->
                                                                                    </span>

                                                                                     <span v-if="input.conditions[i].attribute === 'base_subtotal'">
                                                                                         Subtotal
                                                                                         <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                                <!-- eslint-disable-next-line-->
                                                                                                <option v-for="operator in baseSubtotalOperator"
                                                                                                        v-bind:value="operator.value">
                                                                                                    {{ operator.text }}
                                                                                                </option>
                                                                                         </select>
                                                                                         <input type="number" :v-model="input.value !== '' ? '' : ''"
                                                                                                step=".01"
                                                                                                class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>

<!--                                                                                         <span><i class="fas fa-minus-circle" @click="remove(k, ruleCondition.length)"></i></span>-->
                                                                                     </span>

                                                                                     <span v-if="input.conditions[i].attribute === 'total_qty'">
                                                                                         Total Items Quantity
                                                                                         <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[i].operator" required>
                                                                                            <!-- eslint-disable-next-line-->
                                                                                            <option v-for="operator in totalQtyOperator"
                                                                                                    v-bind:value="operator.value">
                                                                                                {{ operator.text }}
                                                                                            </option>
                                                                                        </select>
                                                                                         <input type="number" :v-model="input.value !== '' ? '' : ''"
                                                                                                class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>

<!--                                                                                         <span><i class="fas fa-minus-circle" @click="remove(k, ruleCondition.length)"  v-show="k || ( !k && ruleCondition.length > 1)"></i></span>-->
                                                                                     </span>

                                                                                    <span><i class="fas fa-minus-circle" @click="remove_cart_serialized(input,data)"></i></span>
                                                                                </div>
                                                                                <span><i class="fas fa-plus-circle" @click="add_cart_serialized(k)"></i></span>
                                                                            </div>
                                                                        </span>

                                                                        <div>
                                                                            <span v-if="input.type === 'base_subtotal_excl_tax'">
                                                                                Subtotal (Excl. Tax)
                                                                                <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                        v-model="input.operator" required>
                                                                                        <!-- eslint-disable-next-line-->
                                                                                        <option v-for="operator in exclSubtotalOperator"
                                                                                                v-bind:value="operator.value">
                                                                                            {{ operator.text }}
                                                                                        </option>
                                                                                </select>

                                                                                <input type="number" v-model="input.value"
                                                                                       step=".01"
                                                                                       class="w-25 focus:ring-indigo-500 sm:text-sm custom-input"
                                                                                       style="border: 0"
                                                                                       placeholder="..." required/>

                                                                                <span><i class="fas fa-minus-circle" @click="remove(k, ruleCondition.length)"></i></span>
                                                                            </span>

                                                                            <span v-if="input.type === 'base_subtotal'">
                                                                                 Subtotal
                                                                                 <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                        v-model="input.operator" required>
                                                                                    <!-- eslint-disable-next-line-->
                                                                                    <option v-for="operator in baseSubtotalOperator"
                                                                                            v-bind:value="operator.value">
                                                                                        {{ operator.text }}
                                                                                    </option>
                                                                                </select>
                                                                                <input type="number" v-model="input.value"
                                                                                       step=".01"
                                                                                       class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>

                                                                                <span><i class="fas fa-minus-circle" @click="remove(k, ruleCondition.length)"></i></span>
                                                                            </span>

                                                                            <span v-if="input.type === 'total_qty'">
                                                                                 Total Items Quantity
                                                                                <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                        v-model="input.operator" required>
                                                                                    <!-- eslint-disable-next-line-->
                                                                                    <option v-for="operator in totalQtyOperator"
                                                                                            v-bind:value="operator.value">
                                                                                        {{ operator.text }}
                                                                                    </option>
                                                                                </select>
                                                                                <input type="number" v-model="input.value"
                                                                                        class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>

                                                                                <span><i class="fas fa-minus-circle" @click="remove(k, ruleCondition.length)"></i></span>
                                                                            </span>

                                                                             <span v-if="input.type === 'smac'">
                                                                                 SMAC
                                                                                 <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                        v-model="input.operator" required>
                                                                                    <!-- eslint-disable-next-line-->
                                                                                    <option v-for="operator in smacOperator"
                                                                                            v-bind:value="operator.value">
                                                                                        {{ operator.text }}
                                                                                    </option>
                                                                                </select>

                                                                                <!-- eslint-disable-next-line-->
                                                                                 <context-menu
                                                                                     v-model:value="input.value"
                                                                                     v-model:value2="input.value2"
                                                                                     :value-operator="input.operator"
                                                                                     @itemSelected="handleMenuItemSelected"
                                                                                 />
<!--                                                                                 <input type="text"-->
<!--                                                                                        v-model="input.value"-->
<!--                                                                                        class="w-25-->
<!--                                                                                               focus:ring-indigo-500-->
<!--                                                                                               sm:text-sm custom-input"-->
<!--                                                                                        style="border: 0"-->
<!--                                                                                        :placeholder="[input.operator === '[]' ? 'From' : '...']"-->
<!--                                                                                        @contextmenu.prevent="showContextMenu($event)"-->
<!--                                                                                        required/>-->

<!--                                                                                 <input type="text" v-model="input.value2" v-if="input.operator === '[]'"-->
<!--                                                                                        class="w-25 focus:ring-indigo-500 sm:text-sm custom-input ml-5" style="border: 0" placeholder="To" required/>-->

                                                                                 <span><i class="fas fa-minus-circle" @click="remove(k, ruleCondition.length)"></i></span>
                                                                             </span>

                                                                             <span v-if="input.type === 'bin_number'">
                                                                                 Bin Number (First 6 digits)
                                                                                 <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                        v-model="input.operator" required>
                                                                                    <!-- eslint-disable-next-line-->
                                                                                    <option v-for="operator in binNumberOperator"
                                                                                            v-bind:value="operator.value">
                                                                                        {{ operator.text }}
                                                                                    </option>
                                                                                </select>

                                                                                 <context-menu
                                                                                     v-model:value="input.value"
                                                                                     v-model:value2="input.value2"
                                                                                     :value-operator="input.operator"
                                                                                     @itemSelected="handleMenuItemSelected"
                                                                                 />

<!--                                                                                 <input type="text" v-model="input.value"-->
<!--                                                                                        class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>-->
<!--                                                                                 <input type="text"-->
<!--                                                                                        v-model="input.value"-->
<!--                                                                                        class="w-25 focus:ring-indigo-500 sm:text-sm custom-input"-->
<!--                                                                                        style="border: 0"-->
<!--                                                                                        :placeholder="[input.operator === '[]' ? 'From' : '...']"-->
<!--                                                                                        required/>-->

<!--                                                                                 <input type="text"-->
<!--                                                                                        v-if="input.operator === '[]'"-->
<!--                                                                                        v-model="input.value2"-->
<!--                                                                                        class="w-25 focus:ring-indigo-500 sm:text-sm custom-input ml-5"-->
<!--                                                                                        style="border: 0"-->
<!--                                                                                        placeholder="To"-->
<!--                                                                                        required/>-->

                                                                                 <span><i class="fas fa-minus-circle" @click="remove(k, ruleCondition.length)"></i></span>
                                                                             </span>

                                                                             <span v-if="input.type === 'mobile'">
                                                                                 Mobile Number
                                                                                 <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                        v-model="input.operator" required>
                                                                                    <!-- eslint-disable-next-line-->
                                                                                    <option v-for="operator in operator_1"
                                                                                            v-bind:value="operator.value">
                                                                                        {{ operator.text }}
                                                                                    </option>
                                                                                </select>

                                                                                 <context-menu
                                                                                     v-model:value="input.value"
                                                                                     v-model:value2="input.value2"
                                                                                     :value-operator="input.operator"
                                                                                     @itemSelected="handleMenuItemSelected"
                                                                                 />

<!--                                                                                 <input type="text" v-model="input.value"-->
<!--                                                                                        class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" placeholder="..." required/>-->

<!--                                                                                 <input type="text"-->
<!--                                                                                        v-model="input.value"-->
<!--                                                                                        class="w-25 focus:ring-indigo-500 sm:text-sm custom-input"-->
<!--                                                                                        style="border: 0"-->
<!--                                                                                        :placeholder="[input.operator === '[]' ? 'From' : '...']"-->
<!--                                                                                        required/>-->

<!--                                                                                 <input type="text"-->
<!--                                                                                        v-if="input.operator === '[]'"-->
<!--                                                                                        v-model="input.value2"-->
<!--                                                                                        class="w-25 focus:ring-indigo-500 sm:text-sm custom-input ml-5"-->
<!--                                                                                        style="border: 0"-->
<!--                                                                                        placeholder="To"-->
<!--                                                                                        required/>-->

                                                                                 <span><i class="fas fa-minus-circle" @click="remove(k, ruleCondition.length)"></i></span>
                                                                             </span>


                                                                            <div v-if="input.type === 'base_subtotal_excl_tax' || input.type === 'base_subtotal' || input.type === 'total_qty' || input.type === 'smac' || input.type === 'bin_number' || input.type === 'mobile'" style="!important; margin-left: 2%">
                                                                                <div v-for="(data,l) in this.ruleCondition[k].conditions" :key="l">
                                                                                    <select v-show="input.conditions[l].type === ''"
                                                                                            v-model="input.conditions[l].type"
                                                                                            class="mb-3 block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                                                                            <!-- eslint-disable-next-line-->
                                                                                            <option v-for="type in sub_cartConditions"
                                                                                                    v-bind:value="type.attribute"
                                                                                                    >
                                                                                                {{ type.text }}
                                                                                            </option>

                                                                                    </select>

                                                                                    <span v-if="input.conditions[l].type === 'products_attribute_combination'">
                                                                                        If an item is
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[l].value" required>
                                                                                                <!-- eslint-disable-next-line-->
                                                                                                <option v-for="aggregator in rule_param_1"
                                                                                                        v-bind:value="aggregator.value">
                                                                                                    {{ aggregator.text }}
                                                                                                </option>
                                                                                        </select>
                                                                                        in the cart with
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[l].aggregator" required>
                                                                                                <!-- eslint-disable-next-line-->
                                                                                                <option v-for="value in rule_param_2"
                                                                                                        v-bind:value="value.value">
                                                                                                    {{ value.text }}

                                                                                                </option>
                                                                                        </select>:

                                                                                        <span ><i class="fas fa-minus-circle" @click="remove_cart_serialized(input,data)"></i></span>

                                                                                    </span>

                                                                                    <span v-if="input.conditions[l].type === 'products_subselection'">
                                                                                        If
                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[l].attribute" required>
                                                                                                <!-- eslint-disable-next-line-->
                                                                                                <option v-for="attribute in rule_param_3"
                                                                                                        v-bind:value="attribute.value">
                                                                                                    {{ attribute.text }}
                                                                                                </option>
                                                                                        </select>

                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[l].operator" required>
                                                                                                <!-- eslint-disable-next-line-->
                                                                                                <option v-for="operator in operator_2"
                                                                                                        v-bind:value="operator.value">
                                                                                                    {{ operator.text }}
                                                                                                </option>
                                                                                        </select>

                                                                                        <input type="number"
                                                                                               v-model="input.conditions[l].value"
                                                                                               step=".01"
                                                                                               class="w-25 focus:ring-indigo-500 sm:text-sm custom-input"
                                                                                               style="border: 0"
                                                                                               placeholder="..." required/>

                                                                                        for a subselection of items in cart matching

                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[l].aggregator" required>
                                                                                                <!-- eslint-disable-next-line-->
                                                                                                <option v-for="value in rule_param_2"
                                                                                                        v-bind:value="value.value">
                                                                                                    {{ value.text }}
                                                                                                </option>
                                                                                        </select>
                                                                                        of these conditions:

                                                                                        <span ><i class="fas fa-minus-circle" @click="remove_cart_serialized(input,data)"></i></span>
                                                                                    </span>

                                                                                    <span v-if="input.conditions[l].type === 'condition_combination'">
                                                                                        If
                                                                                        <select v-show="false" class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[l].type" required>
                                                                                                <!-- eslint-disable-next-line-->
                                                                                                <option v-for="operator in typeConditionCombine"
                                                                                                        v-bind:value="operator.value">
                                                                                                    {{ operator.text }}
                                                                                                </option>
                                                                                        </select>

                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[l].aggregator" required>
                                                                                                <!-- eslint-disable-next-line-->
                                                                                                <option v-for="aggregator in rule_param_2"
                                                                                                        v-bind:value="aggregator.value">
                                                                                                    {{ aggregator.text }}
                                                                                                </option>
                                                                                        </select>
                                                                                        of these conditions are

                                                                                        <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                v-model="input.conditions[l].value"
                                                                                                required>
                                                                                                <!-- eslint-disable-next-line-->
                                                                                                <option v-for="value in condition"
                                                                                                        v-bind:value="value.value">
                                                                                                    {{ value.text }}

                                                                                                </option>
                                                                                        </select>:

                                                                                        <span ><i class="fas fa-minus-circle" @click="remove_cart_serialized(input,data)"></i></span>
                                                                                    </span>

                                                                                    <div v-if="input.conditions[l].type === 'products_attribute_combination' || input.conditions[l].type === 'products_subselection'">
                                                                                        <div v-show="input.conditions[l].type !== ''" style="margin-left: 2%">
                                                                                            <div style="display: flex;" v-for="(data,m) in input.conditions[l].conditions" :key="m">
                                                                                                <select class="mb-3 block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                                                        v-show="input.conditions[l].conditions[m].attribute === ''"
                                                                                                        v-model="input.conditions[l].conditions[m].attribute">
                                                                                                        <!-- eslint-disable-next-line-->
                                                                                                        <option v-for="type in cart_item_attribute"
                                                                                                                v-bind:value="type.attribute"
                                                                                                                >
                                                                                                            {{ type.text }}
                                                                                                        </option>

                                                                                                </select>

                                                                                                <span v-if="input.conditions[l].conditions[m].attribute === 'quote_item_barcode'">
                                                                                                    Barcode
                                                                                                    <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                            v-model="input.conditions[l].conditions[m].operator" required>
                                                                                                        <!-- eslint-disable-next-line-->
                                                                                                        <option v-for="operator in operator_1"
                                                                                                                v-bind:value="operator.value">
                                                                                                            {{ operator.text }}
                                                                                                        </option>
                                                                                                    </select>

                                                                                                    <context-menu
                                                                                                         v-model:value="input.conditions[l].conditions[m].value"
                                                                                                         v-model:value2="input.conditions[l].conditions[m].value2"
                                                                                                         :value-operator="input.conditions[l].conditions[m].operator"
                                                                                                         @itemSelected="handleMenuItemSelected"
                                                                                                     />

<!--                                                                                                    <input type="number"-->
<!--                                                                                                           v-model="input.conditions[l].conditions[m].value"-->
<!--                                                                                                           step=".01"-->
<!--                                                                                                           class="w-25 focus:ring-indigo-500 sm:text-sm custom-input"-->
<!--                                                                                                           style="border: 0"-->
<!--                                                                                                           placeholder="..."-->
<!--                                                                                                           required/>-->

<!--                                                                                                    <input type="text" v-model="input.conditions[l].conditions[m].value"-->
<!--                                                                                                           class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" :placeholder="[input.conditions[l].conditions[m].operator === '[]' ? 'From' : '...']" required/>-->

<!--                                                                                                    <input type="text" v-model="input.conditions[l].conditions[m].value2" v-if="input.conditions[l].conditions[m].operator === '[]'"-->
<!--                                                                                                           class="w-25 focus:ring-indigo-500 sm:text-sm custom-input ml-5" style="border: 0" placeholder="To" required/>-->

                                                                                                    <span><i class="fas fa-minus-circle" @click="remove_cart_serialized(input.conditions[l],data)"></i></span>

                                                                                                </span>

                                                                                                <span v-if="input.conditions[l].conditions[m].attribute === 'quote_item_id'">
                                                                                                    Item ID
                                                                                                    <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                            v-model="input.conditions[l].conditions[m].operator" required>
                                                                                                            <!-- eslint-disable-next-line-->
                                                                                                            <option v-for="operator in operator_1"
                                                                                                                    v-bind:value="operator.value">
                                                                                                                {{ operator.text }}
                                                                                                            </option>
                                                                                                    </select>

                                                                                                    <!--<input type="number"
                                                                                                           v-model="input.conditions[l].conditions[m].value"
                                                                                                           step=".01"
                                                                                                           class="w-25 focus:ring-indigo-500 sm:text-sm custom-input"
                                                                                                           style="border: 0"
                                                                                                           placeholder="..."
                                                                                                           required/>-->

                                                                                                    <context-menu
                                                                                                         v-model:value="input.conditions[l].conditions[m].value"
                                                                                                         v-model:value2="input.conditions[l].conditions[m].value2"
                                                                                                         :value-operator="input.conditions[l].conditions[m].operator"
                                                                                                         @itemSelected="handleMenuItemSelected"
                                                                                                     />

<!--                                                                                                    <input type="text" v-model="input.conditions[l].conditions[m].value"-->
<!--                                                                                                           class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" :placeholder="[input.conditions[l].conditions[m].operator === '[]' ? 'From' : '...']" required/>-->

<!--                                                                                                    <input type="text" v-model="input.conditions[l].conditions[m].value2" v-if="input.conditions[l].conditions[m].operator === '[]'"-->
<!--                                                                                                           class="w-25 focus:ring-indigo-500 sm:text-sm custom-input ml-5" style="border: 0" placeholder="To" required/>-->

                                                                                                    <span><i class="fas fa-minus-circle" @click="remove_cart_serialized(input.conditions[l],data)"></i></span>

                                                                                                </span>

                                                                                            </div>

                                                                                            <span style="!important; margin-left: 2%"><i class="fas fa-plus-circle" @click="sub_add_cart_serialized(k,l)"></i></span>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div v-if="input.conditions[l].type === 'condition_combination'">
                                                                                        <div v-show="input.conditions[l].type !== ''" style="margin-left: 2%">
                                                                                            <div style="display: flex;" v-for="(data,m) in input.conditions[l].conditions" :key="m">
                                                                                                <select class="mb-3 block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                                                        v-show="input.conditions[l].conditions[m].attribute === ''"
                                                                                                        v-model="input.conditions[l].conditions[m].attribute">
                                                                                                        <!-- eslint-disable-next-line-->
                                                                                                        <option v-for="type in sub_conditionTypeCombination"
                                                                                                                v-bind:value="type.attribute"
                                                                                                                >
                                                                                                            {{ type.text }}
                                                                                                        </option>

                                                                                                </select>

                                                                                                <span v-if="input.conditions[l].conditions[m].attribute === 'base_subtotal_excl_tax'">
                                                                                                    Subtotal (Excl. Tax)
                                                                                                    <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                            v-model="input.conditions[l].conditions[m].operator" required>
                                                                                                        <!-- eslint-disable-next-line-->
                                                                                                        <option v-for="operator in exclSubtotalOperator"
                                                                                                                v-bind:value="operator.value">
                                                                                                            {{ operator.text }}
                                                                                                        </option>
                                                                                                    </select>

                                                                                                    <input type="number"
                                                                                                           v-model="input.conditions[l].conditions[m].value"
                                                                                                           step=".01"
                                                                                                           class="w-25 focus:ring-indigo-500 sm:text-sm custom-input"
                                                                                                           style="border: 0"
                                                                                                           placeholder="..."
                                                                                                           required/>

                                                                                                    <span><i class="fas fa-minus-circle" @click="remove_cart_serialized(input.conditions[l],data)"></i></span>

                                                                                                </span>

                                                                                                <span v-if="input.conditions[l].conditions[m].attribute === 'base_subtotal'">
                                                                                                    Subtotal
                                                                                                    <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                            v-model="input.conditions[l].conditions[m].operator" required>
                                                                                                            <!-- eslint-disable-next-line-->
                                                                                                            <option v-for="operator in baseSubtotalOperator"
                                                                                                                    v-bind:value="operator.value">
                                                                                                                {{ operator.text }}
                                                                                                            </option>
                                                                                                    </select>

                                                                                                    <input type="number"
                                                                                                           v-model="input.conditions[l].conditions[m].value"
                                                                                                           step=".01"
                                                                                                           class="w-25 focus:ring-indigo-500 sm:text-sm custom-input"
                                                                                                           style="border: 0"
                                                                                                           placeholder="..."
                                                                                                           required/>

                                                                                                    <span><i class="fas fa-minus-circle" @click="remove_cart_serialized(input.conditions[l],data)"></i></span>

                                                                                                </span>

                                                                                                <span v-if="input.conditions[l].conditions[m].attribute === 'total_qty'">
                                                                                                    Total Items Quantity
                                                                                                    <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                            v-model="input.conditions[l].conditions[m].operator" required>
                                                                                                            <!-- eslint-disable-next-line-->
                                                                                                            <option v-for="operator in totalQtyOperator"
                                                                                                                    v-bind:value="operator.value">
                                                                                                                {{ operator.text }}
                                                                                                            </option>
                                                                                                    </select>

                                                                                                    <input type="number"
                                                                                                           v-model="input.conditions[l].conditions[m].value"
                                                                                                           step=".01"
                                                                                                           class="w-25 focus:ring-indigo-500 sm:text-sm custom-input"
                                                                                                           style="border: 0"
                                                                                                           placeholder="..."
                                                                                                           required/>

                                                                                                    <span><i class="fas fa-minus-circle" @click="remove_cart_serialized(input.conditions[l],data)"></i></span>

                                                                                                </span>

                                                                                                <span v-if="input.conditions[l].conditions[m].attribute === 'quote_item_smac'">
                                                                                                    SMAC
                                                                                                    <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                            v-model="input.conditions[l].conditions[m].operator" required>
                                                                                                            <!-- eslint-disable-next-line-->
                                                                                                            <option v-for="operator in smacOperator"
                                                                                                                    v-bind:value="operator.value">
                                                                                                                {{ operator.text }}
                                                                                                            </option>
                                                                                                    </select>

                                                                                                    <context-menu
                                                                                                         v-model:value="input.conditions[l].conditions[m].value"
                                                                                                         v-model:value2="input.conditions[l].conditions[m].value2"
                                                                                                         :value-operator="input.conditions[l].conditions[m].operator"
                                                                                                         @itemSelected="handleMenuItemSelected"
                                                                                                     />

<!--                                                                                                    <input type="text" v-model="input.conditions[l].conditions[m].value"-->
<!--                                                                                                            class="w-25 focus:ring-indigo-500 sm:text-sm custom-input" style="border: 0" :placeholder="[input.conditions[l].conditions[m].operator === '[]' ? 'From' : '...']" required/>-->

<!--                                                                                                    <input type="text" v-model="input.conditions[l].conditions[m].value2" v-if="input.conditions[l].conditions[m].operator === '[]'"-->
<!--                                                                                                           class="w-25 focus:ring-indigo-500 sm:text-sm custom-input ml-5" style="border: 0" placeholder="To" required/>-->

                                                                                                    <span><i class="fas fa-minus-circle" @click="remove_cart_serialized(input.conditions[l],data)"></i></span>

                                                                                                </span>

                                                                                                <span v-if="input.conditions[l].conditions[m].attribute === 'quote_item_bin_number'">
                                                                                                    Bin Number (First 6 digits)
                                                                                                    <select class="w-50 border-0 focus:ring-indigo-500 sm:text-sm custom-condition"
                                                                                                            v-model="input.conditions[l].conditions[m].operator" required>
                                                                                                            <!-- eslint-disable-next-line-->
                                                                                                            <option v-for="operator in operator_1"
                                                                                                                    v-bind:value="operator.value">
                                                                                                                {{ operator.text }}
                                                                                                            </option>
                                                                                                    </select>

                                                                                                    <context-menu
                                                                                                         v-model:value="input.conditions[l].conditions[m].value"
                                                                                                         v-model:value2="input.conditions[l].conditions[m].value2"
                                                                                                         :value-operator="input.conditions[l].conditions[m].operator"
                                                                                                         @itemSelected="handleMenuItemSelected"
                                                                                                     />

<!--                                                                                                    <input type="text"-->
<!--                                                                                                           v-model="input.conditions[l].conditions[m].value"-->
<!--                                                                                                           class="w-25 focus:ring-indigo-500 sm:text-sm custom-input"-->
<!--                                                                                                           style="border: 0"-->
<!--                                                                                                           :placeholder="[input.conditions[l].conditions[m].operator === '[]' ? 'From' : '...']"-->
<!--                                                                                                           required/>-->

<!--                                                                                                    <input type="text"-->
<!--                                                                                                           v-if="input.conditions[l].conditions[m].operator === '[]'"-->
<!--                                                                                                           v-model="input.conditions[l].conditions[m].value2"-->
<!--                                                                                                           class="w-25 focus:ring-indigo-500 sm:text-sm custom-input ml-5"-->
<!--                                                                                                           style="border: 0"-->
<!--                                                                                                           placeholder="To"-->
<!--                                                                                                           required/>-->

                                                                                                    <span><i class="fas fa-minus-circle" @click="remove_cart_serialized(input.conditions[l],data)"></i></span>

                                                                                                </span>

                                                                                            </div>

                                                                                            <span style="!important; margin-left: 2%"><i class="fas fa-plus-circle" @click="sub_add_cart_serialized(k,l)"></i></span>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                                <span style="!important; margin-left: 2%"><i class="fas fa-plus-circle" @click="add_cart_serialized(k)"></i></span>
                                                                            </div>

                                                                        </div>

                                                                         <span style="display: block;"><i class="fas fa-plus-circle" @click="add()"  v-show="k === ruleCondition.length-1"></i></span>

                                                                    </div>
                                                                </div>
                                                                <!-- end promo conditions -->

                                                                <!-- Start promo action -->
                                                                <DialogTitle class="text-lg font-medium text-white"> Action </DialogTitle>
                                                                <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                                                                  <div class="flex items-center justify-between">
                                                                    <DialogTitle class="text-lg font-medium text-white"> Action </DialogTitle>
                                                                  </div>
                                                                </div>

                                                                <div>
                                                                    <label class="block text-sm font-medium text-gray-900">
                                                                        Apply Action </label>
                                                                    <div class="mt-1">
                                                                        <div class="mt-1">
                                                                            <select class="block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                                    v-model="applyActionValue"
                                                                                    @change="reloadChild"
                                                                                    required
                                                                            >
                                                                                <!-- eslint-disable-next-line-->
                                                                                <option v-for="data in applyAction"
                                                                                        v-bind:value="data.value">
                                                                                    {{ data.text }}
                                                                                </option>
                                                                            </select>
<!--                                                                            <span>Selected: {{ applyActionValue }}</span>-->
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div v-if="applyActionValue === 'cart_by_percent' || applyActionValue === 'cart_by_fixed'">
                                                                    <label class="block text-sm font-medium text-gray-900">
                                                                        Stacking </label>
                                                                    <div class="mt-1">
                                                                        <div class="mt-1">
                                                                            <SwitchGroup as="div" class="flex items-center">
                                                                                    <Switch v-model="stacking"
                                                                                        :class="[stacking ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                                                                    <span aria-hidden="true"
                                                                                          :class="[stacking ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                                                                </Switch>
                                                                            </SwitchGroup>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div v-if="applyActionValue !== 'bundle_promo' & applyActionValue !== 'bundle_promo_percent' & applyActionValue !== 'bundle_promo_fixed'">
                                                                    <label for="discount-amount"
                                                                           class="block text-sm font-medium text-gray-900">
                                                                        Discount Amount </label>
                                                                    <div class="mt-1">
                                                                        <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                        <input type="number" name="discount-amount"
                                                                               id="discount-amount"
                                                                               v-model="discountAmount"
                                                                               step=".01"
                                                                               class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                               required
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div v-if="applyActionValue !== 'cart_fixed' & applyActionValue !== 'cart_percent'">
                                                                     <div>
                                                                        <label class="block text-sm font-medium text-gray-900">
                                                                            Max qty discount is applied to </label>
                                                                        <div class="mt-1">
                                                                            <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <input type="number" name="max-qty-discount-applied"
                                                                                   id="max-qty-discount-applied"
                                                                                   v-model="maxQtyDiscountApplied"
                                                                                   onkeypress="return !(event.charCode == 46)"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <label class="block text-sm font-medium text-gray-900">
                                                                                Max qty discount is applied to (by sku)</label>
                                                                        <div class="mt-1">
                                                                             <input type="number" name="max-qty-discount-applied-by-sku"
                                                                                   id="max-qty-discount-applied-by-sku"
                                                                                   v-model="maxQtyDiscountAppliedBySku"
                                                                                   onkeypress="return !(event.charCode == 46)"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                        </div>
                                                                    </div>

                                                                    <div v-if="applyActionValue === 'cart_buy_x_get_y'">
                                                                        <div>
                                                                            <label class="block text-sm font-medium text-gray-900">
                                                                                Number of X Products </label>
                                                                            <div class="mt-1">
                                                                                <input type="number" name="number-of-x-products"
                                                                                       id="number-of-x-products"
                                                                                       v-model="numberProductX"
                                                                                       class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                            </div>
                                                                        </div>

                                                                        <div>
                                                                            <label class="block text-sm font-medium text-gray-900">
                                                                                Number of Y Products </label>
                                                                            <div class="mt-1">
                                                                                <input type="number" name="number-of-y-products"
                                                                                       id="number-of-y-products"
                                                                                       v-model="numberProductY"
                                                                                       class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- End promo action -->


                                                                <!-- start apply cart conditions -->

                                                                <apply-cart-conditions-input
                                                                    v-model:cartConditions="cart_conditions"
                                                                    :applyActionValue="applyActionValue"
                                                                    :key="childKey"
                                                                />

                                                                <!-- end apply cart conditions -->

                                                                <!-- Start Manage Voucher Codes -->
                                                                <DialogTitle class="text-lg font-medium text-white"> Manage Voucher Codes </DialogTitle>
                                                                <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                                                                  <div class="flex items-center justify-between">
                                                                    <DialogTitle class="text-lg font-medium text-white"> Manage Voucher Codes </DialogTitle>
                                                                  </div>
                                                                </div>

                                                                <div>
                                                                    <div>
                                                                        <label for="voucher_prefix"
                                                                               class="block text-sm font-medium text-gray-900">
                                                                            Voucher Prefix </label>
                                                                        <div class="mt-1">
                                                                            <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <input type="text"
                                                                                   name="trans-disc-sku-id"
                                                                                   id="voucher_prefix"
                                                                                   v-model="voucher_prefix"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label class="block text-sm font-medium text-gray-900">
                                                                            Voucher Qty </label>
                                                                        <div class="mt-1">
                                                                            <input type="number" name="voucher_quantity"
                                                                                   id="voucher_quantity"
                                                                                   v-model="voucher_quantity"
                                                                                   onkeypress="return !(event.charCode == 46)"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"

                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <label class="block text-sm font-medium text-gray-900">
                                                                            Voucher Length </label>
                                                                        <div class="mt-1">
                                                                            <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <input type="number" name="voucher_length"
                                                                                   id="voucher_length"
                                                                                   v-model="voucher_length"
                                                                                   onkeypress="return !(event.charCode == 46)"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"

                                                                            />
                                                                        </div>
                                                                    </div>

<!--                                                                    <div>-->
<!--                                                                        <label class="block text-sm font-medium text-gray-900">-->
<!--                                                                            Voucher Format </label>-->
<!--                                                                        <div class="mt-1">-->
<!--                                                                            <div class="mt-1">-->
<!--                                                                            <select class="block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"-->
<!--                                                                                    v-model="voucher_format"-->
<!--                                                                                >-->
<!--                                                                                    &lt;!&ndash; eslint-disable-next-line&ndash;&gt;-->
<!--                                                                                <option v-for="format in select_voucher_format"-->
<!--                                                                                        v-bind:value="format.value">-->
<!--                                                                                    {{ format.text }}-->
<!--                                                                                </option>-->
<!--                                                                            </select>-->
<!--                                                                            </div>-->

<!--                                                                        </div>-->
<!--                                                                    </div>-->

                                                                    <div>
                                                                        <div class="mt-1">
                                                                            <div class="mt-1">
                                                                                <div class="relative flex items-start py-4">
                                                                                    <div class="min-w-0 text-sm">
                                                                                      <label for="voucher_numeric" class="font-medium text-gray-700">Numeric Only</label>
                                                                                    </div>
                                                                                    <div class="ml-3 flex h-5 items-center">
                                                                                      <input id="voucher_numeric" aria-describedby="comments-description" name="voucher_numeric" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                                                             v-model="voucher_numeric"
                                                                                      />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <label class="block text-sm font-medium text-gray-900">
                                                                            Voucher max number usage </label>
                                                                        <div class="mt-1">
                                                                            <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <input type="number" name="voucher_max_number_usage"
                                                                                   id="voucher_max_number_usage"
                                                                                   v-model="voucher_max_number_usage"
                                                                                   onkeypress="return !(event.charCode == 46)"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"

                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <label class="block text-sm font-medium text-gray-900">
                                                                            Voucher max customer number usage </label>
                                                                        <div class="mt-1">
                                                                            <!--                                                  <textarea id="description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />-->
                                                                            <input type="number" name="voucher_max_customer_number_usage"
                                                                                   id="voucher_max_customer_number_usage"
                                                                                   v-model="voucher_max_customer_number_usage"
                                                                                   onkeypress="return !(event.charCode == 46)"
                                                                                   class="block w-75 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="px-1 py-3">
                                                                    <button type="button"
                                                                            class="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                            @click="generateVoucher"
                                                                    >Generate
                                                                    </button>
                                                                </div>

                                                                <div>
                                                                    <label for="simple-search" class="sr-only">Search</label>
                                                                    <div class="custom-width-40 pt-5" style="display: inline-flex;">
                                                                        <input type="text" id="voucher_query" v-model="voucher_query" class="mr-1 custom-search border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search">
                                                                        <button type="button"
                                                                            class="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                            @click="voucher_filter"><i class="fa fa-search"></i>
                                                                        </button>
<!--                                                                        <button type="submit"><i class="fa fa-search"></i></button>-->
                                                                    </div>

                                                                    <div class="mt-10 mr-10" style="display: flex">
                                                                        <label class="sr-only" style="display: contents">Page </label>
                                                                        <select class="ml-2 block w-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                                v-model="voucher_page"
                                                                                style="margin-top: -5px;"
                                                                                @change="voucher_select_page"
                                                                        >
                                                                            <!-- eslint-disable-next-line-->
                                                                            <option v-for="data in voucher_page_select"
                                                                                    v-bind:value="data.value"
                                                                            >
                                                                                {{ data.text }}
                                                                            </option>
                                                                        </select>
                                                                    </div>

                                                                    <div class="mt-8 flow-root">
                                                                        <div class="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
                                                                            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                                                                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                                                                    <table class="min-w-full divide-y divide-gray-300">
                                                                                      <thead class="bg-gray-50">
                                                                                        <tr>
                                                                                            <th scope="col"
                                                                                              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                                              Voucher</th>
                                                                                            <th scope="col"
                                                                                              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                                              Max no. customer usage</th>
                                                                                            <th scope="col"
                                                                                              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                                              Total no. customer used</th>
                                                                                            <th scope="col"
                                                                                              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                                              Max no. usage</th>
                                                                                            <th scope="col"
                                                                                              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                                              Total no. used</th>
                                                                                            <th scope="col"
                                                                                              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                                              Total no. void</th>
                                                                                            <th scope="col"
                                                                                              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                                              Total</th>
                                                                                            <th scope="col"
                                                                                              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                                              Status</th>
                                                                                            <th scope="col"
                                                                                              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                                              Prefix</th>
                                                                                        </tr>
                                                                                      </thead>
                                                                                      <tbody class="divide-y divide-gray-200 bg-white">
                                                                                        <tr v-for="(voucher, voucherIdx) in vouchers" :key="voucher.voucher_code"
                                                                                            :class="voucherIdx % 2 === 0 ? undefined : 'bg-gray-50'">
                                                                                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                                                {{ voucher.voucher_code }}
                                                                                            </td>
                                                                                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                                                {{ voucher.max_number_of_usage_customer }}
                                                                                            </td>
                                                                                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                                                0
                                                                                            </td>
                                                                                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                                                {{ voucher.max_number_of_usage }}
                                                                                            </td>
                                                                                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                                                {{ voucher.total_voucher_claim }}
                                                                                            </td>
                                                                                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                                                {{ voucher.total_voucher_void }}
                                                                                            </td>
                                                                                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                                                {{ voucher.total_voucher_usage }}
                                                                                            </td>
                                                                                            <td v-if="voucher.is_active === true" class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-5">
                                                                                                <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path fill="#70bf2b" d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
                                                                                                </svg>
                                                                                            </td>
                                                                                            <td v-else class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-5">
                                                                                                <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path fill="#dd4646" d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
                                                                                                </svg>
                                                                                            </td>
                                                                                            <td v-if="voucher.is_prefix === true" class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-5">
                                                                                                <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path fill="#70bf2b" d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
                                                                                                </svg>
                                                                                            </td>
                                                                                            <td v-else class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-5">
                                                                                                <svg width="13" height="13" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path fill="#dd4646" d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
                                                                                                </svg>
                                                                                            </td>
                                                                                        </tr>
                                                                                      </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- end Manage Voucher Codes -->

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="flex flex-shrink-0 justify-end px-4 py-4">
                                                        <button type="button"
                                                                class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                @click="open = false">Cancel
                                                        </button>
                                                        <button type="submit"
                                                                class="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"

                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                </form>
                                            </DialogPanel>
                                        </TransitionChild>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </TransitionRoot>
                    <!-- end dialog information -->
                </div>
                <!-- /End replace -->
            </div>
        </main>
    </div>
    <router-view/>
</template>

<script>
    import {ref} from 'vue'
    import {
        Dialog,
        DialogPanel,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        Switch,
        SwitchGroup,
        SwitchLabel,
    } from '@headlessui/vue'
    import {XIcon} from '@heroicons/vue/outline'
    import axios from "axios";
    import moment from "moment";
    import ContextMenu from "@/components/ContextMenu";
    import ApplyCartConditionsInput from "@/components/ApplyCartConditionsInput";
    import StoreGroupMaxUsage from "@/views/Promo/StoreGroupMaxUsage"

    export default {
        el: "#app",
        name: "NewCartPriceRule",
        components: {
            ContextMenu,
            ApplyCartConditionsInput,
            StoreGroupMaxUsage,
            Dialog,
            DialogPanel,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            XIcon,
            Switch,
            SwitchGroup,
            SwitchLabel
        },
        data() {
            return {
                childKey: 0,
                rule: 'cart_rule',
                showAlert: false,
                message: "",
                createPromo: false,
                updatePromo: false,
                selectedPromoCode: null,
                promoSelected: [],
                valid: false,
                priceRuleValue: '',
                promoId: '',
                promoName: '',
                promoCode: '',
                prefix: null,
                promos: [],
                open: ref(false),
                open_filter: ref(false),
                query: ref(''),
                selectedPerson: ref(),
                stores: [],
                selectedStores: [],
                storeGroups: [],
                selectedStoreGroups: [],
                startDate: null,
                endDate: null,
                numberOfUsage: 0,
                numberOfUsageCustomer: 0,
                aggregators: [
                    {text: 'All', value: 'all'},
                    {text: 'Any', value: 'any'},
                ],
                aggregatorValue: 'all',
                condition: [
                    {text: '(Please select a value)', value: ''},
                    {text: 'True', value: 1},
                    // {text: 'False', value: 0},
                ],
                conditions: [
                    {
                        attribute: '',
                        operator: '',
                        value: '',
                        type: '',
                        value2: '',
                        is_value_processed: '',
                        aggregator: '',
                        attribute_scope: '',
                        conditions: []
                    }
                ],
                cart_conditions: [
                    {
                        attribute: '',
                        operator: '',
                        value: '',
                        value2: '',
                        qty: 0,
                        discount:0,
                    }
                ],
                store_max_usage:[
                    {
                        store_id: '',
                        max_usage: 0,
                    }
                ],
                store_group_max_usage:[
                    {
                        store_group: '',
                        max_usage: 0,
                        option: []
                    }
                ],
                conditionValue: 1,
                attributes: [
                    {text: '(Please select a value)', value: ''},
                    {text: 'Subtotal (Excl. Tax)', value: 'base_subtotal_excl_discount'},
                    // {text: 'Subtotal (Incl. Tax)', value: 'base_subtotal_incl_tax'},
                    {text: 'Subtotal', value: 'base_subtotal'},
                    {text: 'Total Items Quantity', value: 'total_qty'},
                    // {text: 'Mobile Number',  value: 'mobile'},
                    {text: 'SMAC',    value: 'smac'},
                    {text: 'Bin Number (First 6 digits)', value: 'bin_number'},
                ],
                attributeValue: '',
                operators: [
                    {text: '(Please select a value)', value: ''},
                    {text: 'is equal', value: '=='},
                    {text: 'is not', value: '!='},
                    {text: 'equals or greater than', value: '>='},
                    {text: 'equals or less than', value: '<='},
                    {text: 'greater than', value: '>'},
                    {text: 'less than', value: '<'},
                    {text: 'contains', value: '{}'},
                    {text: 'does not contain', value: '!{}'},
                    {text: 'is one of', value: '()'},
                    {text: 'is not one of', value: '!()'},
                ],
                operatorValue: '',
                cart_attributes: [
                    {text: '(Please select a value)', value: ''},
                    // {text: 'Price in cart', value: 'cart_price'},
                    // {text: 'Line Item Quantity', value: 'cart_line_quantity'},
                    // {text: 'Row Total in cart', value: 'cart_row_total'},
                    {text: 'Item ID', value: 'item_id'},
                    {text: 'Barcode', value: 'barcode'},
                    // {text: 'Mobile Number', value: 'mobile'},
                ],
                cart_attributeValue: '',
                cart_operators: [
                    {text: '(Please select a value)', value: ''},
                    {text: 'is equal', value: '=='},
                    {text: 'is not', value: '!='},
                    {text: 'equals or greater than', value: '>='},
                    {text: 'equals or less than', value: '<='},
                    {text: 'greater than', value: '>'},
                    {text: 'less than', value: '<'},
                    {text: 'contains', value: '{}'},
                    {text: 'does not contain', value: '!{}'},
                    {text: 'is one of', value: '()'},
                    {text: 'is not one of', value: '!()'},
                ],
                cart_operatorValue: '',
                applyAction: [
                    {text: '(Please select a value)', value: ''},
                    {text: 'Percent of product price discount' , value: 'cart_by_percent'},
                    {text: 'Fixed amount discount' , value: 'cart_by_fixed'},
                    {text: 'Fixed amount discount for whole cart' , value: 'cart_fixed'},
                    {text: 'Percent amount discount for whole cart' , value: 'cart_percent'},
                    {text: 'Buy X get Y free (discount amount is Y)' , value: 'cart_buy_x_get_y'},
                    {text: 'Bundle Promo' , value: 'bundle_promo'},
                    {text: 'Bundle Promo (Percentage)' , value: 'bundle_promo_percent'},
                    {text: 'Bundle Promo (Fixed Price)' , value: 'bundle_promo_fixed'},
                    // {text: 'To-fixed amount discount' , value: 'cart_to_fixed'},
                    // {text: 'For each $X spent, get $Y discount' , value: 'cart_spent_x_get_y'},
                    // {text: 'For each $X spent, get $Y discount for the whole cart' , value: 'cart_spent_x_get_y_whole'},
                    // {text: 'Buy X get Y' , value: 'cart_buy_x_item_get_y_item'},
                ],
                applyActionValue: '',
                discountAmount: 0,
                maxQtyDiscountApplied: 0,
                maxQtyDiscountAppliedBySku: 0,
                // skuList: '',
                discountQtyStepBuyX: 0,
                numberProductX: 0,
                numberProductY: 0,
                is_active: ref(true),
                applyDiscountType: [
                    {text: 'Override Discount', value: 'override_discount'},
                    {text: 'Do not Apply Discount', value: 'do_not_apply_discount'},
                    {text: 'Combine Discount', value: 'combine_discount'},
                    {text: 'Apply Higher', value: 'apply_higher'},
                ],
                applyDiscount: 'combine_discount',
                transDiscSkuId: '',
                transDiscSkuBarcode: '',
                promoMessage: '',
                cartConditions:  [
                    {text: 'Please choose a condition to add', value: ''},
                    {text: 'Products attribute combination', value: 'products_attribute_combination'},
                    {text: 'Products subselection', value: 'products_subselection'},
                    // {text: 'Orders subselection', value: 'orders_subselection'},
                    // {text: 'Condition Combination', value: 'condition_combination'},
                    {text: 'Cart Attribute', value: 'cart_attribute'},
                    {text: '\xA0\xA0\xA0Subtotal (Excl. Tax)', value: 'base_subtotal_excl_tax'},
                    // {text: '\xA0\xA0\xA0Subtotal (Incl. Tax)', value: 'base_subtotal_incl_tax'},
                    {text: '\xA0\xA0\xA0Subtotal', value: 'base_subtotal'},
                    {text: '\xA0\xA0\xA0Total Items Quantity', value: 'total_qty'},
                    {text: '\xA0\xA0\xA0SMAC',    value: 'smac'},
                    {text: '\xA0\xA0\xA0Bin Number (First 6 digits)',    value: 'bin_number'},
                    {text: '\xA0\xA0\xA0Mobile Number',    value: 'mobile'},
                ],
                sub_cartConditions:  [
                    {text: 'Please choose a condition to add', attribute: ''},
                    {text: 'Products attribute combination', attribute: 'products_attribute_combination'},
                    // {text: 'Products subselection', attribute: 'products_subselection'},
                    {text: 'Condition Combination', attribute: 'condition_combination'},
                ],
                ruleConditionCombine: [
                    {
                        type: "condition_combination",
                        attribute: "",
                        operator: "",
                        value: 1,
                        is_value_processed: "",
                        aggregator: "all",
                        conditions: [],
                    }
                ],
                ruleCondition: [
                    {
                        type: '',
                        attribute: '',
                        operator: '',
                        value: '',
                        value2: '',
                        is_value_processed: '',
                        aggregator: '',
                        conditions: [
                            {
                                type: '',
                                attribute: '',
                                operator: '',
                                value: '',
                                is_value_processed: '',
                                aggregator: '',
                                conditions: [{
                                    type: '',
                                    attribute: '',
                                    operator: '',
                                    value: '',
                                    is_value_processed: '',
                                    aggregator: '',
                                    conditions: [{
                                        type: '',
                                        attribute: '',
                                        operator: '',
                                        value: '',
                                        is_value_processed: '',
                                        aggregator: '',
                                        conditions: []
                                    }]
                                }]
                            }
                        ]
                    }
                ],
                sub_ruleCondition: [
                    {
                        type: '',
                        attribute: '',
                        operator: '',
                        value: '',
                        value2: '',
                        is_value_processed: '',
                        aggregator: '',
                        conditions: [
                            {
                                type: '',
                                attribute: '',
                                operator: '',
                                value: '',
                                value2: '',
                                is_value_processed: '',
                                aggregator: '',
                                attribute_scope: '',
                                conditions: []
                            }
                            ]
                    }
                ],
                conditionsSerialized: [
                    {
                        condition_type: '',
                        condition_attribute: '',
                        condition_operator: '',
                        condition_value: '',
                        condition_is_value_processed: false,
                        condition_attribute_scope: ""
                    }
                ],
                cartItemAttribute: [
                    {text: 'Please choose a condition to add', value: '', attribute: ''},
                    {text: 'Price in cart', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\quote_item_price', attribute: "quote_item_price"},
                    {text: 'Quantity in cart', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\quote_item_qty', attribute: "quote_item_qty"},
                    {text: 'Row Total in cart', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\quote_item_row', attribute: "quote_item_row"},
                    {text: 'Subtotal (Excl. Tax)', value: 'base_subtotal_excl_discount'},
                    // {text: 'Subtotal (Incl. Tax)', value: 'base_subtotal_incl_tax', attribute: ''},
                    {text: 'Subtotal', value: 'base_subtotal', attribute: ''},
                    {text: 'Total Items Quantity', value: 'total_qty', attribute: ''},
                    {text: 'SMAC', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\quote_item_smac', attribute: 'quote_item_smac'},
                    {text: 'Bin Number (First 6 digits)', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\quote_item_bin_number', attribute: "quote_item_bin_number"},
                ],
                // start products_attribute_combination
                cart_item_attribute: [
                    {text: 'Please choose a condition to add', value: '', attribute: ''},
                    // {text: 'SMAC', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\quote_item_smac', attribute: 'quote_item_smac'},
                    // {text: 'Bin Number (First 6 digits)', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\quote_item_bin_number', attribute: "quote_item_bin_number"},
                    // {text: 'Price in cart', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\quote_item_price', attribute: "quote_item_price"},
                    // {text: 'Quantity in cart', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\quote_item_qty', attribute: "quote_item_qty"},
                    // {text: 'Row Total in cart', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\quote_item_row', attribute: "quote_item_row"},
                    {text: 'Barcode', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\quote_item_qty', attribute: "quote_item_barcode"},
                    {text: 'Item ID', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\quote_item_qty', attribute: "quote_item_id"},
                    // {text: 'Mobile Number', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\quote_item_qty', attribute: "quote_item_mobile"},
                ],
                operator_1: [
                    {text: '(Please select a value)', value: ''},
                    {text: 'is equal', value: '=='},
                    // {text: 'is not', value: '!='},
                    {text: 'contains', value: '{}'},
                    // {text: 'does not contain', value: '!{}'},
                    {text: 'is one of', value: '()'},
                    // {text: 'is not one of', value: '!()'},
                    {text: 'range', value: '[]'},
                ],
                // end products_attribute_combination
                // start products_subselection
                conditionTypeSubselection: [
                    {text: 'Please choose a condition to add', value: '', attribute: ''},
                    {text: 'SMAC', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\quote_item_smac', attribute: 'quote_item_smac'},
                    {text: 'Bin Number (First 6 digits)', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\quote_item_bin_number', attribute: "quote_item_bin_number"},
                ],
                operator_2: [
                    {text: '(Please select a value)', value: ''},
                    {text: 'is equal', value: '=='},
                    // {text: 'is not', value: '!='},
                    {text: 'equals or greater than', value: '>='},
                    {text: 'equals or less than', value: '<='},
                    {text: 'greater than', value: '>'},
                    {text: 'less than', value: '<'},
                ],
                // end products_subselection
                // start condition_combination
                conditionTypeCombination: [
                    {text: 'Please choose a condition to add', value: '', attribute: ''},
                    {text: 'Subtotal (Excl. Tax)', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\base_subtotal_excl_tax', attribute: 'base_subtotal_excl_tax'},
                    // {text: 'Subtotal (Incl. Tax)', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\base_subtotal_incl_tax', attribute: 'base_subtotal_incl_tax'},
                    {text: 'Subtotal', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\base_subtotal', attribute: 'base_subtotal'},
                    {text: 'Total Items Quantity', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\total_qty', attribute: 'total_qty'},
                    {text: 'SMAC', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\quote_item_smac', attribute: 'quote_item_smac'},
                    {text: 'Bin Number (First 6 digits)', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\quote_item_bin_number', attribute: "quote_item_bin_number"},
                ],

                sub_conditionTypeCombination: [
                    {text: 'Please choose a condition to add', value: '', attribute: ''},
                    // {text: 'Subtotal (Excl. Tax)', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\base_subtotal_excl_tax', attribute: 'base_subtotal_excl_tax'},
                    // {text: 'Subtotal (Incl. Tax)', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\base_subtotal_incl_tax', attribute: 'base_subtotal_incl_tax'},
                    // {text: 'Subtotal', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\base_subtotal', attribute: 'base_subtotal'},
                    // {text: 'Total Items Quantity', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\total_qty', attribute: 'total_qty'},
                    {text: 'SMAC', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\quote_item_smac', attribute: 'quote_item_smac'},
                    {text: 'Bin Number (First 6 digits)', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\quote_item_bin_number', attribute: "quote_item_bin_number"},
                    // {text: 'Mobile Number', value: 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\quote_item_qty', attribute: "quote_item_mobile"},
                ],
                // end condition_combination
                // start quote_item_price
                priceCartOperator: [
                    {text: '(Please select a value)', value: ''},
                    {text: 'is equal', value: '=='},
                    // {text: 'is not', value: '!='},
                    {text: 'equals or greater than', value: '>='},
                    {text: 'equals or less than', value: '<='},
                    {text: 'greater than', value: '>'},
                    {text: 'less than', value: '<'},
                ],
                // end quote_item_price
                // start quote_item_qty
                itemQtyCartOperator: [
                    {text: '(Please select a value)', value: ''},
                    {text: 'is equal', value: '=='},
                    // {text: 'is not', value: '!='},
                    {text: 'equals or greater than', value: '>='},
                    {text: 'equals or less than', value: '<='},
                    {text: 'greater than', value: '>'},
                    {text: 'less than', value: '<'},
                ],
                // end quote_item_qty
                // start quote_item_row
                rowTotalCartOperator:[
                    {text: '(Please select a value)', value: ''},
                    {text: 'is equal', value: '=='},
                    // {text: 'is not', value: '!='},
                    {text: 'equals or greater than', value: '>='},
                    {text: 'equals or less than', value: '<='},
                    {text: 'greater than', value: '>'},
                    {text: 'less than', value: '<'},
                ],
                // end quote_item_row
                // start smac
                smacOperator: [
                    {text: '(Please select a value)', value: ''},
                    {text: 'is equal', value: '=='},
                    // {text: 'is not', value: '!='},
                    {text: 'contains', value: '{}'},
                    // {text: 'does not contain', value: '!{}'},
                    {text: 'is one of', value: '()'},
                    // {text: 'is not one of', value: '!()'},
                    {text: 'range', value: '[]'},
                ],
                // end smac
                // start bin_number
                binNumberOperator: [
                    {text: '(Please select a value)', value: ''},
                    {text: 'is equal', value: '=='},
                    // {text: 'is not', value: '!='},
                    {text: 'contains', value: '{}'},
                    // {text: 'does not contain', value: '!{}'},
                    {text: 'is one of', value: '()'},
                    // {text: 'is not one of', value: '!()'},
                    {text: 'range', value: '[]'},
                ],
                // end bin_number
                // start base_subtotal_excl_tax
                exclSubtotalOperator: [
                    {text: '(Please select a value)', value: ''},
                    {text: 'is equal', value: '=='},
                    // {text: 'is not', value: '!='},
                    {text: 'equals or greater than', value: '>='},
                    {text: 'equals or less than', value: '<='},
                    {text: 'greater than', value: '>'},
                    {text: 'less than', value: '<'},
                ],
                // end base_subtotal_excl_tax
                // start base_subtotal
                baseSubtotalOperator: [
                    {text: '(Please select a value)', value: ''},
                    {text: 'is equal', value: '=='},
                    // {text: 'is not', value: '!='},
                    {text: 'equals or greater than', value: '>='},
                    {text: 'equals or less than', value: '<='},
                    {text: 'greater than', value: '>'},
                    {text: 'less than', value: '<'},
                ],
                // end base_subtotal
                // start total_qty
                totalQtyOperator: [
                    {text: '(Please select a value)', value: ''},
                    {text: 'is equal', value: '=='},
                    // {text: 'is not', value: '!='},
                    {text: 'equals or greater than', value: '>='},
                    {text: 'equals or less than', value: '<='},
                    {text: 'greater than', value: '>'},
                    {text: 'less than', value: '<'},
                ],
                // end total_qty
                rule_param_1: [
                    {text: '(Please select a value)', value: ''},
                    {text: 'FOUND', value: 'found'},
                    {text: 'NOT FOUND', value: 'not_found'},
                ],
                rule_param_2: [
                    {text: '(Please select a value)', value: ''},
                    {text: 'All', value: 'all'},
                    {text: 'Any', value: 'any'},
                ],
                rule_param_3: [
                    {text: '(Please select a value)', value: ''},
                    {text: 'total quantity', value: 'total_quantity'},
                    {text: 'total amount', value: 'total_amount'},
                ],
                rule_param_4: [
                    {text: '(Please select a value)', value: ''},
                    {text: 'total quantity', value: 'total_quantity'},
                    {text: 'total amount', value: 'total_amount'},
                    {text: 'total amount paid', value: 'total_amount_paid'},
                    {text: 'total refunded amount', value: 'total_refunded_amount'},
                    {text: 'average amount', value: 'average_amount'},
                    {text: 'total order revenue', value: 'total_order_revenue'},
                ],
                typeConditionCombine: [
                    {text: 'condition_combination', value: 'condition_combination'},
                ],
                subTotalInclAggregator: [
                    {text: 'base_subtotal', value: 'base_subtotal'},
                ],
                totalQtyAggregator: [
                    {text: 'total_qty', value: 'total_qty'}
                ],
                subTotalExclAggregator: [
                    {text: 'base_subtotal_excl_tax', value: 'base_subtotal_excl_tax'},
                ],
                smacAggregator: [
                    {text: 'smac', value: 'smac'},
                ],
                binNumberAggregator: [
                    {text: 'bin_number', value: 'bin_number'},
                ],
                mobileAggregator: [
                    {text: 'mobile', value: 'mobile'},
                ],
                currentSort:'promo_code',
                currentSortDir:'asc',
                currentPage:1,
                currentStoreGroupPage:1,
                search_query: ref(''),
                showNextButton: false,
                showPreviousButton: false,
                max_promo_msg_line: 40,
                smac_required: ref(false),
                priority: 0,
                stacking: false,
                order_name: 'promo_code',
                sample_filter: [],
                filter_start_date_from: '',
                filter_start_date_to: '',
                filter_end_date_from: '',
                filter_end_date_to: '',
                filter_discount_amount_from: '',
                filter_discount_amount_to: '',
                filter_priority_from: 0,
                filter_priority_to: 0,
                filter_usage_from: 0,
                filter_usage_to: 0,
                filter_usage_customer_from: 0,
                filter_usage_customer_to: 0,
                filter_promo_code: '',
                filter_promo_name: '',
                filter_apply_discount: 'combine_discount',
                filter_apply_action: '',
                filter_stacking: false,
                filter_is_active: ref(true),
                filter_smac: false,
                filter_status: 'False',
                voucher_prefix: null,
                voucher_quantity: 0,
                voucher_length: 12,
                voucher_format: 'alphanum',
                select_voucher_format: [
                    {text: 'Alphanumeric', value: 'alphanum'},
                ],
                voucher_numeric: true,
                voucher_max_number_usage: 0,
                voucher_max_customer_number_usage: 0,
                voucher_query: ref(''),
                voucher_search: '',
                voucher_promo_code: '',
                voucher_page: 1,
                voucher_page_select: [{value: 1, text: 1}],
                voucher_total_page: 0,
                voucher_order_by: 'updated_at',
                voucher_currentSortDir:'desc',
                vouchers: [],
                voucher_total: 0,
                voucher_pagination: 50,
                multiple_voucher: false,
                dmall: false,
                max_characters: 20,

                contextMenuVisible: false,
                isResizingStore: false,
                isResizing: false,
                errorMessage: null,
                // contextMenuTop: '0px',
                // contextMenuLeft: '0px',
                // menuItems: [
                //     { label: 'upload' },
                // ],
            }
        },
        mounted() {
            this.getPromos()
            this.getStores()
            this.getStoreGroups()
        },
        /* eslint-disable */
        computed:{
            sortedPromos:function() {
                return this.promos.sort((a,b) => {
                  let modifier = 1;
                  if(this.currentSortDir === 'desc') modifier = -1;
                  if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                  if(a[this.currentSort] > b[this.currentSort]) return modifier;
                  return 0;
                });
          }
        },
        methods: {
            displayAlert(message, duration) {
                this.message = message;
                this.showAlert = true;

                setTimeout(() => {
                    this.showAlert = false;
                    this.message = "";
                }, duration);
            },
            limitCharacters() {
                if (this.promoName.length > this.max_characters) {
                    this.promoName = this.promoName.substring(0, this.max_characters);
                }

                if (this.promoCode.length > this.max_characters) {
                    this.promoCode = this.promoCode.substring(0, this.max_characters);
                }
            },
            disable_select_store(input_store){
                // console.log(input_store)
                if (input_store) {
                    return true
                }
                return false
            },
            selectedStoreMaxUsage(list_store_max_usage, input_store_id) {
                // console.log(list_store_max_usage.length)
                this.stores = this.stores.map(v => ({...v, status: true}));
                if (list_store_max_usage.length - 1 > 0) {
                    let i=0;
                    for (i; i < list_store_max_usage.length -1; i++) {
                        let filter_store = this.stores.filter(s => {
                            return s.store_id.includes(list_store_max_usage[i]['store_id'])
                        })

                        filter_store[0].status = false
                        // console.log(filter_store)

                    }
                }


                if (input_store_id !== '') {
                     let filter_input_store = this.stores.filter(s => {
                        return s.store_id.includes(input_store_id)
                     })
                     filter_input_store[0].status = false
                }
            },
            limitTextPerRow() {
                if (this.promoMessage) {
                  var lines = this.promoMessage.split(/(\r\n|\n|\r)/gm);
                  for (var i = 0; i < lines.length; i++) {
                    if (lines[i].length >= this.max_promo_msg_line) {
                      lines[i] = lines[i].substring(0, this.max_promo_msg_line);
                    }
                  }
                  this.promoMessage = lines.join('');
                }
            },
            search() {
                this.filter_status = 'False'
                this.currentPage = 1
                this.showNextButton = false
                this.showPreviousButton = false
                this.getPromos()
            },
            voucher_filter(){
                this.voucher_search = this.voucher_query
                const token =  localStorage.getItem('token')
                axios.defaults.headers.common['Authorization'] = "Token " + token
                // console.log(this.currentPage)
                this.voucher_page_select = [{value: 1, text: 1}]
                this.voucher_page = 1
                let data = {
                    "orderby": this.voucher_order_by,
                    "sort": this.voucher_currentSortDir,
                }
                axios.get(`/api/v1/promo-vouchers/${this.promoCode}?search=${this.voucher_search}`).then(res => {
                    // console.log(res.data);
                    let total_count = res.data.count

                    this.vouchers = res.data.results;
                    this.voucher_total = total_count;
                    this.voucher_total_page = this.voucher_total / this.voucher_pagination
                    // console.log(Math.ceil(this.voucher_total_page))
                    for (let x=1;x <= Math.ceil(this.voucher_total_page); x++){
                        if (x > 1) {
                            this.voucher_page_select.push(
                                {
                                    text: x,
                                    value: x
                                }
                            );
                        }

                    }


                }).catch(error => {
                    console.log(error)
                })
                // this.getPromoVouchers()
            },
            sortHeader:function(s) {
                //if s == current sort, reverse
                if(s === this.currentSort) {
                  this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
                } else {
                     this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
                }
                this.order_name = s
                // console.log(this.order_name)
                if (this.filter_status === "True") {
                    this.applyFilter()
                } else {
                    this.getPromos()
                }
            },
            nextPage:function() {
                this.currentPage += 1
                if (this.filter_status === "True") {
                    this.applyFilter()
                } else {
                    this.getPromos()
                }
            },
            prevPage:function() {
                this.currentPage -= 1
                if (this.filter_status === "True") {
                    this.applyFilter()
                } else {
                    this.getPromos()
                }
            },
            getPromos: function() {
                const token = localStorage.getItem('token')
                axios.defaults.headers.common['Authorization'] = "Token " + token
                // console.log(`/api/v1/promos/?page=${this.currentPage}&search=${this.search_query}&orderby=${this.order_name}&sort=${this.currentSortDir}`)
                let data = {
                    "search": this.search_query,
                    "filter_status": this.filter_status,
                }
                axios.get(`/api/v1/promos?page=${this.currentPage}&orderby=${this.order_name}&sort=${this.currentSortDir}`, {params: data}).then(res => {
                    this.promos = res.data.results;

                    if (res.data.next) {
                        this.showNextButton = true
                    } else {
                        this.showNextButton = false
                    }
                    if (res.data.previous) {
                        this.showPreviousButton = true
                    } else {
                        this.showPreviousButton = false
                    }

                }).catch(error => {
                    console.log(error)
                })
            },
            voucher_select_page() {
                this.getPromoVouchers()
            },
            getPromoVouchers: function() {
                const token =  localStorage.getItem('token')
                axios.defaults.headers.common['Authorization'] = "Token " + token
                // console.log(this.currentPage)
                this.voucher_page_select = [{value: 1, text: 1}]
                // console.log(this.voucher_promo_code)

                let data = {
                    "orderby": this.voucher_order_by,
                    "sort": this.voucher_currentSortDir,
                    "promo_code": this.voucher_promo_code,
                }
                // axios.defaults.
                // axios.get(`/api/v1/promo-vouchers/?page=${this.voucher_page}&search=${this.voucher_query}`, {params: data}).then(res => {
                axios.get(`/api/v1/promo-vouchers/${this.promoCode}`).then(res => {
                    // console.log(res.data);
                    let total_count = res.data.count
                    this.vouchers = res.data.results;
                    this.voucher_total = total_count;
                    this.voucher_total_page = this.voucher_total / this.voucher_pagination
                    // console.log(Math.ceil(this.voucher_total_page))
                    for (let x=1;x <= Math.ceil(this.voucher_total_page); x++){
                        if (x > 1) {
                            this.voucher_page_select.push(
                                {
                                    text: x,
                                    value: x
                                }
                            );
                        }

                    }
                }).catch(error => {
                    console.log(error)
                })
            },
            getStores() {
                const token = localStorage.getItem('token')
                axios.defaults.headers.common['Authorization'] = "Token " + token
                axios.get("/api/v1/stores").then(res => {
                    // console.log(res.data);
                    this.stores = res.data.results.map(v => ({...v, status: true}));
                }).catch(error => {
                    console.log(error)
                })
            },
            add() {
                this.ruleCondition.push(
                    {
                        type: '',
                        attribute: '',
                        operator: '',
                        value: '',
                        value2: '',
                        is_value_processed: '',
                        aggregator: '',
                        conditions: [
                            {
                                type: '',
                                attribute: '',
                                operator: '',
                                value: '',
                                value2: '',
                                is_value_processed: '',
                                aggregator: '',
                                attribute_scope: '',
                                conditions: []
                            }
                            ]
                    }
                    );
                // console.log(this.ruleCondition)
            },
            remove(index, length) {
                // console.log(index)
                // console.log(length)
                if (length > 1) {
                    this.ruleCondition.splice(index, 1);
                } else {
                    this.ruleCondition[index] =
                        {
                            type: '',
                            attribute: '',
                            operator: '',
                            value: '',
                            value2: '',
                            is_value_processed: '',
                            aggregator: '',
                            conditions: [
                                {
                                    type: '',
                                    attribute: '',
                                    operator: '',
                                    value: '',
                                    value2: '',
                                    is_value_processed: '',
                                    aggregator: '',
                                    attribute_scope: '',
                                    conditions: [{
                                        type: '',
                                        attribute: '',
                                        operator: '',
                                        value: '',
                                        is_value_processed: '',
                                        aggregator: '',
                                        conditions: []
                                    }]
                                }
                                ]
                        }

                }

            },
            add_cart_rule() {
                this.cart_conditions.push({});
            },
            remove_cart_rule(index) {
                this.cart_conditions.splice(index, 1);
            },
            add_store_max_usage(input_store_id) {
                this.selectedStoreMaxUsage(this.store_max_usage, input_store_id)
                this.store_max_usage.push({
                    store_id: '',
                    max_usage: 0,}
                    );
            },
            remove_store_max_usage(index, input_store_id) {
                this.selectedStoreMaxUsage(this.store_max_usage, input_store_id)
                this.store_max_usage.splice(index, 1);
            },
            add_cart_serialized(i) {
                this.ruleCondition[i].conditions.push( {
                                type: '',
                                attribute: '',
                                operator: '',
                                value: '',
                                value2: '',
                                is_value_processed: '',
                                aggregator: '',
                                attribute_scope: '',
                                conditions: [{
                                    type: '',
                                    attribute: '',
                                    operator: '',
                                    value: '',
                                    value2: '',
                                    is_value_processed: '',
                                    aggregator: '',
                                    attribute_scope: '',
                                    conditions: []
                                }],

                })
                // console.log( this.ruleCondition[i])
            },
            sub_add_cart_serialized(i, l) {
                this.ruleCondition[i].conditions[l].conditions.push( {
                                type: '',
                                attribute: '',
                                operator: '',
                                value: '',
                                value2: '',
                                is_value_processed: '',
                                aggregator: '',
                                attribute_scope: '',
                                conditions: [{type: '',
                                attribute: '',
                                operator: '',
                                value: '',
                                value2: '',
                                is_value_processed: '',
                                aggregator: '',
                                attribute_scope: '',}]

                })
                // console.log( this.ruleCondition[i])
            },
            remove_cart_serialized(attribute, combine) {
                let index = attribute.conditions.indexOf(combine)
                attribute.conditions.splice(index, 1);
            },
            defaultCondition(){
                this.ruleConditionCombine =  [
                    {
                        type: "condition_combination",
                        attribute: "",
                        operator: "",
                        value: 1,
                        value2: '',
                        is_value_processed: "",
                        aggregator: "all",
                        conditions: [],
                    }
                    ]
            },
            clearAll(){
                this.priority = 0
                this.stacking = false
                this.promoId = ''
                this.promoName = ''
                this.promoCode = ''
                this.selectedPromoCode = null
                this.prefix=null
                this.selectedStores = []
                this.selectedStoreGroups = []
                this.startDate = null
                this.endDate = null
                this.numberOfUsage = 0
                this.numberOfUsageCustomer = 0
                this.aggregatorValue = 'all'
                this.conditionValue = 1
                this.conditions =  [
                    {
                        attribute: '',
                        operator: '',
                        value: '',
                    }
                    ]
                this.applyActionValue = ''
                this.discountAmount = 0
                this.maxQtyDiscountApplied = 0
                // this.skuList = ''
                this.maxQtyDiscountAppliedBySku = 0
                this.numberProductX = 0
                this.numberProductY = 0
                this.applyDiscount = 'combine_discount'
                this.is_active = ref(true)
                this.cart_conditions =  [
                    {
                        attribute: '',
                        operator: '',
                        value: '',
                        value2: '',
                        qty: 0,
                        discount:0,
                    }
                ]
                this.transDiscSkuId = ''
                this.transDiscSkuBarcode = ''
                this.promoMessage = ''
                this.ruleConditionCombine = [
                    {
                        type: "condition_combination",
                        attribute: "",
                        operator: "",
                        value: 1,
                        value2: "",
                        is_value_processed: "",
                        aggregator: "all",
                        conditions: [],
                    }
                ]
                this.ruleCondition = [
                    {
                        type: '',
                        attribute: '',
                        operator: '',
                        value: '',
                        value2: '',
                        is_value_processed: '',
                        aggregator: '',
                        conditions: [
                            {
                                type: '',
                                attribute: '',
                                operator: '',
                                value: '',
                                value2: '',
                                is_value_processed: '',
                                aggregator: '',
                                attribute_scope: '',
                                conditions: [{
                                    type: '',
                                    attribute: '',
                                    operator: '',
                                    value: '',
                                    value2: '',
                                    is_value_processed: '',
                                    aggregator: '',
                                    attribute_scope: '',
                                    conditions: []
                                }]
                            }
                            ]
                    }
                ]
                this.store_max_usage = [
                    {
                        store_id: '',
                        max_usage: 0,
                    }
                ]
                this.store_group_max_usage = [
                    {
                        store_group: '',
                        max_usage: 0,
                    }
                ]
                this.getStores()
                this.getStoreGroups()
                this.defaultCondition()

                this.voucher_quantity = 0
                this.voucher_length = 12
                this.voucher_format = 'alphanum'
                this.select_voucher_format = [
                    {text: 'Alphanumeric', value: 'alphanum'},
                ]
                this.voucher_numeric = true
                this.voucher_max_number_usage = 0
                this.voucher_max_customer_number_usage = 0
                this.voucher_query = ref('')
                this.voucher_promo_code = ''
                this.voucher_page = 1
                this.voucher_page_select = [{value: 1, text: 1}]
                this.voucher_total_page = 0
                this.voucher_order_by = 'updated_at'
                this.voucher_currentSortDir = 'desc'
                this.vouchers = []
                this.voucher_total = 0
                this.voucher_pagination = 50
                this.multiple_voucher = false
                this.errorMessage = null
                this.dmall = false
            },
            selectedPromo(promo) {
                console.log('update')
                this.getPromos()
                // console.log(promo)
                this.priority = promo['priority']
                this.stacking = promo['stacking']
                this.promoId = promo['id']
                this.promoName = promo['promo_name']
                this.promoCode = promo['promo_code']
                this.selectedPromoCode = promo['promo_code']
                this.prefix = promo['prefix']
                let stores = []
                Object.keys(promo['stores']).map((key) => {
                    stores.push(promo['stores'][key]['store_id'])
                })
                // console.log(stores)
                this.selectedStores = stores
                let storeGroups = []
                Object.keys(promo['store_groups']).map((key) => {
                    storeGroups.push(promo['store_groups'][key])
                })
                // console.log(storeGroups)
                this.selectedStoreGroups = storeGroups
                this.startDate = moment(String(promo['start_date'])).format('YYYY-MM-DDTHH:mm:ss.SSS')
                this.endDate = moment(String(promo['end_date'])).format('YYYY-MM-DDTHH:mm:ss.SSS')
                this.numberOfUsage = promo['number_of_usage']
                this.numberOfUsageCustomer = promo['number_of_usage_customer']
                this.aggregatorValue = promo['aggregator']
                this.conditionValue = promo['value_aggregator']

                this.conditions = promo['conditions_serialized']
                this.ruleConditionCombine = [
                        {
                            type: "condition_combination",
                            attribute: "",
                            operator: "",
                            value: promo['conditions_serialized'][0]['value'],
                            value2: "",
                            is_value_processed: "",
                            aggregator: promo['conditions_serialized'][0]['aggregator'],
                            conditions: []
                        }
                    ]
                let count = promo['conditions_serialized'][0]['conditions'].length;

                for (let i = 0; i < count; i++) {
                    this.ruleCondition.push(
                    {
                        type: '',
                        attribute: '',
                        operator: '',
                        value: '',
                        value2: '',
                        is_value_processed: '',
                        aggregator: '',
                        conditions: [
                            {
                                type: '',
                                attribute: '',
                                operator: '',
                                value: '',
                                value2: '',
                                is_value_processed: '',
                                aggregator: '',
                                attribute_scope: '',
                                conditions: [
                                    {
                                        type: '',
                                        attribute: '',
                                        operator: '',
                                        value: '',
                                        value2: '',
                                        is_value_processed: '',
                                        aggregator: '',
                                        attribute_scope: '',
                                        conditions:[]
                                    }
                                    ]
                            }
                            ]
                    }
                    );
                }



                // console.log(promo['store_max_usage'])
                // stores
                if (promo['store_max_usage']) {
                    for (let i = 0; i < 1; i++) {
                    this.store_max_usage.push(
                        {
                            store_id: '',
                            max_usage: 0,
                        }
                    )
                }
                this.store_max_usage = promo['store_max_usage']
                }

                // store group
                if (promo['store_group_max_usage']) {
                    for (let i = 0; i < 1; i++) {
                    this.store_group_max_usage.push(
                        {
                            store_group: '',
                            max_usage: 0,
                        }
                    )
                }
                this.store_group_max_usage = promo['store_group_max_usage']
                }

                for (let i = 0; i < count; i++) {
                    this.ruleCondition[i] = {
                        type: promo['conditions_serialized'][0]['conditions'][i]['type'],
                        attribute: '',
                        operator: '',
                        value: '',
                        value2: '',
                        is_value_processed: '',
                        aggregator: '',
                        conditions: [
                        {
                            type: '',
                            attribute: '',
                            operator: '',
                            value: '',
                            value2: '',
                            is_value_processed: '',
                            aggregator: '',
                            attribute_scope: ''
                        }
                        ]
                    }

                    for (let e = 0; e < promo['conditions_serialized'][0]['conditions'][i]['conditions'].length; e++) {
                        // console.log(promo['conditions_serialized'][0]['conditions'][i]['conditions'])
                        this.ruleCondition[i].conditions.push( {
                                    type: '',
                                    attribute: '', //promo['conditions_serialized'][0]['conditions'][i]['conditions'][e]['attribute'],
                                    operator: '',
                                    value: '',
                                    value2: '',
                                    is_value_processed: '',
                                    aggregator: '',
                                    attribute_scope: ''
                        })
                    }
                    // console.log( this.ruleCondition[i])

                }

                this.ruleCondition = promo['conditions_serialized'][0]['conditions']
                this.applyActionValue = promo['apply_action']
                this.discountAmount = promo['discount_amount']
                this.maxQtyDiscountApplied = promo['max_qty_discount_is_applied_to']
                this.maxQtyDiscountAppliedBySku = promo['max_qty_discount_is_applied_by_sku']

                this.numberProductX = promo['number_of_x_products']
                this.numberProductY = promo['number_of_y_products']
                this.applyDiscount = promo['apply_discount']
                this.is_active = promo['is_active']
                this.cart_conditions = promo["apply_cart_conditions_serialized"]
                this.transDiscSkuId = promo["transaction_disc_sku_id"]
                this.transDiscSkuBarcode = promo["transaction_disc_sku_barcode"]
                this.promoMessage = promo["promo_message"]

                if (this.applyActionValue === 'cart_fixed' && this.applyActionValue === 'cart_percent') {
                    this.maxQtyDiscountApplied = 0
                    this.maxQtyDiscountAppliedBySku = 0
                    this.numberProductX = 0
                    this.numberProductY = 0
                    this.cart_conditions =  [
                    {
                        attribute: '',
                        operator: '',
                        value: '',
                        value2: '',
                        qty: 0,
                        discount:0,
                    }
                ]
                }
                this.smac_required = promo["smac_required"]
                this.voucher_promo_code = promo["promo_code"]
                this.getPromoVouchers()
                this.multiple_voucher = promo["enable_multiple_voucher"]
                this.dmall = promo["dmall"]
                this.errorMessage = null
            },
            generateVoucher: function() {
                if  (this.updatePromo === true) {
                    let data = {
                        voucher_prefix: this.voucher_prefix,
                        voucher_quantity: this.voucher_quantity,
                        voucher_length: this.voucher_length,
                        voucher_numeric: this.voucher_numeric,
                        voucher_max_number_usage: this.voucher_max_number_usage,
                        voucher_max_customer_number_usage: this.voucher_max_customer_number_usage,
                        promo_code: this.promoCode
                    }

                    const token = localStorage.getItem('token')
                    axios.defaults.headers.common['Authorization'] = "Token " + token
                    axios.post("/api/v1/generate-voucher", data).then(res => {
                        // console.log(res.data);
                        // location.reload();
                        this.getPromoVouchers()
                    }).catch(error => {
                        console.log(error)
                    })
                }
            },
            submitForm: function() {
                console.log('Save')

                if (this.startDate === 'Invalid date' || this.startDate === '') {
                    this.startDate = null
                }

                if (this.endDate === 'Invalid date' || this.endDate === '') {
                    this.endDate = null
                }
                if (this.applyActionValue === 'bundle_promo' || this.applyActionValue === 'bundle_promo_percent' || this.applyActionValue === 'bundle_promo_fixed') {
                    this.discountAmount = 0
                    this.numberProductX = 0
                    this.numberProductY = 0
                }
                // let list_stores = {"store_id" : []}
                // list_stores.store_id = this.selectedStores
                // console.log(list_stores)
                // console.log(this.ruleConditionCombine)

                let conditions_serialized = []
                for (let i=0;i<this.ruleConditionCombine.length; i++) {
                    this.ruleConditionCombine[0]['conditions'] = this.ruleCondition
                }
                conditions_serialized = this.ruleConditionCombine

                let data = {
                    rule: this.rule,
                    promo_id: this.promoId,
                    promo_name: this.promoName,
                    promo_code: this.promoCode,
                    prefix: this.prefix,
                    stores: this.selectedStores,
                    store_groups: this.selectedStoreGroups,
                    start_date: this.startDate,
                    end_date: this.endDate,
                    number_of_usage: this.numberOfUsage,
                    number_of_usage_customer: this.numberOfUsageCustomer,
                    aggregator: this.aggregatorValue,
                    condition_value: this.conditionValue,
                    apply_discount: this.applyDiscount,
                    is_active: this.is_active,
                    promo_conditions: this.conditions,
                    apply_cart_conditions_serialized: this.cart_conditions,
                    apply_action: this.applyActionValue,
                    discount_amount: this.discountAmount,
                    max_qty_discount_is_applied_to: this.maxQtyDiscountApplied,
                    // sku: this.skuList,
                    max_qty_discount_is_applied_by_sku: this.maxQtyDiscountAppliedBySku,
                    discount_qty_step_buy_x: this.discountQtyStepBuyX,
                    selectedPromoCode: this.selectedPromoCode,
                    number_of_x_products: this.numberProductX,
                    number_of_y_products: this.numberProductY,
                    transaction_disc_sku_id: this.transDiscSkuId,
                    transaction_disc_sku_barcode: this.transDiscSkuBarcode,
                    promo_message: this.promoMessage,
                    rule_conditionCombine: this.ruleConditionCombine,
                    conditions_serialized: conditions_serialized, //this.ruleCondition.concat(this.conditionsSerialized),
                    serialized: this.conditionsSerialized,
                    smac_required: this.smac_required,
                    priority: this.priority,
                    stacking: this.stacking,
                    store_max_usage: this.store_max_usage,
                    store_group_max_usage: this.store_group_max_usage,
                    enable_multiple_voucher: this.multiple_voucher,
                    dmall: this.dmall,
                }

                // console.log(data)
                // console.log(this.ruleCondition)

                if (this.createPromo === true) {
                    const token = localStorage.getItem('token')
                    axios.defaults.headers.common['Authorization'] = "Token " + token
                    axios.post("/api/v1/promos", data).then(res => {
                        // console.log(res.data);
                        if (res.status === 201) {
                            location.reload();
                        } else {
                            let msg_error = error.response.data.detail || 'An error occurred';
                            this.displayAlert(msg_error, 5000)
                        }
                    }).catch(error => {
                        if (error.response) {
                            // console.log(error.response.data);
                            // console.log(error.response.data.detail);
                            // console.log(error.response.status);
                            // console.log(error.response.headers);
                            let msg_error = error.response.data.detail || 'An error occurred';
                            this.displayAlert(msg_error, 5000);
                        } else if (error.request) {
                            // The request was made but no response was received
                            console.log(error.request);
                            this.displayAlert('No response received from server', 5000);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                            this.displayAlert('Error: ' + error.message, 5000);
                        }
                        console.log(error.config);
                    })
                }

                if (this.updatePromo === true) {
                    const token = localStorage.getItem('token')
                    axios.defaults.headers.common['Authorization'] = "Token " + token
                    axios.put(`/api/v1/promos/${this.selectedPromoCode}`, data).then(res => {
                        // console.log(res.data);
                        if (res.status === 200) {
                            // console.log(res.data);
                            location.reload();
                        } else {
                            let msg_error = res.data.message
                            this.displayAlert(msg_error, 5000)
                            
                        }
                    }).catch(error => {
                        if (error.response) {
                            // console.log(error.response.data);
                            // console.log(error.response.data.detail);
                            // console.log(error.response.status);
                            // console.log(error.response.headers);
                            let msg_error = error.response.data.detail || 'An error occurred';
                            this.displayAlert(msg_error, 5000);
                        } else if (error.request) {
                            // The request was made but no response was received
                            console.log(error.request);
                            this.displayAlert('No response received from server', 5000);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                            this.displayAlert('Error: ' + error.message, 5000);
                        }
                        console.log(error.config);
                    })
                }

            },
            submitApplyFilter: function() {
                console.log('Filter')
                this.currentPage = 1
                this.showNextButton = false
                this.showPreviousButton = false
                this.applyFilter()
            },
            applyFilter: function () {
                let filter_stacking = ''
                if (this.filter_stacking === true) {
                    filter_stacking = "True"
                } else {
                    filter_stacking = "False"
                }

                let filter_is_active = ''
                if (this.filter_is_active === true) {
                    filter_is_active = "True"
                } else {
                    filter_is_active = "False"
                }

                let filter_smac = ''
                if (this.filter_smac === true) {
                    filter_smac = "True"
                } else {
                    filter_smac = "False"
                }

                const token = localStorage.getItem('token')
                axios.defaults.headers.common['Authorization'] = "Token " + token
                let data = {
                    "start_date_from": this.filter_start_date_from,
                    "start_date_to": this.filter_start_date_to,
                    "end_date_from": this.filter_end_date_from,
                    "end_date_to": this.filter_end_date_to,
                    "disc_amount_from": this.filter_discount_amount_from,
                    "disc_amount_to": this.filter_discount_amount_to,
                    "no_usage_from": this.filter_usage_from,
                    "no_usage_to": this.filter_usage_to,
                    "no_usage_customer_from": this.filter_usage_customer_from,
                    "no_usage_customer_to": this.filter_usage_customer_to,
                    "priority_from": this.filter_priority_from,
                    "priority_to": this.filter_priority_to,
                    "promo_code": this.filter_promo_code,
                    "promo_name": this.filter_promo_name,
                    "apply_discount": this.filter_apply_discount,
                    "apply_action": this.filter_apply_action,
                    "stacking": filter_stacking,
                    "is_active": filter_is_active,
                    "smac": filter_smac,
                    "filter_status": this.filter_status,
                }

                axios.get(`/api/v1/promos?page=${this.currentPage}&orderby=${this.order_name}&sort=${this.currentSortDir}` , {params: data}).then(res => {
                    this.promos = res.data.results;
                    // console.log(this.promos)
                    if (res.data.next) {
                        this.showNextButton = true
                    } else {
                        this.showNextButton = false
                    }
                    if (res.data.previous) {
                        this.showPreviousButton = true
                    } else {
                        this.showPreviousButton = false
                    }

                }).catch(error => {
                    console.log(error)
                })
            },
            resetFilter: function () {
                this.filter_status = 'False'
                this.filter_start_date_from = ''
                this.filter_start_date_to = ''
                this.filter_end_date_from = ''
                this.filter_end_date_to = ''
                this.filter_discount_amount_from = ''
                this.filter_discount_amount_to = ''
                this.filter_priority_from = ''
                this.filter_priority_to = ''
                this.filter_usage_from = ''
                this.filter_usage_to = ''
                this.filter_usage_customer_from = ''
                this.filter_usage_customer_to = ''
                this.filter_promo_code = ''
                this.filter_promo_name = ''
                this.filter_apply_discount = 'combine_discount'
                this.filter_apply_action = ''
                this.filter_stacking = false
                this.filter_is_active = ref(true)
                this.filter_smac = false

                this.currentPage = 1
                this.showNextButton = false
                this.showPreviousButton = false
                this.getPromos()
            },
            getStoreGroups() {
                let filter = {
                    "page": this.currentStoreGroupPage,
                    "search": "",
                    "store_group_name": "",
                    "orderby": "store_group_name",
                    "sort": "asc",
                    "filter_status": "False",
                }

                axios.get(`/api/v1/store-groups` ).then(res => {
                    this.storeGroups = res.data.results;
                    // console.log(this.storeGroups)
                    // this.showNextButton = !!res.data.next;
                    // this.showPreviousButton = !!res.data.previous;

                }).catch(error => {
                    console.log(error)
                })


            },
            // showContextMenu(event) {
            //   event.preventDefault();
            //   this.contextMenuVisible = true;
            //   this.contextMenuTop = `${event.clientY}px`;
            //   this.contextMenuLeft = `${event.clientX}px`;
            // },
            handleMenuItemSelected(item) {
              console.log('Selected:', item.label);
              this.contextMenuVisible = false;
            },
            // handleValuePassed(returnValue){
            //     console.log(returnValue)
            // },
            startResizeStore(event) {
              this.isResizingStore = true;
              const initialWidth = this.$refs.resizableSelectStore.clientWidth;
              const initialHeight = this.$refs.resizableSelectStore.clientHeight;
              const initialX = event.clientX;
              const initialY = event.clientY;

              const onMouseMove = (event) => {
                if (this.isResizingStore) {
                  const deltaX = event.clientX - initialX;
                  const deltaY = event.clientY - initialY;
                  this.$refs.resizableSelectStore.style.width = `${initialWidth + deltaX}px`;
                  this.$refs.resizableSelectStore.style.height = `${initialHeight + deltaY}px`;
                }
              };

              const onMouseUp = () => {
                this.isResizingStore = false;
                window.removeEventListener('mousemove', onMouseMove);
                window.removeEventListener('mouseup', onMouseUp);
              };

              window.addEventListener('mousemove', onMouseMove);
              window.addEventListener('mouseup', onMouseUp);
            },
            startResize(event) {
              this.isResizing = true;
              const initialWidth = this.$refs.resizableSelect.clientWidth;
              const initialHeight = this.$refs.resizableSelect.clientHeight;
              const initialX = event.clientX;
              const initialY = event.clientY;

              const onMouseMove = (event) => {
                if (this.isResizing) {
                  const deltaX = event.clientX - initialX;
                  const deltaY = event.clientY - initialY;
                  this.$refs.resizableSelect.style.width = `${initialWidth + deltaX}px`;
                  this.$refs.resizableSelect.style.height = `${initialHeight + deltaY}px`;
                }
              };

              const onMouseUp = () => {
                this.isResizing = false;
                window.removeEventListener('mousemove', onMouseMove);
                window.removeEventListener('mouseup', onMouseUp);
              };

              window.addEventListener('mousemove', onMouseMove);
              window.addEventListener('mouseup', onMouseUp);
            },
            reloadChild() {
              this.childKey++; // Change the key to "reload" the child component
            },
        },


    }
</script>

<style scoped>
    .custom-width-30 {
        width: 30%;
    }

    .custom-width-40 {
        width: 40%;
    }

     .custom-condition {
         font-size: medium;
         text-decoration-line: underline;
     }
    .custom-input {
        border: 2px !important;
        border-bottom-style: solid !important;
        padding: 0px;
        font-size: medium;
    }

    .pagination {
      display: inline-block;
    }

    .pagination button {
      color: black;
      float: left;
      padding: 8px 16px;
      text-decoration: none;
      transition: background-color .3s;
      border: 1px solid #ddd;
    }

    .pagination button.active {
      background-color: #4CAF50;
      color: white;
      border: 1px solid #4CAF50;
    }

    .pagination button:hover:not(.active) {background-color: #ddd;}

    .custom-align-center{
        text-align: -moz-center;
    }

    .custom-search {
        border: 1px solid #555;
        display: block;
        padding: 9px 4px 9px 40px;
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
    }

    .app {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      background-color: #f0f0f0;
    }

    .resizable-div {
      resize: both;
      overflow: auto;
      border: 1px solid #ccc;
    }
</style>
