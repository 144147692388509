<template>
    <!--    <p> {{ inputFilter }} </p>-->
    <div class="mt-8 flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead class="bg-gray-50">
                        <tr>
                            <th scope="col"
                                @click="sortHeader('id')"
                                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                ID
                            </th>
                            <th scope="col"
                                @click="sortHeader('store_id')"
                                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Store ID
                            </th>
                            <th scope="col"
                                @click="sortHeader('store')"
                                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Store
                            </th>
                            <th scope="col"
                                @click="sortHeader('master_ip_domain')"
                                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Master IP Domain
                            </th>
                            <th scope="col"
                                @click="sortHeader('master_key')"
                                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Master Key
                            </th>
                            <th scope="col"
                                @click="sortHeader('pos_key')"
                                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                POS Key
                            </th>
                            <th scope="col"
                                @click="sortHeader('region')"
                                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Region
                            </th>

                            <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <span class="sr-only">Edit</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody class="bg-white text-left">

                        <tr v-for="(data, index) in data" :key="data.id"
                            :class="index % 2 === 0 ? undefined : 'bg-gray-50'">
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {{ data.id }}
                            </td>
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {{ data.store_id }}
                            </td>
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {{ data.store }}
                            </td>
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {{ data.master_ip_domain }}
                            </td>
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {{ data.master_key }}
                            </td>
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {{ data.pos_key }}
                            </td>
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {{ data.region }}
                            </td>
                            <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <a href="#" class="text-indigo-600 hover:text-indigo-900"
                                   @click="open = true; selectedItem(data)"
                                >Edit<span class="sr-only">, {{ data.id }}</span></a
                                >
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

        <div class="mt-10 pagination m-1 custom-center-div">
            <button @click="prevPage" v-if="showPreviousButton">❮</button>
            <button @click="nextPage" v-if="showNextButton">❯</button>
        </div>

        <div class="px-4 sm:px-6 lg:px-8">
        <!--edit store group-->
            <TransitionRoot as="template" :show="open">
                <Dialog as="div" class="relative z-10" @close="open = false">
                    <div class="fixed inset-0"/>

                    <div class="fixed inset-0 overflow-hidden">
                        <div class="absolute inset-0 overflow-hidden">
                            <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16 ">
                                <TransitionChild as="template"
                                                 enter="transform transition ease-in-out duration-500 sm:duration-700"
                                                 enter-from="translate-x-full" enter-to="translate-x-0"
                                                 leave="transform transition ease-in-out duration-500 sm:duration-700"
                                                 leave-from="translate-x-0" leave-to="translate-x-full">
                                    <DialogPanel class="pointer-events-auto w-screen max-w-md">
                                        <form class="flex
                                                     h-full
                                                     flex-col
                                                     divide-y
                                                     divide-gray-200
                                                     bg-white
                                                     shadow-xl"
                                              @submit.prevent="submitForm"
                                        >
                                            <div class="h-0 flex-1 overflow-y-auto">
                                                <div class="bg-indigo-700 py-6 px-4 sm:px-6">
                                                    <div class="flex items-center justify-between">
                                                        <DialogTitle class="text-lg font-medium text-white">
                                                            Edit Store Group
                                                        </DialogTitle>
                                                        <div class="ml-3 flex h-7 items-center">
                                                            <button type="button"
                                                                    class="rounded-md
                                                                           bg-indigo-700
                                                                           text-indigo-200
                                                                           hover:text-white"
                                                                    @click="open = false">
                                                                <span class="sr-only">Close panel</span>
                                                                <XIcon class="h-6 w-6" aria-hidden="true"/>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <data-fields
                                                        :action="action"
                                                        :selectedData="selectedData"
                                                /> <!--display fields-->

                                            </div>
                                        </form>
                                    </DialogPanel>
                                </TransitionChild>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </TransitionRoot>
        </div>

    </div>
</template>

<script>
    import axios from "axios";
    import {ref} from "vue";
    import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
    import {XIcon} from "@heroicons/vue/outline";
    import DataFields from "@/views/Store/DataFields";
    export default {
        name: "ListStore",
        components: {
            Dialog,
            DialogPanel,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            XIcon,
            DataFields
        },
        props: {
            inputFilter: Object,
        },
        data() {
            return {
                showNextButton: false,
                showPreviousButton: false,
                currentPage:1,
                search_query: ref(''),
                order_name: 'id',
                currentSortDir:'asc',
                data: [],
                selectedData: [],
                open: ref(false),
                action: "update",
                id: null,
                filter: this.inputFilter,
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            nextPage:function() {
                this.currentPage += 1
                if (this.filter_status === "True") {
                    this.applyFilter()
                } else {
                    this.getData()
                }
            },
            prevPage:function() {
                this.currentPage -= 1
                if (this.filter_status === "True") {
                    this.applyFilter()
                } else {
                    this.getData()
                }
            },
            getData() {
                console.log(this.inputFilter)
                let filter = {
                    "page": this.currentPage,
                    "search": this.inputFilter.search_query,
                    "store_id": this.inputFilter.filter_store_id,
                    "store": this.inputFilter.filter_store,
                    "master_ip_domain": this.inputFilter.filter_master_ip_domain,
                    "master_key": this.inputFilter.filter_master_key,
                    "pos_key": this.inputFilter.filter_pos_key,
                    "region": this.inputFilter.filter_region,
                    "orderby": this.order_name,
                    "sort": this.currentSortDir,
                    "filter_status": this.inputFilter.filter_status,
                }

                console.log(filter)

                axios.get(`/api/v1/store-view/` , {params: filter}).then(res => {
                    // console.log(res.data);
                    this.data = res.data.results;

                    this.showNextButton = !!res.data.next;
                    this.showPreviousButton = !!res.data.previous;

                }).catch(error => {
                    console.log(error)
                })

            },
            submitApplyFilter: function () {
                console.log('Filter')
                this.currentPage = 1
                this.showNextButton = false
                this.showPreviousButton = false
                this.applyFilter()
            },
            applyFilter: function () {
                // let filter_is_active = ''
                // if (this.filter_is_active === true) {
                //     filter_is_active = "True"
                // } else {
                //     filter_is_active = "False"
                // }
                //
                // const token =  localStorage.getItem('token')
                // axios.defaults.headers.common['Authorization'] = "Token " + token
                // // console.log(this.currentPage)
                // let data = {
                //     "page": this.currentPage,
                //     "no_usage_from": this.filter_usage_from,
                //     "no_usage_to": this.filter_usage_to,
                //     "no_usage_customer_from": this.filter_usage_customer_from,
                //     "no_usage_customer_to": this.filter_usage_customer_to,
                //     "voucher_code": this.filter_voucher_code,
                //     "promo_code": this.filter_promo_code,
                //     "is_active": filter_is_active,
                //     "orderby": this.order_name,
                //     "sort": this.currentSortDir,
                //     "filter_status": this.filter_status,
                // }
                // axios.get(`/api/v1/vouchers/` , {params: data}).then(res => {
                //     // console.log(res.data);
                //     this.vouchers = res.data.results;
                //
                //     if (res.data.next) {
                //         this.showNextButton = true
                //     } else {
                //         this.showNextButton = false
                //     }
                //     if (res.data.previous) {
                //         this.showPreviousButton = true
                //     } else {
                //         this.showPreviousButton = false
                //     }
                //
                // }).catch(error => {
                //     console.log(error)
                // })
            },
            sortHeader:function(s) {
                if(s === this.currentSort) {
                   this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
                } else {
                     this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
                }
                this.order_name = s

                if (this.filter_status === "True") {
                    this.applyFilter()
                } else {
                    this.getData()
                }
            },
            selectedItem(strGrp){
                this.selectedData =  strGrp
                console.log(this.selectedData)
            },
            submitForm: function() {

            },
        }
    }
</script>

<style scoped>
    .pagination {
      display: inline-block;
    }

    .pagination button {
      color: black;
      float: left;
      padding: 8px 16px;
      text-decoration: none;
      transition: background-color .3s;
      border: 1px solid #ddd;
    }

    .pagination button.active {
      background-color: #4CAF50;
      color: white;
      border: 1px solid #4CAF50;
    }

    .custom-center-div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .pagination button:hover:not(.active) {background-color: #ddd;}
</style>