<template>
    <!-- start dialog information -->
    <div  v-if="showAlert"
          class="bg-red-100
                 border
                 border-red-400
                 text-red-700
                 px-4
                 py-3
                 rounded
                 relative
                 text-left"
    >
        {{ message }}
    </div>
    <form @submit.prevent="submitForm">
        <div class="flex flex-1 flex-col justify-between">
            <div class="divide-y divide-gray-200 px-4 sm:px-6">
                <div class="space-y-6 pt-6 pb-5">

                    <div>
                        <label for="store_group_name"
                               class="block text-sm font-medium text-gray-900">
                            Store Group Name </label>
                        <div class="mt-1">
                            <input type="text" name="store_group_name"
                                   id="store_group_name"
                                   v-model="formData.store_group_name"
                                   class="block
                                          w-75
                                          rounded-md
                                          border-gray-300
                                          shadow-sm
                                          focus:border-indigo-500
                                          focus:ring-indigo-500
                                          sm:text-sm"
                                   required
                            />
                        </div>
                    </div>

                    <div>
                        <label class="block text-sm font-medium text-gray-900">
                        Stores </label>
                        <div class="mt-1">
                            <select class="block
                                           w-50
                                           rounded-md
                                           border-gray-300
                                           shadow-sm
                                           focus:border-indigo-500
                                           focus:ring-indigo-500
                                           sm:text-sm
                                           resizable-select-stores
                                           resizable-div"
                                    ref="resizableSelectStore"
                                    v-model="formData.stores"
                                    multiple
                            >
                                <!-- eslint-disable-next-line-->
                                <option v-for="data in stores"
                                        v-bind:value="data.store_id">
                                    {{ data.store_id }}
                                </option>
                            </select>

                        </div>
                    </div>

                    <div>
                        <SwitchGroup as="div" class="flex items-center">
                            <Switch v-model="formData.is_active"
                                    :class="[formData.is_active ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                <span aria-hidden="true"
                                      :class="[formData.is_active ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                            </Switch>
                            <SwitchLabel as="span" class="ml-3">
                                <span class="text-sm font-medium text-gray-900">Status </span>
                            </SwitchLabel>
                        </SwitchGroup>
                    </div>


                </div>

                <div class="flex flex-shrink-0 justify-end px-4 py-4">
                    <button type="button"
                            class="rounded-md
                                   border
                                   border-gray-300
                                   bg-white
                                   py-2
                                   px-4
                                   text-sm
                                   font-medium
                                   text-gray-700
                                   shadow-sm
                                   hover:bg-gray-50
                                   focus:outline-none
                                   focus:ring-2
                                   focus:ring-indigo-500
                                   focus:ring-offset-2"
                            >Reset
                    </button>
                    <button type="submit"
                            class="ml-4
                                   inline-flex
                                   justify-center
                                   rounded-md
                                   border
                                   border-transparent
                                   bg-indigo-600
                                   py-2
                                   px-4
                                   text-sm
                                   font-medium
                                   text-white
                                   shadow-sm
                                   hover:bg-indigo-700
                                   focus:outline-none
                                   focus:ring-2
                                   focus:ring-indigo-500
                                   focus:ring-offset-2"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
        Dialog,
        DialogPanel,
        DialogTitle,
        Switch,
        SwitchGroup,
        TransitionChild,
        TransitionRoot
    } from "@headlessui/vue";
    import {ChevronDownIcon, CheckIcon} from "@heroicons/vue/outline";
    import CreateStoreGroup from "@/views/StoreGroup/CreateStoreGroup";
    import axios from "axios";
    import {ref} from "vue";
    export default {
        name: "DataFields",
        components: {
            Switch,
            SwitchGroup,
            // Combobox,
            // ComboboxButton,
            // ComboboxInput,
            // ComboboxLabel,
            // ComboboxOption,
            // ComboboxOptions,
            // CheckIcon,
            // ChevronDownIcon
            // Dialog,
            // DialogPanel,
            // DialogTitle,
            // TransitionChild,
            // TransitionRoot,
            // XIcon,
            // CreateStoreGroup
        },
        props: {
            action: Text,
            selectedData: Object
        },
        data() {
            return {
                formData: {
                    stores: [],
                    store_group_name: '',
                    // company: '',
                    is_active: true,
                },
                selectedStoreGroup: '',
                // company_list: [
                //     {text: 'SVI', value: 'svi'},
                //     {text: 'SSM', value: 'ssm'},
                //     {text: 'SMCo', value: 'smco'},
                // ],
                stores: [],
                query: ref(''),
                // region_list: [
                //     {text: 'NCR', value: 'ncr'},
                //     {text: 'North Luzon', value: 'north_luzon'},
                //     {text: 'South Luzon', value: 'south_luzon'},
                //     {text: 'Visayas', value: 'visayas'},
                //     {text: 'Mindanao', value: 'mindanao'},
                // ],

                showAlert: false,
                message: "",
            }
        },
        mounted() {
            const token = localStorage.getItem('token')
            axios.defaults.headers.common['Authorization'] = "Token " + token
            if (this.action === "update") {
                // this.formData.store = this.selectedData.store
                this.formData.stores = this.selectedData.stores.map(store => store.store_id)
                this.formData.store_group_name = this.selectedData.store_group_name
                // this.formData.company = this.selectedData.company
                this.formData.is_active = this.selectedData.is_active
                this.selectedStoreGroup = this.selectedData.store_group_name
                // console.log(this.selectedStoreGroup)
            }
            this.getStores()
        },
        methods: {
            getStores() {
                axios.get("/api/v1/stores").then(res => {
                    // console.log(res.data);
                    this.stores = res.data.results.map(v => ({...v, status: true}));
                }).catch(error => {
                    console.log(error)
                })
            },
            submitForm: function() {
                console.log(this.formData)
                // this.formData.store = this.formData.store["store_id"]


                if (this.action === "create") {
                    axios.post("/api/v1/store-groups", this.formData).then(res => {
                        // console.log(res.data.status);
                        if (res.status === 201) {
                            location.reload();
                        } else {
                            let msg_error = res.data.message
                            this.displayAlert(msg_error, 5000)   
                        }
                    }).catch(error => {
                        if (error.response) {
                            // console.log(error.response.data);
                            // console.log(error.response.data.detail);
                            // console.log(error.response.status);
                            // console.log(error.response.headers);
                            let msg_error = error.response.data.detail || 'An error occurred';
                            this.displayAlert(msg_error, 5000);
                        } else if (error.request) {
                            // The request was made but no response was received
                            console.log(error.request);
                            this.displayAlert('No response received from server', 5000);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                            this.displayAlert('Error: ' + error.message, 5000);
                        }
                        console.log(error.config);
                    })
                } else if (this.action === "update") {
                    axios.put(`/api/v1/store-groups/${this.selectedStoreGroup}`, this.formData).then(res => {
                        if (res.status === 200) {
                            // location.reload();
                        } else {
                            let msg_error = res.data.message
                            this.displayAlert(msg_error, 5000)
                        }
                    }).catch(error => {
                        if (error.response) {
                            // console.log(error.response.data);
                            // console.log(error.response.data.detail);
                            // console.log(error.response.status);
                            // console.log(error.response.headers);
                            let msg_error = error.response.data.detail || 'An error occurred';
                            this.displayAlert(msg_error, 5000);
                        } else if (error.request) {
                            // The request was made but no response was received
                            console.log(error.request);
                            this.displayAlert('No response received from server', 5000);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                            this.displayAlert('Error: ' + error.message, 5000);
                        }
                        console.log(error.config);
                    })
                }
            },
            displayAlert(message, duration) {
                this.message = message;
                this.showAlert = true;

                setTimeout(() => {
                    this.showAlert = false;
                    this.message = "";
                }, duration);
            },
        }
    }
</script>

<style scoped>
    .resizable-div {
      resize: both;
      overflow: auto;
      border: 1px solid #ccc;
    }
</style>