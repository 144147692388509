<template>
    <input
        type="file"
        ref="fileInput"
        style="display: none"
        @change="handleFileChange"
    />

    <div v-if="isVisible" class="context-menu" :style="{ top, left }">
        <ul>
          <li v-for="(item, index) in menuItems" :key="index" @click="handleItemClick(item)">
              {{ item.label }}
          </li>
        </ul>
    </div>

    <div v-if="inputApplyActionValue !== 'cart_fixed' &
               inputApplyActionValue !== 'cart_percent'">
        <DialogTitle class="text-lg font-medium text-white"> Apply Cart Conditions </DialogTitle>
        <div class="bg-indigo-700 py-6 px-4 sm:px-6">
            <div class="flex items-center justify-between">
                <DialogTitle class="text-lg font-medium text-white"> Apply Cart Conditions </DialogTitle>
            </div>
        </div>

        <div id="app_cart" style="margin-top: 0; !important;" v-for="(input,k) in cart_conditions" :key="k">
            <div class="row " style="display: flex!important;">
                <div class="mt-0 mb-0">
                    <label class="block text-sm font-medium text-gray-900">
                        Attribute </label>
                    <div class="mt-1 mr-3">
                        <select style="height: 110%;"
                                class="mb-3
                                       block
                                       w-50
                                       rounded-md
                                       border-gray-300
                                       shadow-sm
                                       focus:border-indigo-500
                                       focus:ring-indigo-500
                                       sm:text-sm"
                                v-model="input.attribute"
                                @change="input.operator || onChange"
                        >
                            <!-- eslint-disable-next-line-->
                            <option v-for="attribute in cart_attributes"
                                    v-bind:value="attribute.value">
                                {{ attribute.text }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="mt-0 mb-0">
                    <label class="block text-sm font-medium text-gray-900">
                        Operator </label>
                    <div class="mt-1 mr-3">

                        <select style="height: 110%;"
                                class="mb-3
                                       block
                                       w-50
                                       rounded-md
                                       border-gray-300
                                       shadow-sm
                                       focus:border-indigo-500
                                       focus:ring-indigo-500
                                       sm:text-sm"
                                v-model="input.operator"
                                v-if="input.attribute === 'item_id' ||
                                      input.attribute === 'barcode' ||
                                      input.attribute === 'mobile'"
                                @change="onChange"
                        >
                            <!-- eslint-disable-next-line-->
                            <option v-for="operator in operator_1"
                                    v-bind:value="operator.value">
                                {{ operator.text }}
                            </option>
                        </select>

                        <select style="height: 110%;"
                                class="mb-3
                                       block
                                       w-50
                                       rounded-md
                                       border-gray-300
                                       shadow-sm
                                       focus:border-indigo-500
                                       focus:ring-indigo-500
                                       sm:text-sm"
                                v-model="input.operator"
                                v-else-if="input.attribute === 'cart_price' ||
                                           input.attribute === 'cart_line_quantity' ||
                                           input.attribute === 'cart_row_total'"
                        >
                            <!-- eslint-disable-next-line-->
                            <option v-for="operator in operator_2"
                                    v-bind:value="operator.value">
                                {{ operator.text }}
                            </option>
                        </select>

                        <select style="height: 110%;"
                                class="mb-3
                                       block
                                       w-50
                                       rounded-md
                                       border-gray-300
                                       shadow-sm
                                       focus:border-indigo-500
                                       focus:ring-indigo-500
                                       sm:text-sm"
                                @change="onChange"
                                v-model="input.operator"
                                v-else
                        >
                            <!-- eslint-disable-next-line-->
                            <option>
                            </option>
                        </select>
                    </div>
                </div>

                <div class="mt-0 mb-0" >
                     <label class="block text-sm font-medium text-gray-900">
                            Value </label>
                    <div style="display:flex; !important;">

                        <div class="mt-1 mr-3" >
                            <context-menu-input
                                    v-model:value="input.value"
                                    :value-operator="input.operator"
                                    :key="childKey"
                            />

                        </div>

                        <span v-show="input.operator !== '[]'" v-if="inputApplyActionValue !== 'bundle_promo' &
                                    inputApplyActionValue !== 'bundle_promo_percent' &
                                    inputApplyActionValue !=='bundle_promo_fixed'">
                            <i class="fas fa-minus-circle"
                               @click="remove_cart_rule(this.k)"
                               v-show="k || ( !k && cart_conditions.length > 1)"></i>
                            <i class="fas fa-plus-circle"
                               @click="add_cart_rule()"
                               v-show="k === cart_conditions.length-1"></i>
                        </span>
                    </div>
                </div>

<!--                <div class="mt-0 mb-0" v-show="input.operator === '[]'">-->
<!--                    <label class="block text-sm font-medium text-gray-900">Value </label>-->
<!--                    <div style="display:flex; !important;">-->
<!--                        <div class="mt-1 mr-3" >-->

<!--                            <context-menu-input-->
<!--                                    v-model:value="input.value"-->
<!--                            />-->

<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

                <div class="mt-0 mb-0" v-show="input.operator === '[]'">
                    <label class="block text-sm font-medium text-gray-900">Value To </label>
                    <div style="display:flex; !important;">
                        <div class="mt-1 mr-3" >

                            <input type="text"
                                   v-model="input.value2"
                                   style="height: 80%;"
                                   placeholder="To"
                                   class="mb-3
                                          block
                                          w-50
                                          rounded-md
                                          border-gray-300
                                          shadow-sm
                                          focus:border-indigo-500
                                          focus:ring-indigo-500
                                          sm:text-sm"/>
                        </div>

                        <span v-if="inputApplyActionValue !== 'bundle_promo' &
                                    inputApplyActionValue !== 'bundle_promo_percent' &
                                    inputApplyActionValue !=='bundle_promo_fixed'">
                            <i class="fas fa-minus-circle"
                               @click="remove_cart_rule(k)"
                               v-show="k || ( !k && cart_conditions.length > 1)"></i>
                            <i class="fas fa-plus-circle"
                               @click="add_cart_rule()"
                               v-show="k === cart_conditions.length-1"></i>
                        </span>
                    </div>
                </div>

                <div class="mt-0 mb-0"
                     v-if="inputApplyActionValue === 'bundle_promo' ||
                           inputApplyActionValue === 'bundle_promo_percent' ||
                           inputApplyActionValue === 'bundle_promo_fixed'">
                     <label class="block text-sm font-medium text-gray-900">
                            Qty </label>
                    <div style="display:flex; !important;">
                        <div class="mt-1 mr-3">
                            <input type="number"
                                   v-model="input.qty"
                                   style="height: 80%;"
                                   class="mb-3
                                          block
                                          w-50
                                          rounded-md
                                          border-gray-300
                                          shadow-sm
                                          focus:border-indigo-500
                                          focus:ring-indigo-500
                                          sm:text-sm"/>
                        </div>
                    </div>
                </div>

                <div class="mt-0 mb-0"
                     v-if="inputApplyActionValue === 'bundle_promo' ||
                           inputApplyActionValue === 'bundle_promo_percent' ||
                           inputApplyActionValue === 'bundle_promo_fixed'">
                     <label v-if="inputApplyActionValue !== 'bundle_promo_fixed'"
                            class="block
                                   text-sm
                                   font-medium
                                   text-gray-900">
                            Discount </label>

                    <label v-else class="block text-sm font-medium text-gray-900">
                            Price </label>

                    <div style="display:flex; !important;">

                        <div class="mt-1 mr-3">
                            <input type="number"
                                   v-model="input.discount"
                                   style="height: 80%;"
                                   step=".01"
                                   class="mb-3
                                          block
                                          w-50
                                          rounded-md
                                          border-gray-300
                                          shadow-sm
                                          focus:border-indigo-500
                                          focus:ring-indigo-500
                                          sm:text-sm"/>
                        </div>

                        <span v-if="applyActionValue === 'bundle_promo' ||
                                    applyActionValue === 'bundle_promo_percent' ||
                                    applyActionValue === 'bundle_promo_fixed'">
                            <i class="fas fa-minus-circle"
                               @click="remove_cart_rule(k)"
                               v-show="k || ( !k && cart_conditions.length > 1)"></i>
                            <i class="fas fa-plus-circle"
                               @click="add_cart_rule()"
                               v-show="k === cart_conditions.length-1"></i>
                        </span>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import readXlsxFile from "read-excel-file";
    import {DialogTitle} from '@headlessui/vue'
    import ContextMenuInput from "@/components/ContextMenuInput";
    export default {
        name: "ApplyCartConditionsInput",
        components: {
            DialogTitle,
            ContextMenuInput
        },
        props: {
            cartConditions: Array,
            applyActionValue: String,
        },
        mounted() {
            this.reloadChild()
        },
        data() {
            return {
                childKey: 0,
                isVisible: false,
                top: "",
                left: "",
                menuItems: [
                    { label: 'upload' },
                ],
                firstColumn: [],
                selectedFile: null,
                data: [],
                file: "",
                cart_conditions: this.cartConditions,
                inputApplyActionValue: this.applyActionValue,
                cart_attributes: [
                    {text: '(Please select a value)', value: ''},
                    {text: 'Item ID', value: 'item_id'},
                    {text: 'Barcode', value: 'barcode'},
                    // {text: 'Price in cart', value: 'cart_price'},
                    // {text: 'Line Item Quantity', value: 'cart_line_quantity'},
                    // {text: 'Row Total in cart', value: 'cart_row_total'},
                    // {text: 'Mobile Number', value: 'mobile'},
                ],
                operator_1: [
                    {text: '(Please select a value)', value: ''},
                    {text: 'is equal', value: '=='},
                    {text: 'contains', value: '{}'},
                    {text: 'is one of', value: '()'},
                    {text: 'range', value: '[]'},
                    // {text: 'is not', value: '!='},
                    // {text: 'does not contain', value: '!{}'},
                    // {text: 'is not one of', value: '!()'},
                ],
                operator_2: [
                    {text: '(Please select a value)', value: ''},
                    {text: 'is equal', value: '=='},
                    {text: 'equals or greater than', value: '>='},
                    {text: 'equals or less than', value: '<='},
                    {text: 'greater than', value: '>'},
                    {text: 'less than', value: '<'},
                    // {text: 'is not', value: '!='},
                ],
            }
        },
        methods: {
            handleItemClick(item) {
                this.$emit('itemSelected', item);
                this.isVisible = false;
                this.$refs.fileInput.click();
            },
            uploadFile() {
                if (this.selectedFile) {
                    console.log('Selected file:', this.selectedFile);
                } else {
                    console.log('No file selected.');
                }
            },
            showContextMenu(event) {
                event.preventDefault();
                this.isVisible = true;
                this.top = `${event.clientY}px`;
                this.left = `${event.clientX}px`;
            },
            add_cart_rule() {
                this.cart_conditions.push({});
                this.onChange()
            },
            remove_cart_rule(index) {
                this.cart_conditions.splice(index, 1);
                this.onChange()
            },
            async handleFileChange(event) {
                this.isVisible = false;
                this.file = this.$refs.fileInput.files[0]
                console.log(this.file)

                if (this.file) {
                    await this.readExcelFile(this.file);
                }
            },
            async readExcelFile(file) {
                try {
                    const data = await readXlsxFile(file);
                    if (data.length > 0) {
                      this.firstColumn = data.map(row => row[0]);
                      this.data = this.firstColumn.join(",")
                    }
                    // console.log(this.data)
                    this.$emit('data', this.data);
                    this.$emit('update:value', this.data);
                    this.inputValue=this.data
                    this.onChange()
                    // console.log(this.data)

                } catch (error) {
                    console.error('Error reading Excel file:', error);
                }
                this.clearFields()
            },
            clearFields() {
                 this.file = ''
                 this.data = ''
                 this.$refs.fileInput.value = null
            },
            onChange() {
                this.$emit('update:cartConditions', this.cart_conditions);
            },
            reloadChild() {
              this.childKey++; // Change the key to "reload" the child component
            },
        }
    }

</script>

<style scoped>

</style>