import { createRouter, createWebHistory } from 'vue-router'
// import LoginView from "@/views/LoginView";
import DashboardView from "../views/DashboardView";
import PromoView from "../views/PromoView";
import CatalogPriceRule from "@/views/CatalogPriceRule";
import CartPriceRule from "@/views/CartPriceRule";
import VoucherView from "../views/VoucherView";
import NewUI from "@/views/NewUI";
import NewCartPriceRule from "@/views/NewCartPriceRule";
import ImportExport from "@/views/ImportExport";
import StoreGroup from "@/views/StoreGroup/StoreGroup";
import StoreSetup from "@/views/Store/StoreSetup";
// import PromoVoucherView from "../views/PromoVoucherView";

const routes = [
  // {
  //   path: '/',
  //   name: 'Login',
  //   component: LoginView
  // },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView
  },
  {
    path: '/promo',
    name: 'Promo',
    component: PromoView
  },
  {
    path: '/catalog_rule',
    name: 'Catalog Price Rule',
    component: CatalogPriceRule
  },
  {
    path: '/cart_rule',
    name: 'Cart Price Rule',
    component: CartPriceRule
  },
  {
    path: '/voucher',
    name: 'Voucher',
    component: VoucherView
  },
  {
  path: '/new_cart',
  name: 'New',
  component: NewUI
  },
  {
    path: '/promo_rule',
    name: 'NewCartPriceRule',
    component: NewCartPriceRule
  },
  {
    path: '/import_export',
    name: 'ImportExport',
    component: ImportExport
  },
  {
    path: '/store_setup',
    name: 'StoreSetup',
    component: StoreSetup
  },
  {
    path: '/store_group',
    name: 'StoreGroup',
    component: StoreGroup
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
