<template>
<!--    <SideBar/>-->
    <template>

    </template>
</template>

<script>
    // import SideBar from "@/components/SideBar";
    export default {
        name: "DashboardView",
        // components: { SideBar }
    }
</script>

<style scoped>

</style>