<template>
    <div class="py-10">
        <header>
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 class="text-3xl font-bold leading-tight text-gray-900 text-left">Store</h1>
            </div>
        </header>

        <main>
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <create-store/> <!--create store-->
                <filter-data/> <!--filter data-->
            </div>
        </main>

    </div>
</template>

<script>
    import CreateStore from "@/views/Store/CreateStore";
    import FilterData from "@/views/Store/FilterData";
    export default {
        name: "StoreSetup",
        components: {
            CreateStore,
            FilterData
        }
    }
</script>

<style scoped>

</style>